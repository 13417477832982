import React, { useRef } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const StudyTips = () => {
  // Create refs for each section
  const dailyStudyScheduleRef = useRef(null);
  const studyGroupsRef = useRef(null);
  const practiceRef = useRef(null);
  const selfCareRef = useRef(null);

  // Smooth scroll function
  const scrollToRef = (ref) => {
    if (ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div>
      <NavBar />
      <Container>
        
        {/* Introduction section */}
        <div className="p-4 p-md-5 mb-4 rounded text-body-emphasis bg-body-secondary SignInBackgroundStudyTips">
          <Row className="gx-0">
            <Col lg={6} className="px-0">
              <div className="transparent-box">
                <h1 className="display-3 text-center">Study Tips</h1>
              </div>
            </Col>
          </Row>
        </div>
        
        {/* Tips section */}
        <Row className="mb-4">
          {/* Daily Study Schedule Card */}
          <Col md={6}>
            <Card className="border rounded h-md-250 position-relative">
              <Card.Body className="d-flex flex-column position-static">
                <Card.Title className='display-6'>Daily Study Schedule</Card.Title>
                <Card.Text>Craft a daily plan outlining study blocks, breaks, and goals, ensuring consistency and efficiency in learning.</Card.Text>
                <Card.Text style={{fontSize: '12px'}}><u>Click box to navigate</u> </Card.Text>
                {/* Add Link component with 'to' attribute */}
                <Link to="#" className="btn btn-link stretched-link" onClick={() => scrollToRef(dailyStudyScheduleRef)}></Link>
              </Card.Body>
            </Card>
          </Col>
          {/* Study Groups Card */}
          <Col md={6}>
            <Card className="border rounded h-md-250 position-relative">
              <Card.Body className="d-flex flex-column position-static">
                <Card.Title className='display-6'>Study Groups</Card.Title>
                <Card.Text>Collaborate with peers to share knowledge, exchange perspectives, and support one another in understanding complex medical concepts.</Card.Text>
                <Card.Text style={{fontSize: '12px'}}><u>Click box to navigate</u> </Card.Text>
                {/* Add Link component with 'to' attribute */}
                <Link to="#" className="btn btn-link stretched-link" onClick={() => scrollToRef(studyGroupsRef)}></Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        
        <Row className="mb-2">
          {/* Practice Card */}
          <Col md={6}>
            <Card className="border rounded h-md-250 position-relative">
              <Card.Body className="d-flex flex-column position-static">
                <Card.Title className='display-6'>Practice!</Card.Title>
                <Card.Text>Engage in active learning through clinical cases, simulations, and hands-on practice to reinforce understanding and develop essential skills.</Card.Text>
                <Card.Text style={{fontSize: '12px'}}><u>Click box to navigate</u> </Card.Text>
                {/* Add Link component with 'to' attribute */}
                <Link to="#" className="btn btn-link stretched-link" onClick={() => scrollToRef(practiceRef)}></Link>
              </Card.Body>
            </Card>
          </Col>
          {/* Self Care Card */}
          <Col md={6}>
            <Card className="border rounded h-md-250 position-relative">
              <Card.Body className="d-flex flex-column position-static">
                <Card.Title className='display-6'>Self Care</Card.Title>
                <Card.Text>
Promote overall well-being in medical school with exercise, mindfulness, and social connections.</Card.Text>
<Card.Text style={{fontSize: '12px'}}><u>Click box to navigate</u> </Card.Text>
                {/* Add Link component with 'to' attribute */}
                <Link to="#" className="btn btn-link stretched-link" onClick={() => scrollToRef(selfCareRef)}></Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        
        {/* Detailed information for each topic */}
        <div className="mb-5 mt-5" ref={dailyStudyScheduleRef}>
          <h1 className="text-center mb-4">Daily Study Schedule</h1>
          <p>Create an effective daily study schedule:</p>
          <ol>
            <li><strong>Identify Your Peak Study Times:</strong> Determine when you are most productive and allocate study sessions during those times.</li>
            <li><strong>Allocate Specific Time Slots:</strong> Assign dedicated time slots for each subject or topic to maintain focus.</li>
            <li><strong>Include Breaks:</strong> Incorporate short breaks to rest and recharge your mind.</li>
            <li><strong>Set Realistic Goals:</strong> Establish achievable study goals for each session to track progress.</li>
            <li><strong>Regularly Review and Adjust:</strong> Evaluate your schedule periodically and make necessary adjustments to optimize efficiency.</li>
          </ol>
          <p><strong>Key Point:</strong> Consistency and flexibility are crucial for effective time management.</p>
          <p><strong>Additional Resources:</strong> Use apps like <a href="https://todoist.com/">Todoist</a> or <a href="https://calendar.google.com/">Google Calendar</a> to organize your study schedule. Websites like <a href="https://pomofocus.io/">Pomodoro Technique</a> can help you structure study sessions with timed intervals and breaks.</p>
        </div>

        {/* Pomodoro Technique subsection */}
        <div className="mb-5" >
          <h2 className="text-center mb-4">Pomodoro Technique</h2>
          <p>The Pomodoro Technique is a time management method that uses a timer to break work into intervals, traditionally 25 minutes in length, separated by short breaks.</p>
          <p><strong>How to Use:</strong> Set a timer for 25 minutes and work on a task with full focus. Once the timer rings, take a short break (usually 5 minutes). After completing four cycles, take a longer break (15-30 minutes).</p>
          <p><strong>Benefits:</strong></p>
          <ul>
            <li>Enhances focus and productivity by breaking work into manageable intervals.</li>
            <li>Prevents burnout and mental fatigue by incorporating regular breaks.</li>
            <li>Encourages a sense of urgency and motivation to complete tasks within the designated time frame.</li>
            <li>Improves time management skills and helps prioritize tasks effectively.</li>
          </ul>
          <p><strong>Tools:</strong> Various Pomodoro timer apps and websites are available, such as <a href="https://tomato-timer.com/">TomatoTimer</a> and <a href="https://www.focusboosterapp.com/">Focus Booster</a>, to help you implement the technique effectively.</p>
        </div>

        {/* Todoist and Google Calendar section */}
        <div className="mb-5">
          <h2 className="text-center mb-5">Using Todoist and Google Calendar</h2>
          <h4 className="text-center mb-4"><strong>Todoist:</strong></h4>
          <p>Todoist is a task management tool that helps you organize your tasks and projects. Here's how to use it for your study schedule:</p>
          <ol className="mb-5">
            <li><strong>Create Tasks:</strong> Add tasks for each study session, assignment, or project.</li>
            <li><strong>Set Due Dates:</strong> Assign due dates to your tasks to prioritize and schedule them.</li>
            <li><strong>Organize with Projects and Labels:</strong> Group related tasks into projects and use labels to categorize them further.</li>
            <li><strong>Utilize Reminders and Notifications:</strong> Set reminders to keep you on track and receive notifications for upcoming deadlines.</li>
            <li><strong>Sync Across Devices:</strong> Access Todoist on your computer, smartphone, or tablet to stay organized wherever you go.</li>
          </ol>
          <h4 className="text-center mb-4"><strong>Google Calendar:</strong></h4>
          <p>Google Calendar is a versatile tool for managing your schedule and events. Here's how to use it for your study schedule:</p>
          <ol>
            <li><strong>Create Events:</strong> Add study sessions, classes, appointments, and other events to your calendar.</li>
            <li><strong>Set Reminders:</strong> Receive notifications and reminders for upcoming events and deadlines.</li>
            <li><strong>Color Code Events:</strong> Use different colors to categorize events by type or priority.</li>
            <li><strong>Share and Collaborate:</strong> Share your calendar with study partners or family members to coordinate schedules and activities.</li>
            <li><strong>Integrate with Other Tools:</strong> Sync Google Calendar with Todoist or other task management apps for seamless organization.</li>
          </ol>
          <p>By using Todoist and Google Calendar together, you can effectively plan and manage your study schedule, ensuring productivity and progress towards your academic goals.</p>
        </div>

        {/* Detailed information for each topic */}
        <div className="mb-5" ref={studyGroupsRef}>
          <h1 className="text-center mb-4">Study Groups</h1>
          <p>Maximize learning through study groups:</p>
          <ol>
            <li><strong>Find Reliable Study Partners:</strong> Collaborate with dedicated peers who share similar academic goals.</li>
            <li><strong>Establish Clear Study Objectives:</strong> Define study goals and objectives for each session to stay focused.</li>
            <li><strong>Encourage Active Participation:</strong> Foster an environment of open discussion and participation to enhance understanding.</li>
            <li><strong>Share Resources and Responsibilities:</strong> Distribute study materials and tasks efficiently among group members.</li>
            <li><strong>Provide Constructive Feedback:</strong> Offer feedback and support to group members to facilitate growth and learning.</li>
          </ol>
          <p><strong>Key Point:</strong> Effective communication and collaboration are essential for productive study groups.</p>
          <p><strong>Additional Resources:</strong></p>
          <ul>
            <li><strong><a href="https://discord.com/">Discord:</a></strong> Create a dedicated server for your study group on Discord, allowing real-time communication and file sharing. Or join ours :)</li>
            <li><strong><a href="https://slack.com/">Slack:</a></strong> Utilize Slack channels to organize discussions, share resources, and collaborate on group projects.</li>
            <li><strong><a href="https://www.google.com/docs">Google Docs:</a></strong> Collaboratively work on study guides, summaries, and presentations using Google Docs, Sheets, and Slides.</li>
            <li><strong><a href="https://zoom.us/">Zoom:</a></strong> Host virtual study sessions or group meetings on Zoom, enabling screen sharing and breakout rooms for focused discussions.</li>
          </ul>
        </div>

        <div className="mb-5" ref={practiceRef}>
          <h1 className="text-center mb-4">Practice!</h1>
          <p>Enhance learning through practice techniques:</p>
          <ol>
            <li><strong>Engage in Hands-on Activities:</strong> Participate in practical exercises, simulations, and case studies to apply theoretical knowledge. Actively involve yourself in laboratory sessions, clinical rotations, or virtual patient scenarios to reinforce understanding.</li>
            <li><strong>Utilize Resources:</strong> Make use of a variety of resources to supplement your learning. Textbooks, online platforms, and educational tools such as virtual anatomy models or interactive quizzes can provide additional practice opportunities and reinforce concepts.</li>
            <li><strong>Seek Feedback:</strong> Actively seek feedback from mentors, instructors, or peers to identify areas for improvement. Discuss challenging topics or cases with colleagues and receive constructive criticism to enhance your understanding and skills.</li>
            <li><strong>Reflect Regularly:</strong> Take time to reflect on your performance and learning progress. Analyze your strengths and weaknesses, identify learning gaps, and adjust your study approach accordingly. Keep a journal or use reflective exercises to track your growth and development.</li>
            <li><strong>Consistency is Key:</strong> Make practice a regular part of your study routine. Allocate dedicated time for practice sessions and integrate them into your daily or weekly schedule. Consistent practice over time is essential for mastering skills and concepts.</li>
          </ol>
          <p><strong>Key Point:</strong> Practice is not only about repetition but also about active engagement and reflection, leading to deeper learning and skill development.</p>
          <p><strong>Additional Resources:</strong></p>
          <ul>
            <li><strong><a href="https://www.khanacademy.org/">Khan Academy:</a></strong> Access a wide range of interactive courses, practice exercises, and instructional videos covering various medical topics and disciplines.</li>
            <li><strong><a href="https://www.coursera.org/">Coursera:</a></strong> Enroll in online courses offered by leading universities and institutions worldwide, featuring hands-on projects and peer-reviewed assignments.</li>
            <li><strong><a href="https://apps.ankiweb.net/">Anki:</a></strong> Use a spaced repetition flashcard app like Anki to create and review digital flashcards for efficient memorization and recall of medical terminology, concepts, and facts.</li>
            <li><strong><a href="https://quizlet.com/">Quizlet:</a></strong> Explore a vast library of user-generated flashcards and study sets covering medical subjects, or create your own customized flashcards for self-assessment and review.</li>
            <li><strong><a href="https://www.medscape.com/">Medscape:</a></strong> Access clinical reference tools, medical news, expert commentary, and case-based articles to stay updated on current medical practices and research.</li>
            <li><strong><a href="https://www.osmosis.org/">Osmosis:</a></strong> Utilize a comprehensive medical education platform offering video lessons, clinical case simulations, and spaced repetition quizzes to reinforce learning and prepare for exams.</li>
            <li><strong><a href="https://www.drawittoknowit.com/">Draw It to Know It:</a></strong> Engage in visual learning with interactive drawing tutorials and quizzes focused on medical anatomy, physiology, and pathology.</li>
            <li><strong>Online Forums and Study Groups:</strong> Join online communities, forums, or study groups dedicated to medical education and collaboration. Platforms like Reddit's <a href="https://www.reddit.com/r/medicalschool/">r/medicalschool</a> or <a href="https://forums.studentdoctor.net/">Student Doctor Network</a> provide opportunities to connect with peers, share resources, and seek advice.</li>
          </ul>
        </div>
        
        <div className="mb-5" ref={selfCareRef}>
          <h1 className="text-center mb-4">Self Care</h1>
          <p>Maintaining your well-being is crucial for long-term success in medical school. Here are some self-care practices to incorporate into your routine:</p>
          <ol>
            <li><strong>Allocate Time for Relaxation:</strong> Schedule regular breaks throughout your day and engage in activities that promote relaxation and stress relief. Whether it's reading a book, taking a walk, or listening to music, find what helps you unwind.</li>
            <li><strong>Practice Mindfulness:</strong> Incorporate mindfulness techniques such as meditation, deep breathing exercises, or yoga into your daily routine. These practices can help reduce stress, improve focus, and enhance overall well-being.</li>
            <li><strong>Stay Physically Active:</strong> Prioritize regular exercise and physical activity to support your physical health and mental well-being. Whether it's going for a run, attending a fitness class, or practicing a sport, find activities that you enjoy and make them a regular part of your routine.</li>
            <li><strong>Cultivate a Support Network:</strong> Build relationships with peers, mentors, and family members who provide emotional support and encouragement. Having a strong support network can help you navigate the challenges of medical school and maintain a positive outlook.</li>
            <li><strong>Seek Professional Help:</strong> Don't hesitate to seek professional assistance if you're experiencing excessive stress, anxiety, or mental health challenges. University counseling services, mental health hotlines, or therapy sessions can provide valuable support and resources to help you cope with challenges and prioritize your well-being.</li>
          </ol>
          <p><strong>Key Point:</strong> Prioritizing self-care is not selfish—it's essential for maintaining your physical health, mental well-being, and overall happiness throughout your medical school journey.</p>
          <p><strong>Additional Resources:</strong></p>
          <ul>
            <li><strong><a href="https://www.headspace.com/">Headspace:</a></strong> A popular app offering guided meditation and mindfulness exercises to reduce stress, improve sleep, and increase focus.</li>
            <li><strong><a href="https://www.calm.com/">Calm:</a></strong> Another highly-rated app providing meditation, relaxation, and sleep aid features to promote overall well-being and relaxation.</li>
            <li><strong>University Counseling Services:</strong> Many universities offer confidential counseling services for students to address mental health concerns and receive support from trained professionals.</li>
          </ul>
        </div>
        
      </Container>
      <div style={{ textAlign: "center", marginTop: "50px" }}>
    <a href="#" className="top-of-page-link" style={{alignItems: 'center', fontSize: '23px'}}>Back to Top</a> {/* Link to the top of the page */}
    </div>
    <footer style={{marginTop:'5vh'}}>
    <Footer />
    </footer>
    </div>
  )
}

export default StudyTips;
