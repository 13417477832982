import React from 'react';
import Nav from 'react-bootstrap/Nav';



const Sidebar = ({ pageTitle }) => {

  // Define a map of page titles and their corresponding sidebar content

  const sidebarContentMap = new Map([
    ['Anatomy', (
      <div>
        <Nav  className="flex-column">
      <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
      <Nav.Link href="#cellular-composition" className="nav-link custom-link text-center"  style={linkStyle}>Cellular Composition</Nav.Link>
      <Nav.Link href="#microscopic-anatomy" className="nav-link custom-link text-center"  style={linkStyle}>Microscopic Anatomy</Nav.Link>
      <Nav.Link href="#embyrology" className="nav-link custom-link text-center"  style={linkStyle}>Embryology</Nav.Link>
      <Nav.Link href="#neuroanatomy" className="nav-link custom-link text-center"  style={linkStyle}>Neuroanatomy</Nav.Link>
      <Nav.Link href="#radiological-anatomy" className="nav-link custom-link text-center"  style={linkStyle}>Radiological Anatomy</Nav.Link>
      <Nav.Link href="#clinical-anatomy" className="nav-link custom-link text-center"  style={linkStyle}>Clinical Anatomy</Nav.Link>
    </Nav>
      </div>
    )],
    ['Histology', (
      <div>
  
        <Nav className="flex-column">
        <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
        <Nav.Link href="#cellular-composition" className="nav-link custom-link text-center "  style={linkStyle}>Cellular Composition</Nav.Link>
        <Nav.Link href="#tissue-types" className="nav-link custom-link text-center "  style={linkStyle}>Tissue Types</Nav.Link>
        <Nav.Link href="#microscopic-anatomy" className="nav-link custom-link text-center "  style={linkStyle}>Microscopic Anatomy</Nav.Link>
    </Nav>
      </div>
    )],
    ['Biochemistry', (
      <div>
        <Nav className="flex-column">
      <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
      <Nav.Link href="#macromolecules" className="nav-link custom-link text-center "  style={linkStyle}>Macromolecules</Nav.Link>
      <Nav.Link href="#metabolic-pathways" className="nav-link custom-link text-center "  style={linkStyle}>Metabolic Pathways</Nav.Link>
      <Nav.Link href="#molecular-genetics" className="nav-link custom-link text-center "  style={linkStyle}>MolecularGenetics</Nav.Link>
      <Nav.Link href="#cellular-signaling" className="nav-link custom-link text-center "  style={linkStyle}>Cellular Signaling</Nav.Link>
      <Nav.Link href="#medical-biochemistry" className="nav-link custom-link text-center "  style={linkStyle}>Medical Biochemistry</Nav.Link>
      
    </Nav>
      </div>
    )],
    ['Pharmacology', (
      <div>
         <Nav className="flex-column">
         <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
         <Nav.Link href="#drug-classification" className="nav-link custom-link text-center "  style={linkStyle}>Drug Classification</Nav.Link>
         <Nav.Link href="#pharmacokinetics" className="nav-link custom-link text-center "  style={linkStyle}>Pharmacokinetics</Nav.Link>
         <Nav.Link href="#pharmacodynamics" className="nav-link custom-link text-center "  style={linkStyle}>Pharmacodynamics</Nav.Link>
         <Nav.Link href="#drug-development-and-regulation" className="nav-link custom-link text-center "  style={linkStyle}>Drug Development and Regulation</Nav.Link>
         <Nav.Link href="#clinical-pharmacology" className="nav-link custom-link text-center "  style={linkStyle}>Clinical Pharmacology</Nav.Link>
         <Nav.Link href="#toxicology" className="nav-link custom-link text-center "  style={linkStyle}>Toxicology</Nav.Link>
    </Nav>      </div>
    )],
    ['Microbiology', (
      <div>
        <Nav className="flex-column">
        <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
        <Nav.Link href="#microbial-classification" className="nav-link custom-link text-center "  style={linkStyle}>Microbial Classification</Nav.Link>
        <Nav.Link href="#microbial-structure-and-function" className="nav-link custom-link text-center "  style={linkStyle}>Microbial Structure and Function</Nav.Link>
        <Nav.Link href="#microbial-growth-and-reproduction" className="nav-link custom-link text-center "  style={linkStyle}>Microbial Growth and Reproduction</Nav.Link>
        <Nav.Link href="#microbial-genetics" className="nav-link custom-link text-center "  style={linkStyle}>Microbial Genetics</Nav.Link>
        <Nav.Link href="#microbial-pathogenesis" className="nav-link custom-link text-center "  style={linkStyle}>Microbial Pathogenesis</Nav.Link>
        <Nav.Link href="#microbial-ecology" className="nav-link custom-link text-center "  style={linkStyle}>Microbial Ecology</Nav.Link>
    </Nav>     </div>
    )],
    ['Immunology', (
      <div>
         <Nav className="flex-column">
         <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
         <Nav.Link href="#immune-system-component" className="nav-link custom-link text-center "  style={linkStyle}>Immune System Component</Nav.Link>
         <Nav.Link href="#immune-response-mechanisms" className="nav-link custom-link text-center "  style={linkStyle}>Immune Response Mechanisms</Nav.Link>
         <Nav.Link href="#immunological-disorders" className="nav-link custom-link text-center "  style={linkStyle}>Immunological Disorders</Nav.Link>
         <Nav.Link href="#immunotherapy" className="nav-link custom-link text-center "  style={linkStyle}>Immunotherapy</Nav.Link>
         <Nav.Link href="#vaccinology" className="nav-link custom-link text-center "  style={linkStyle}>Vaccinology</Nav.Link>
         <Nav.Link href="#transplant-immunolog" className="nav-link custom-link text-center "  style={linkStyle}>Transplant Immunolog</Nav.Link>

    </Nav>      </div>
    )],
    ['Genetics', (
      <div>
         <Nav className="flex-column">
         <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
         <Nav.Link href="#mendelian-genetics" className="nav-link custom-link text-center "  style={linkStyle}>Mendelian Genetics</Nav.Link>
         <Nav.Link href="#molecular-genetics" className="nav-link custom-link text-center "  style={linkStyle}>Molecular Genetics</Nav.Link>
         <Nav.Link href="#population-genetics" className="nav-link custom-link text-center "  style={linkStyle}>Population Genetics</Nav.Link>
         <Nav.Link href="#genetic-engineering" className="nav-link custom-link text-center "  style={linkStyle}>Genetic Engineerings</Nav.Link>
         <Nav.Link href="#human-genetics" className="nav-link custom-link text-center "  style={linkStyle}>Human Genetics</Nav.Link>
         <Nav.Link href="#medical-genetics" className="nav-link custom-link text-center "  style={linkStyle}>Medical Genetics</Nav.Link>
         <Nav.Link href="#evolutionary-genetics" className="nav-link custom-link text-center "  style={linkStyle}>Evolutionary Genetics</Nav.Link>
    </Nav>      </div>
    )],
    ['Embryology', (
      <div>
        <Nav className="flex-column">
        <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
        <Nav.Link href="#embryonic-development" className="nav-link custom-link text-center "  style={linkStyle}>Embryonic Development</Nav.Link>
        <Nav.Link href="#germ-layer-differentiation" className="nav-link custom-link text-center "  style={linkStyle}>Germ Layer Differentiation</Nav.Link>
        <Nav.Link href="#organogenesis" className="nav-link custom-link text-center "  style={linkStyle}>Organogenesis</Nav.Link>
        <Nav.Link href="#developmental-genetics" className="nav-link custom-link text-center "  style={linkStyle}>Developmental Genetics</Nav.Link>
        <Nav.Link href="#teratogenesis" className="nav-link custom-link text-center "  style={linkStyle}>Teratogenesis</Nav.Link>
    </Nav>      </div>
    )],



    /*Clinical Sciences */


    ['InternalMedicine', (
      <div>
        <Nav className="flex-column">
        <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
        <Nav.Link href="#primary-care" className="nav-link custom-link text-center "  style={linkStyle}>Primary Care</Nav.Link>
        <Nav.Link href="#diagnosis-and-management" className="nav-link custom-link text-center "  style={linkStyle}>Diagnosis and Management</Nav.Link>
        <Nav.Link href="#specialized-care" className="nav-link custom-link text-center "  style={linkStyle}>Specialized Care</Nav.Link>
        <Nav.Link href="#inpatient-care" className="nav-link custom-link text-center "  style={linkStyle}>Inpatient Care</Nav.Link>
        <Nav.Link href="#geriatric-care" className="nav-link custom-link text-center "  style={linkStyle}>Geriatric Care</Nav.Link>
        <Nav.Link href="#preventive-medicine" className="nav-link custom-link text-center "  style={linkStyle}>Preventive Medicine</Nav.Link>
        <Nav.Link href="#health-promotion" className="nav-link custom-link text-center "  style={linkStyle}>Health Promotion</Nav.Link>
    </Nav>      </div>
    )],
    ['Surgery', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
       <Nav.Link href="#anatomy-and-physiology" className="nav-link custom-link text-center "  style={linkStyle}>Anatomy and Physiology</Nav.Link>
       <Nav.Link href="#preoperative-evaluation-management" className="nav-link custom-link text-center "  style={linkStyle}>Preoperative Evaluation Management</Nav.Link>
       <Nav.Link href="#surgical-techniques" className="nav-link custom-link text-center "  style={linkStyle}>Surgical Techniques</Nav.Link>
    </Nav>      </div>
    )],
    ['Pediatrics', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#growth-and-development" className="nav-link custom-link text-center "  style={linkStyle}>Growth and Development</Nav.Link>
       <Nav.Link href="#childhood-diseases-and-conditions" className="nav-link custom-link text-center "  style={linkStyle}>Childhood Diseases and Conditions</Nav.Link>
    </Nav>      </div>
    )],
    ['ObstetricsandGynecology', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#female-reproductive-anatomy" className="nav-link custom-link text-center "  style={linkStyle}>Female Reproductive Anatomy</Nav.Link>
       <Nav.Link href="#embryology-of-the-female-reproductive-system" className="nav-link custom-link text-center "  style={linkStyle}>Embryology of the Female Reproductive System</Nav.Link>
       <Nav.Link href="#normal-histology-of-the-female-reproductive-organs" className="nav-link custom-link text-center "  style={linkStyle}>Normal Histology of the Female Reproductive Organs</Nav.Link>
       <Nav.Link href="#pathological-histology-of-gynecological-disorders" className="nav-link custom-link text-center "  style={linkStyle}>Pathological Histology of Gynecological Disorders</Nav.Link>
       <Nav.Link href="#placental-histology" className="nav-link custom-link text-center "  style={linkStyle}>Placental Histology</Nav.Link>
       <Nav.Link href="#microscopic-anatomy-of-the-breast" className="nav-link custom-link text-center "  style={linkStyle}>Microscopic Anatomy of the Breast</Nav.Link>
       <Nav.Link href="#clinical-correlations-in-obstetrics-and-gynecology" className="nav-link custom-link text-center "  style={linkStyle}>Clinical Correlations in Obstetrics and Gynecology</Nav.Link>
    </Nav>      </div>
    )],
    ['Psychiatry', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
       <Nav.Link href="#neurobiology" className="nav-link custom-link text-center "  style={linkStyle}>Neurobiology</Nav.Link>
       <Nav.Link href="#psychological" className="nav-link custom-link text-center "  style={linkStyle}>Psychological Theories</Nav.Link>
       <Nav.Link href="#diagnostic" className="nav-link custom-link text-center "  styles={linkStyle}>Diagnostic</Nav.Link>
    </Nav>      </div>
    )],
    ['Neurology', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
       <Nav.Link href="#neuronal-structure" className="nav-link custom-link text-center "  style={linkStyle}>Neuronal Structure</Nav.Link>
    </Nav>     </div>
    )],
    ['FamilyMedicine', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
       <Nav.Link href="#primary-care-principles" className="nav-link custom-link text-center "  style={linkStyle}>Primary Care Principles</Nav.Link>
    </Nav>      </div>
    )],
    ['Radiology', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
       <Nav.Link href="#radiographic-techniques" className="nav-link custom-link text-center "  style={linkStyle}>Radiographic Techniques</Nav.Link>
       <Nav.Link href="#interventional-radiology" className="nav-link custom-link text-center "  style={linkStyle}>Interventional Radiology</Nav.Link>
    </Nav>    </div>
    )],
    ['EmergencyMedicine', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
       <Nav.Link href="#emergency-assessment-and-triage" className="nav-link custom-link text-center "  style={linkStyle}>Emergency Assessment and Triage</Nav.Link>
       <Nav.Link href="#resuscitation-and-critical-care" className="nav-link custom-link text-center "  style={linkStyle}>Resuscitation and Critical Care</Nav.Link>
       <Nav.Link href="#trauma-management" className="nav-link custom-link text-center "  style={linkStyle}>Trauma Management</Nav.Link>
       <Nav.Link href="#cardiac-emergencies" className="nav-link custom-link text-center "  style={linkStyle}>Cardiac Emergencies</Nav.Link>
    </Nav>      </div>
    )],
    ['Anesthesiology', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#types-of-anesthesia" className="nav-link custom-link text-center "  style={linkStyle}>Types of Anesthesia</Nav.Link>
       <Nav.Link href="#pharmacology-of-anesthetic-agents" className="nav-link custom-link text-center "  style={linkStyle}>Pharmacology of Anesthetic Agents</Nav.Link>
       <Nav.Link href="#physiology-of-anesthesia" className="nav-link custom-link text-center "  style={linkStyle}>Physiology of Anesthesia</Nav.Link>

    </Nav>      </div>
    )],


    /*Professional Development and Skills */


    ['MedicalEthics', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
       <Nav.Link href="#ethical-principles" className="nav-link custom-link text-center "  style={linkStyle}>Ethical Principles</Nav.Link>
       <Nav.Link href="#professionalism-and-integrity" className="nav-link custom-link text-center "  style={linkStyle}>Professionalism and Integrity</Nav.Link>
    </Nav>      </div>
    )],
    ['MedicalJurisprudence', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
       <Nav.Link href="#forensic-pathology" className="nav-link custom-link text-center "  style={linkStyle}>Forensic Pathology</Nav.Link>
       <Nav.Link href="#medical-ethics" className="nav-link custom-link text-center "  style={linkStyle}>Medical Ethics</Nav.Link>
       <Nav.Link href="#medical-malpractice" className="nav-link custom-link text-center "  style={linkStyle}>Medical Malpractice</Nav.Link>
       <Nav.Link href="#healthcare-law-and-regulation" className="nav-link custom-link text-center "  style={linkStyle}>Healthcare Law and Regulation</Nav.Link>

    </Nav>      </div>
    )],
    ['CommunicationSkills', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
       <Nav.Link href="#verbal-communication" className="nav-link custom-link text-center "  style={linkStyle}>Verbal Communication</Nav.Link>
       <Nav.Link href="#nonverbal-communication" className="nav-link custom-link text-center "  style={linkStyle}>Nonverbal Communication</Nav.Link>
       <Nav.Link href="#active-listening" className="nav-link custom-link text-center "  style={linkStyle}>Active Listening</Nav.Link>
       <Nav.Link href="#empathic-communication" className="nav-link custom-link text-center "  style={linkStyle}>Empathic Communication</Nav.Link>
    </Nav>      </div>
    )],
    ['ExaminationSkills', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
       <Nav.Link href="#history-taking" className="nav-link custom-link text-center "  style={linkStyle}>History Taking</Nav.Link>
       <Nav.Link href="#physical-examination" className="nav-link custom-link text-center "  style={linkStyle}>Physical Examination</Nav.Link>
       <Nav.Link href="#clinical-reasoning" className="nav-link custom-link text-center "  style={linkStyle}>Clinical Reasoning</Nav.Link>
       <Nav.Link href="#diagnostic-testing" className="nav-link custom-link text-center "  style={linkStyle}>Diagnostic Testing</Nav.Link>
       <Nav.Link href="#communication-skills" className="nav-link custom-link text-center "  style={linkStyle}>Communication Skills</Nav.Link>
       <Nav.Link href="#documentation" className="nav-link custom-link text-center "  style={linkStyle}>Documentation</Nav.Link>
       <Nav.Link href="#professionalism-and-ethics" className="nav-link custom-link text-center "  style={linkStyle}>Professionalism and Ethics</Nav.Link>
    </Nav>      </div>
    )],
    ['ClinicalReasoning', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
       <Nav.Link href="#diagnostic-reasoning" className="nav-link custom-link text-center "  style={linkStyle}>Diagnostic Reasoning</Nav.Link>
       <Nav.Link href="#therapeutic-reasoning" className="nav-link custom-link text-center "  style={linkStyle}>Therapeutic Reasoning</Nav.Link>
       <Nav.Link href="#prognostic-reasoning" className="nav-link custom-link text-center "  style={linkStyle}>Prognostic Reasoning</Nav.Link>
       <Nav.Link href="#clinical-reasoning-process" className="nav-link custom-link text-center "  style={linkStyle}>Clinical Reasoning Process</Nav.Link>

    </Nav>      </div>
    )],
    ['MedicalDocumentation', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
       <Nav.Link href="#documentation-standards" className="nav-link custom-link text-center "  style={linkStyle}>Documentation Standards</Nav.Link>
       <Nav.Link href="#record-keeping-practices" className="nav-link custom-link text-center "  style={linkStyle}>Record Keeping Practices</Nav.Link>
       <Nav.Link href="#privacy-and-confidentiality" className="nav-link custom-link text-center "  style={linkStyle}>Privacy and Confidentiality</Nav.Link>
       <Nav.Link href="#interdisciplinary-communication" className="nav-link custom-link text-center "  style={linkStyle}>Interdisciplinary Communication</Nav.Link>
    </Nav>      </div>
    )],
    ['Professionalism', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
       <Nav.Link href="#ethical-principles" className="nav-link custom-link text-center "  style={linkStyle}>Ethical Principles</Nav.Link>
       <Nav.Link href="#communication-skills" className="nav-link custom-link text-center "  style={linkStyle}>Communication Skills</Nav.Link>
       <Nav.Link href="#interprofessional-collaboration" className="nav-link custom-link text-center "  style={linkStyle}>Interprofessional Collaboration</Nav.Link>
    </Nav>      </div>
    )],
    ['CulturalCompetence', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
       <Nav.Link href="#understanding-cultural-diversity" className="nav-link custom-link text-center "  style={linkStyle}>Understanding Cultural Diversity</Nav.Link>
       <Nav.Link href="#cultural-sensitivity-awareness" className="nav-link custom-link text-center "  style={linkStyle}>Cultural Sensitivity Awareness</Nav.Link>
       <Nav.Link href="#communication-across-cultures" className="nav-link custom-link text-center "  style={linkStyle}>Communication Across Cultures</Nav.Link>
    </Nav>      </div>
    )],
    ['Leadership', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
       <Nav.Link href="#leadership-theories" className="nav-link custom-link text-center "  style={linkStyle}>Leadership Theories</Nav.Link>
       <Nav.Link href="#communication-skills" className="nav-link custom-link text-center "  style={linkStyle}>Communication Skills</Nav.Link>
    </Nav>      </div>
    )],

    /*Clincial Practice and Procedures*/


    ['ClinicalSkills', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
       <Nav.Link href="#history-taking" className="nav-link custom-link text-center "  style={linkStyle}>History Taking</Nav.Link>
       <Nav.Link href="#physical-examination" className="nav-link custom-link text-center "  style={linkStyle}>Physical Examination</Nav.Link>
       <Nav.Link href="#communication-skills" className="nav-link custom-link text-center "  style={linkStyle}>Communication Skills</Nav.Link>
       <Nav.Link href="#diagnostic-procedures" className="nav-link custom-link text-center "  style={linkStyle}>Diagnostic Procedures</Nav.Link>
    </Nav>      </div>
    )],['PatientHistory', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#interview-techniques" className="nav-link custom-link text-center "  style={linkStyle}>Interview Techniques</Nav.Link>
       <Nav.Link href="#medical-history-components" className="nav-link custom-link text-center "  style={linkStyle}>Medical History Components</Nav.Link>
       <Nav.Link href="#social-and-environmental-history" className="nav-link custom-link text-center "  style={linkStyle}>Social and Environmental History</Nav.Link>
    </Nav>      </div>
    )],['ClinicalProcedures', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
       <Nav.Link href="#patient-assessment" className="nav-link custom-link text-center "  style={linkStyle}>Patient Assessment</Nav.Link>
       <Nav.Link href="#diagnostic-procedures" className="nav-link custom-link text-center "  style={linkStyle}>Diagnostic Procedures</Nav.Link>
       <Nav.Link href="#therapeutic-interventions" className="nav-link custom-link text-center "  style={linkStyle}>Therapeutic Interventions</Nav.Link>
       <Nav.Link href="#procedural-skills" className="nav-link custom-link text-center "  style={linkStyle}>Procedural Skills</Nav.Link>
 

    </Nav>
          </div>
    )],['DiagnosticTechniques', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link> 
       <Nav.Link href="#history-and-physical-examination" className="nav-link custom-link text-center "  style={linkStyle}>History and Physical Examination</Nav.Link> 
       <Nav.Link href="#laboratory-investigations" className="nav-link custom-link text-center "  style={linkStyle}>Laboratory Investigations</Nav.Link> 
       <Nav.Link href="#imaging-studies" className="nav-link custom-link text-center "  style={linkStyle}>Imaging Studies</Nav.Link> 
       <Nav.Link href="#invasive-procedures" className="nav-link custom-link text-center "  style={linkStyle}>Invasive Procedures</Nav.Link>
       
    </Nav>      </div>
    )],['SurgicalTechniques', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
       <Nav.Link href="#anatomy-and-physiology" className="nav-link custom-link text-center "  style={linkStyle}>Anatomy and Physiology</Nav.Link>
       <Nav.Link href="#surgical-instrumentation" className="nav-link custom-link text-center "  style={linkStyle}>Surgical Instrumentation</Nav.Link>
       <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
       <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
    </Nav>      </div>
    )],['ElectronicHealthRecords', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#introduction" className="nav-link custom-link text-center "  style={linkStyle}>Introduction</Nav.Link>
       <Nav.Link href="#concept-and-components-of-ehrs" className="nav-link custom-link text-center "  style={linkStyle}>Concept and Components of Ehrs</Nav.Link>
       <Nav.Link href="#integration-and-interoperability" className="nav-link custom-link text-center "  style={linkStyle}>Integration and Interoperability</Nav.Link>
    </Nav>      </div>
    )],['InterprofessionalCollaboration', (
      <div>
       <Nav className="flex-column">
       <Nav.Link href="#definition-importance-of-ipc" className="nav-link custom-link text-center "  style={linkStyle}>Definition Importance of IPC</Nav.Link>
       <Nav.Link href="#roles-responsibilities-of-healthcare-professionals" className="nav-link custom-link text-center "  style={linkStyle}>Roles Responsibilities of Healthcare Professionals</Nav.Link>
       <Nav.Link href="#effective-communication-strategies" className="nav-link custom-link text-center "  style={linkStyle}>Effective Communication Strategies</Nav.Link>
       <Nav.Link href="#team-based-care-models" className="nav-link custom-link text-center "  style={linkStyle}>Team Based Care Models</Nav.Link>
       <Nav.Link href="#technology-and-telehealth-in-ipc" className="nav-link custom-link text-center "  style={linkStyle}>Technology and Telehealth in IPC</Nav.Link>
    </Nav>      </div>
    )],

  ]);
  // Default sidebar content if pageTitle is not found in the map
  let sidebarContent = sidebarContentMap.get(pageTitle);
  if (!sidebarContent) {
    sidebarContent = (
      <div>
        <h5>Sidebar for Default Page</h5>
      </div>
    );
  }
  return (
    <div className="sidebar-container">
    <h3>Content List</h3>
    <div>{sidebarContent}</div>
   </div>
  );
};
const linkStyle = {
  display: 'block',
  padding: '10px',
  backgroundColor: '#222',
  color: '#fff',
  borderRadius: '5px',
  marginBottom: '5px',
  transition: 'opacity 0.3s ease',
  width: '70%', // Adjust the width as needed
};

// Add hover effect to the buttons
linkStyle[':hover'] = {
  opacity: 0.8,
};

// Media query for smaller screen sizes
const smallScreen = '@media (max-width: 768px)';
// Media query for larger screen sizes
const largeScreen = '@media (min-width: 768px)';


linkStyle[smallScreen] = {
  fontSize: '12px', 
};


linkStyle[largeScreen] = {
  fontSize: '16px', 
};
export default Sidebar;
