import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar, Button, NavDropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { SiDiscord } from "react-icons/si";
import { motion } from "framer-motion";
import '../css/master.css';

const NavBar = ({ darkMode, toggleDarkMode }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Animation variants for staggered effect
    const dropdownVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2, // Delay between each child animation
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, x: -20 },
        visible: { opacity: 1, x: 0, transition: { duration: 0.4 } }
    };

    return (
        <Navbar bg={darkMode ? 'dark' : 'light'} data-bs-theme='dark' expand="lg" className="bg-body-tertiary sticky-top">
            <Container>
                <Navbar.Brand href="/">
                    <img alt="Logo" src="/images/Logo.png" width="40" height="40" className="d-inline align-top rounded" />
                </Navbar.Brand>
            </Container>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" style={{ marginRight: '290px' }}>
                <Nav variant="underline" className="col-12 col-md-auto mb-12 mb-md-0" id='Navbar-links'>
                    {/* Navigation links */}
                    <Link to="/" className="nav-link text-light" style={{ fontSize: '18px' }}>Home</Link>
                    <Link to="/Question-Bank" className="nav-link text-light" style={{ fontSize: '18px' }}>Questions</Link>
                    <Link to="/FAQ" className="nav-link text-light" style={{ fontSize: '18px' }}>FAQ</Link>
                    <Link to="/About" className="nav-link text-light" style={{ fontSize: '18px' }}>About Us</Link>

                    {/* Staggered Dropdown Menu */}
                    <NavDropdown
                        id="basic-nav-dropdown"
                        title={<span className="text-light" style={{ fontSize: '18px' }}>Resources</span>}
                    >
                        <motion.div variants={dropdownVariants} initial="hidden" animate="visible">
                            <motion.div variants={itemVariants}>
                                <NavDropdown.Item href="/Medical-Terminology-List">Medical Dictionary</NavDropdown.Item>
                            </motion.div>
                            <motion.div variants={itemVariants}>
                                <NavDropdown.Item href="/Question-Database">Question Database</NavDropdown.Item>
                            </motion.div>
                            <motion.div variants={itemVariants}>
                                <NavDropdown.Item href="/Study-Tips">Study Tips</NavDropdown.Item>
                            </motion.div>
                            <motion.div variants={itemVariants}>
                                <NavDropdown.Divider />
                            </motion.div>
                            <motion.div variants={itemVariants}>
                                <NavDropdown.Item href="/Contact">Contact Us</NavDropdown.Item>
                            </motion.div>
                        </motion.div>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>

            <div className="col-md-3 text-end pe-4">
                <Button href="https://discord.gg/8YwYPVYrXd" className={`btn btn-primary ${windowWidth > 768 ? 'btn-large' : 'btn-small'}`}>
                    <SiDiscord /><strong> Join Our Discord</strong>
                </Button>
            </div>
        </Navbar>
    );
}

export default NavBar;
