import React, { useState, useEffect } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { SiDiscord } from "react-icons/si";
import { BsGithub } from "react-icons/bs";
import { SiCoffeescript } from "react-icons/si";

export const About = () => {
  const [darkMode, setDarkMode] = useState(false); // Dark Mode
  const [pop, setPop] = useState(false);

  useEffect(() => {
    setPop(true);
  }, []);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className={darkMode ? 'dark-mode' : ''}>
      <NavBar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      <Container className={`px-4 py-5 my-5 text-center ${pop ? 'pop' : ''}`}>
        <Image className="d-block mx-auto mb-4" src="/images/ProfileImg.jpg" alt="ProfileImg" width="175" height="195" roundedCircle />
        <h1 className={`display-5 fw-bold ${darkMode ? 'text-light' : 'text-body-emphasis'}`}>About Us</h1>
        <Row className="justify-content-center">
          <Col lg={9}>
            <p className={`lead mb-4 py-4 ${darkMode ? 'text-light' : ''}`}>
              Welcome to Med-Bud! My name is Christopher, and I'm thrilled to introduce you to this platform designed with the needs of aspiring medical students in mind. As the partner of a dedicated future physician, I've witnessed firsthand the challenges and demands of the journey toward medical school. Motivated by a desire to support and empower individuals like my fiance, I embarked on a mission to create Med-Bud.
            </p>
            <p className='lead mb-5'>Med-Bud provides a wealth of information on a diverse range of medical topics, from Anatomy to Medical Ethics. We offer extensive questions within our question bank, comprehensive lists of medical terminology, and practical study tips to support your educational journey. Med-Bud is here to guide you every step of the way.</p>
            <div className="d-grid gap-2 d-sm-flex justify-content-lg-center">
              <Button href="https://discord.gg/8YwYPVYrXd" variant="primary" size="lg" className="px-4"><SiDiscord /> <strong>Join Our Discord</strong></Button>
              <Button href="https://github.com/ElementalVista" variant="primary" size="lg" className="px-4"><BsGithub /> <strong>Checkout My Github</strong></Button>
              <Button href="https://www.buymeacoffee.com/elementalvista" variant="primary" size="lg" className="px-4"><SiCoffeescript /> <strong>Buy Me a Coffee</strong></Button>
            </div>
          </Col>
        </Row>
      </Container>
      <footer style={{marginTop:'10vh'}}>
        {/* Footer */}
        <Footer />
      </footer>
    </div>
  );
};

export default About;
