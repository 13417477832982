import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { SiDiscord } from 'react-icons/si';
import { motion } from 'framer-motion'; 
import '../css/master.css';

const DiscordAdvertisement = () => {
  return (
    <Container>
    <motion.div
      whileHover={{ scale: 1.05 }} // Apply a slight scale-up when hovered over
      transition={{ type: "spring", stiffness: 200 }} // Smooth spring transition
    >
      <Container
        className="my-5 rounded-3"
        style={{ backgroundColor: '#343a40', color: '#ffffff' }}
      >
        <Row className="p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
          <Col lg={7} className="p-3 p-lg-5 pt-lg-3">
            <h1 className="display-4 fw-bold lh-1">Join our Discord!</h1>
            <p className="lead">
              We invite you to join our Discord community where you can connect with others who share similar interests, stay updated on our newest features, and enjoy the added perks of networking and collaboration. We look forward to welcoming you into our community!
            </p>
            <div className="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
              <Button href="https://discord.gg/8YwYPVYrXd" variant="primary" size="lg" className="px-4">
                <SiDiscord /> <strong>Join Our Discord</strong>
              </Button>
            </div>
          </Col>
          <Col lg={4} className="offset-lg-1 p-0 overflow-hidden shadow-lg">
            <img className="rounded-3" src="./images/Advertisment.jpg" alt="Advertisement-Picture" width="720" />
            </Col>
          </Row>
        </Container>
      </motion.div>
      </Container>
    );
  };

  export default DiscordAdvertisement;
