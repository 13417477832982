import React, { useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import ImageOverlay from './components/ImageOverlay';
import Categories from './components/Categories';
import CategoryTitle from './components/CategoryTitle';
import CardList from './components/CardList';
import OftheDay from './components/oftheDay';
import NavBarCategories from './components/NavBar-Categories';
import DiscordAdvertisement from './components/DiscordAdvertisement';
import './css/master.css';
import './css/footer.css';


function App() {

  
  // Creates separate refs for each video
  const videoRefs = useRef({
    Anatomy: useRef(null),
    Histology: useRef(null),
    Biochemistry: useRef(null),
    Pharmacology: useRef(null),
    Microbiology: useRef(null),
    Immunology: useRef(null),
    Genetics: useRef(null),
    Embryology: useRef(null),

    //Clinical Sciences
    'Internal Medicine': useRef(null),
    Surgery: useRef(null),
    Pediatrics: useRef(null),
    OBGYN: useRef(null),
    Psychiatry: useRef(null),
    Neurology: useRef(null),
    'Family Medicine': useRef(null),
    Radiology: useRef(null),
    'Emergency Medicine': useRef(null),
    Anesthesiology: useRef(null),

    //Professional Development and Skills
    'Medical Ethics': useRef(null),
    'Medical Jurisprudence': useRef(null),
    'Communication Skills': useRef(null),
    'Examination Skills': useRef(null),
    'Clinical Reasoning': useRef(null),
    'Medical Documentation': useRef(null),
    Professionalism: useRef(null),
    'Cultural Competence': useRef(null),
    Leadership: useRef(null),

    //Clinical Practice and Procedures
    'Clinical Skills': useRef(null),
    'Patient History': useRef(null),
    'Clinical Procedures': useRef(null),
    'Physical ExaminationTechniques': useRef(null),
    'Diagnostic Techniques': useRef(null),
    'Surgical Techniques': useRef(null),
    'Electronic Health Records': useRef(null),
    'Interprofessional Collaboration': useRef(null),
  });


  
  const [selectedCategory, setSelectedCategory] = useState('Medical Sciences');
  const [darkMode, setDarkMode] = useState(false); //Dark Mode

  // const [username, setUsername] = useState('');
  // const [password, setPassword] = useState('');
  // const [loggedIn, setLoggedIn] = useState(false);


  // const handleLogin = (e) => {
  //   e.preventDefault();
  //   // Check if the entered username and password are correct       tEst9faF$              ///HF30d*a
  //   if (username === 'tEst9faF$' && password === 'HF30d*a') {
  //     setLoggedIn(true);
  //   } else {
  //     alert('Incorrect username or password!');
  //   }
  // };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const handleMouseEnter = (videoName) => {
    if (videoRefs.current[videoName].current) {
      videoRefs.current[videoName].current.play();
    }
  };

  const handleMouseLeave = (videoName) => {
    if (videoRefs.current[videoName].current) {
      videoRefs.current[videoName].current.pause();
      videoRefs.current[videoName].current.currentTime = 0;
    }
  };

  // if (!loggedIn) {
  //   return (
     
  //     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
  //     <div style={{ width: '300px', padding: '20px', border: '1px solid #ccc', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
  //       <div className="login-form text-center">
  //         <form onSubmit={handleLogin}>
  //           <div style={{ marginBottom: '1rem' }}>
  //             <label style={{ display: 'block', marginBottom: '0.5rem' }}>
  //               Username:
  //             </label>
  //             <input
  //               type="text"
  //               value={username}
  //               onChange={(e) => setUsername(e.target.value)}
  //               style={{ width: '100%', padding: '0.5rem', borderRadius: '4px', border: '1px solid #ccc' }}
  //             />
  //           </div>
  //           <div style={{ marginBottom: '1rem' }}>
  //             <label style={{ display: 'block', marginBottom: '0.5rem' }}>
  //               Password:
  //             </label>
  //             <input
  //               type="password"
  //               value={password}
  //               onChange={(e) => setPassword(e.target.value)}
  //               style={{ width: '100%', padding: '0.5rem', borderRadius: '4px', border: '1px solid #ccc' }}
  //             />
  //           </div>
  //           <button
  //             type="submit"
  //             style={{ width: '100%', backgroundColor: '#007bff', color: '#fff', padding: '0.5rem', borderRadius: '4px', border: 'none' }}
  //           >
  //             Login
  //           </button>
  //         </form>
  //       </div>
  //     </div>
  //   </div>
  //   );
  // }

  return (
    <div className={darkMode ? 'dark-mode' : 'light-mode'} id='app' style={{ minHeight: '250vh' }}>
      {/* Nav Bar */}
      <NavBar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      <div className='sticky-nav-categories'>
      <NavBarCategories />
      </div>
      {/* Image Overlay */}
      <ImageOverlay />

      {/* Question/Quote of the day */}
      <div className='divMarginMidBreak'>
        <Container>
          <OftheDay />

          {/* Resource Category Title */}
          <CategoryTitle />

          {/* Categories */}
          <Categories selectedCategory={selectedCategory} handleCategorySelect={handleCategorySelect} darkMode={darkMode} />
          <div className='divMargin'>
          {/* CardLists */}
          <CardList  selectedCategory={selectedCategory} videoRefs={videoRefs} handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} darkMode={darkMode}  />
          </div>
        </Container>
      </div>
      <DiscordAdvertisement />

      {/* Footer */}
      <footer style={{marginTop:'10vh'}}>
        <Footer />
      </footer>
    </div>
  );
} 


export default App;




