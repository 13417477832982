import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NavBar from '../../../components/NavBar';
import NavBarCategories from '../../../components/NavBar-Categories';
import Sidebar from '../../../components/Sidebar';
import Footer from '../../../components/Footer';
import SidebarResource from '../../../components/Sidebar-Resource';

const biochemistry = () => {
  return (
    <div>
      <NavBar />
      <div className='sticky-nav-categories'>
        <NavBarCategories />
      </div>
      <div className="pt-3 my-5 d-flex">
        {/* Sidebar */}
        <Sidebar pageTitle="Biochemistry" />
        {/* Main Content */}
        <Container>
          <Row>
            <Col lg={12}>
              <div>
                <div>
                  <h1 className="display-4 fw-bold text-body-emphasis text-center">Biochemistry</h1>
                </div>
                <header id="the-nervous-system"></header>

                <section id='introduction'>
                  <h2>Introduction</h2>
                  <p>
                    Biochemistry, the study of chemical processes within and relating to living organisms, serves as a cornerstone of medical understanding, unraveling the molecular intricacies that underpin physiological functions and pathological conditions. From the metabolic pathways fueling cellular energy production to the molecular signaling cascades regulating gene expression, biochemistry illuminates the biochemical landscape shaping our health and disease.
                  </p>
                </section>

                <section>
                  <h2>Key Areas of Study</h2>
                  <ul>
                    <li>Macromolecules and Biomolecules</li>
                    <li>Metabolic Pathways</li>
                    <li>Molecular Genetics</li>
                    <li>Cellular Signaling</li>
                    <li>Enzymology</li>
                    <li>Medical Biochemistry</li>
                    <li>Applied Biochemistry</li>
                  </ul>
                </section>

                <section id='macromolecules'>
                  <h1>Macromolecules and Biomolecules:</h1>
                  <p>Biochemistry begins by examining the structure, function, and interactions of macromolecules and biomolecules essential for life processes. These include proteins, carbohydrates, lipids, nucleic acids, and other molecules involved in cellular structure, energy metabolism, and information transfer.</p>
                </section>

                <section>
                  <h2>Key Concepts:</h2>
                  <ul>
                    <li><strong>Proteins:</strong> Biochemistry introduces us to the diverse roles and functions of proteins, which serve as enzymes, structural components, receptors, transporters, and signaling molecules within cells and organisms.</li>
                    <li><strong>Carbohydrates:</strong> By examining the structure and metabolism of carbohydrates, biochemistry elucidates their roles as energy sources, structural elements, and cell-cell recognition molecules in physiological and pathological contexts.</li>
                    <li><strong>Lipids:</strong> Understanding lipid structure, function, and metabolism is crucial for unraveling their roles in membrane structure, energy storage, signaling, and the pathogenesis of lipid-related disorders.</li>
                    <li><strong>Nucleic Acids:</strong> Biochemistry delves into the structure and function of nucleic acids, elucidating their roles in genetic information storage, gene expression, and molecular regulation.</li>
                  </ul>
                </section>

                <section>
                  <h3>Proteins:</h3>
                  <ul>
                    <li><strong>Structural Diversity:</strong> Proteins exhibit remarkable structural diversity, allowing them to perform a wide range of biological functions essential for cellular and organismal viability.</li>
                    <li><strong>Functional Versatility:</strong> Proteins serve as enzymes catalyzing biochemical reactions, receptors mediating cellular signaling, antibodies defending against pathogens, and structural components maintaining cellular integrity.</li>
                    <li><strong>Dynamic Interactions:</strong> Protein-protein interactions, ligand binding, and conformational changes underlie the dynamic behavior of proteins, regulating their activity, localization, and function within cellular environments.</li>
                  </ul>
                </section>

                <section>
                  <h3>Carbohydrates:</h3>
                  <ul>
                    <li><strong>Energy Metabolism:</strong> Carbohydrates serve as primary energy sources for cellular metabolism, undergoing glycolysis, citric acid cycle, and oxidative phosphorylation to produce ATP, the universal energy currency of cells.</li>
                    <li><strong>Structural Functions:</strong> Carbohydrates contribute to the structural integrity of cells and tissues through glycoproteins, glycolipids, and extracellular matrix components, participating in cell-cell recognition, adhesion, and signaling processes.</li>
                    <li><strong>Cellular Communication:</strong> Carbohydrates play crucial roles in cell-cell communication and immune recognition, with glycan structures mediating interactions between cells, pathogens, and the immune system.</li>
                  </ul>
                </section>

                <section>
                  <h3>Lipids:</h3>
                  <ul>
                    <li><strong>Membrane Structure:</strong> Lipids are fundamental components of biological membranes, forming lipid bilayers that define cell boundaries and compartmentalize cellular organelles, enabling selective permeability and cellular communication.</li>
                    <li><strong>Energy Storage:</strong> Lipids serve as efficient energy storage molecules, with triglycerides storing energy in adipose tissue for metabolic utilization during fasting or prolonged exertion.</li>
                    <li><strong>Signaling Molecules:</strong> Lipids function as signaling molecules and second messengers in cellular signaling pathways, regulating processes such as inflammation, apoptosis, and cell proliferation.</li>
                  </ul>
                </section>

                <section id='metabolic-pathways'>
                  <h1>Metabolic Pathways:</h1>
                  <p>Biochemistry explores the interconnected networks of metabolic pathways that regulate the flow of energy and molecular building blocks within cells and organisms. These pathways, including glycolysis, the citric acid cycle, and oxidative phosphorylation, orchestrate the conversion of nutrients into ATP and the synthesis of biomolecules essential for cellular function and survival.</p>
                </section>

                <section>
                  <h2>Key Concepts:</h2>
                  <ul>
                    <li>Glycolysis and Gluconeogenesis</li>
                    <li>Citric Acid Cycle</li>
                    <li>Oxidative Phosphorylation</li>
                    <li>Fatty Acid Metabolism</li>
                    <li>Amino Acid Metabolism</li>
                    <li>Regulation of Metabolic Pathways</li>
                    <li>Integration of Metabolism</li>
                  </ul>
                </section>

                <section>
                  <h3>Glycolysis and Gluconeogenesis:</h3>
                  <p>Glycolysis is a central metabolic pathway that converts glucose into pyruvate, generating ATP and NADH in the process. Gluconeogenesis, on the other hand, synthesizes glucose from non-carbohydrate precursors such as lactate, glycerol, and amino acids, ensuring glucose availability during fasting or energy-demanding states.</p>
                </section>

                <section>
                  <h3>Citric Acid Cycle:</h3>
                  <p>The citric acid cycle (Krebs cycle) is a key metabolic pathway that oxidizes acetyl-CoA derived from carbohydrates, fats, and amino acids, producing reducing equivalents (NADH and FADH2) and substrates for oxidative phosphorylation. It also generates ATP and intermediates for biosynthetic pathways.</p>
                </section>

                <section>
                  <h3>Oxidative Phosphorylation:</h3>
                  <p>Oxidative phosphorylation is the process by which ATP is synthesized from ADP and inorganic phosphate (Pi) using the energy derived from electron transport chain (ETC) reactions coupled to proton translocation across the inner mitochondrial membrane. This process generates the majority of ATP in aerobic respiration.</p>
                </section>

                <section>
                  <h3>Fatty Acid Metabolism:</h3>
                  <p>Fatty acid metabolism involves the synthesis (lipogenesis) and breakdown (lipolysis) of fatty acids, which serve as energy substrates, membrane components, and signaling molecules. Lipogenesis converts excess carbohydrates into fatty acids for storage as triglycerides, while lipolysis releases fatty acids for energy production via β-oxidation.</p>
                </section>

                <section>
                  <h3>Amino Acid Metabolism:</h3>
                  <p>Amino acid metabolism encompasses the synthesis and degradation of amino acids, which are essential for protein synthesis, neurotransmitter production, and nitrogen balance. Transamination and deamination reactions regulate amino acid levels, while the carbon skeletons of amino acids can enter metabolic pathways for energy production or biosynthesis.</p>
                </section>

                <section>
                  <h3>Regulation of Metabolic Pathways:</h3>
                  <p>Metabolic pathways are tightly regulated by allosteric enzymes, hormonal signals, and substrate availability to maintain energy homeostasis and respond to physiological demands. Regulatory mechanisms include feedback inhibition, covalent modification, and gene expression control, ensuring metabolic fluxes are appropriately adjusted in response to changing cellular conditions.</p>
                </section>

                <section>
                  <h3>Integration of Metabolism:</h3>
                  <p>Metabolism is integrated across different tissues and organs to balance energy production, storage, and utilization according to nutritional status and metabolic demands. Hormonal signals such as insulin and glucagon coordinate metabolic responses, while interorgan communication ensures metabolic intermediates are distributed efficiently throughout the body.</p>
                </section>

                <section id='molecular-genetics'>
                  <h1>Molecular Genetics:</h1>
                  <p>Biochemistry delves into the molecular mechanisms governing gene expression, replication, and regulation, unraveling the genetic code that dictates cellular identity, function, and fate. From the structure of DNA and RNA to the intricacies of transcription, translation, and epigenetic modifications, molecular genetics elucidates the molecular underpinnings of inheritance and disease.</p>
                </section>

                <section>
                  <h2>Key Concepts:</h2>
                  <ul>
                    <li>DNA Structure and Replication</li>
                    <li>RNA Transcription and Processing</li>
                    <li>Genetic Code and Translation</li>
                    <li>Regulation of Gene Expression</li>
                    <li>Epigenetics and Chromatin Remodeling</li>
                    <li>Genetic Variation and Mutation</li>
                    <li>Genomics and Systems Biology</li>
                  </ul>
                </section>

                <section>
                  <h3>DNA Structure and Replication:</h3>
                  <p>DNA (deoxyribonucleic acid) is the hereditary material that carries genetic information in cells. Its double helical structure composed of nucleotide bases (adenine, thymine, cytosine, and guanine) facilitates accurate replication by DNA polymerases, ensuring faithful transmission of genetic information during cell division.</p>
                </section>

                <section>
                  <h3>RNA Transcription and Processing:</h3>
                  <p>RNA (ribonucleic acid) is synthesized from DNA templates by RNA polymerases through a process called transcription. Pre-mRNA undergoes post-transcriptional modifications such as capping, splicing, and polyadenylation to produce mature mRNA transcripts that are translated into proteins by ribosomes.</p>
                </section>

                <section>
                  <h3>Genetic Code and Translation:</h3>
                  <p>The genetic code is the set of rules by which nucleotide triplets (codons) specify amino acids during protein synthesis. Transfer RNA (tRNA) molecules recognize codons through complementary base pairing and deliver the corresponding amino acids to ribosomes, where they are assembled into polypeptide chains through translation.</p>
                </section>

                <section>
                  <h3>Regulation of Gene Expression:</h3>
                  <p>Gene expression is regulated at multiple levels, including transcriptional, post-transcriptional, translational, and epigenetic mechanisms. Transcription factors, RNA-binding proteins, microRNAs, and chromatin-modifying enzymes modulate gene activity, allowing cells to fine-tune their gene expression profiles in response to internal and external cues.</p>
                </section>

                <section>
                  <h3>Epigenetics and Chromatin Remodeling:</h3>
                  <p>Epigenetic modifications such as DNA methylation, histone acetylation, and chromatin remodeling influence gene expression patterns without altering the underlying DNA sequence. These reversible modifications regulate chromatin structure and accessibility, playing crucial roles in development, differentiation, and disease.</p>
                </section>

                <section>
                  <h3>Genetic Variation and Mutation:</h3>
                  <p>Genetic variation arises from mutations, which are heritable changes in DNA sequence resulting from errors in replication, DNA damage, or environmental exposures. Mutations can lead to genetic disorders, cancer, and evolutionary diversity, highlighting the importance of DNA repair mechanisms and genome stability maintenance.</p>
                </section>

                <section>
                  <h3>Genomics and Systems Biology:</h3>
                  <p>Genomics explores the organization, function, and evolution of genomes, encompassing the sequencing, annotation, and comparative analysis of entire genomes. Systems biology integrates genomic, transcriptomic, proteomic, and metabolomic data to model complex biological systems and elucidate emergent properties underlying cellular behavior and disease.</p>
                </section>

                <section id='cellular-signaling'>
                  <h1>Cellular Signaling:</h1>
                  <p>Biochemistry investigates the intricate networks of cellular signaling pathways that govern physiological responses to extracellular stimuli, coordinating cellular processes such as growth, differentiation, metabolism, and apoptosis. From receptor-ligand interactions to intracellular signal transduction cascades, cellular signaling elucidates the molecular mechanisms regulating cellular behavior and homeostasis.</p>
                </section>

                <section>
                  <h2>Key Concepts:</h2>
                  <ul>
                    <li>Receptor-Ligand Interactions</li>
                    <li>Intracellular Signaling Cascades</li>
                    <li>Second Messengers and Signal Amplification</li>
                    <li>Cellular Response and Adaptation</li>
                    <li>Signal Termination and Feedback Regulation</li>
                    <li>Signal Integration and Crosstalk</li>
                    <li>Dysregulation and Disease Pathogenesis</li>
                  </ul>
                </section>

                <section>
                  <h3>Receptor-Ligand Interactions:</h3>
                  <p>Cellular signaling begins with the binding of extracellular ligands to cell surface receptors, triggering conformational changes that propagate intracellular signals. Receptors can be categorized as membrane-bound receptors, ion channels, or intracellular receptors, depending on their localization and signaling mechanisms.</p>
                </section>

                <section>
                  <h3>Intracellular Signaling Cascades:</h3>
                  <p>Upon ligand binding, activated receptors initiate intracellular signaling cascades through the activation of downstream effector molecules such as protein kinases, G proteins, and second messengers. These cascades transmit and amplify signals to regulate cellular processes such as gene expression, cytoskeletal rearrangements, and enzyme activity.</p>
                </section>

                <section>
                  <h3>Second Messengers and Signal Amplification:</h3>
                  <p>Second messengers such as cyclic AMP (cAMP), calcium ions (Ca2+), and inositol trisphosphate (IP3) mediate signal transduction by relaying extracellular signals from receptors to intracellular effectors. Signal amplification occurs through enzymatic cascades and positive feedback loops, ensuring robust and graded cellular responses to stimuli.</p>
                </section>

                <section>
                  <h3>Cellular Response and Adaptation:</h3>
                  <p>Cellular responses to signaling stimuli include changes in gene expression, protein synthesis, enzyme activity, and cytoskeletal dynamics. Cells can adapt to persistent or repetitive signals through mechanisms such as receptor desensitization, downregulation, or feedback inhibition, maintaining homeostasis and preventing aberrant responses.</p>
                </section>

                <section>
                  <h3>Signal Termination and Feedback Regulation:</h3>
                  <p>Signal termination is crucial for preventing prolonged or excessive signaling, which can lead to cellular dysfunction or pathological conditions. Negative feedback mechanisms, phosphatases, and endocytic processes help terminate signaling pathways by dephosphorylating receptors, removing signaling complexes from the membrane, or targeting receptors for degradation.</p>
                </section>

                <section>
                  <h3>Signal Integration and Crosstalk:</h3>
                  <p>Cells integrate multiple signaling inputs to generate coordinated responses that govern complex physiological processes. Signal crosstalk occurs when signaling pathways intersect or converge at common nodes, allowing for synergistic, antagonistic, or integrated responses to diverse stimuli.</p>
                </section>

                <section>
                  <h3>Dysregulation and Disease Pathogenesis:</h3>
                  <p>Aberrant cellular signaling underlies various diseases, including cancer, metabolic disorders, and neurological conditions. Dysregulation of signaling pathways can result from genetic mutations, aberrant receptor activation, or alterations in downstream effectors, highlighting the importance of targeting signaling pathways for therapeutic interventions.</p>
                </section>
                <section id='medical-biochemistry'>
                  <h1>Medical Biochemistry:</h1>
                  <p>Medical biochemistry is the application of biochemical principles to the understanding of human health and disease. It encompasses the study of molecular mechanisms underlying physiological processes and the biochemical basis of pathological conditions, as well as the development of diagnostic tools and therapeutic strategies.</p>
                </section>

                <section>
                  <h2>Key Concepts:</h2>
                  <ul>
                    <li>Molecular Basis of Disease</li>
                    <li>Biochemical Diagnostics</li>
                    <li>Therapeutic Targets and Drug Development</li>
                    <li>Nutritional Biochemistry</li>
                    <li>Pharmacogenomics and Personalized Medicine</li>
                  </ul>
                </section>

                <section>
                  <h3>Molecular Basis of Disease:</h3>
                  <p>Understanding the molecular basis of disease involves elucidating the biochemical pathways and mechanisms that contribute to the development and progression of various disorders, from genetic diseases to cancer and neurodegenerative conditions.</p>
                </section>

                <section>
                  <h3>Biochemical Diagnostics:</h3>
                  <p>Biochemical diagnostics are laboratory tests that measure the levels or activities of specific molecules in biological samples, providing valuable information for the diagnosis, prognosis, and monitoring of diseases. Examples of biochemical diagnostics include enzyme assays, immunoassays, and genetic testing.</p>
                </section>

                <section>
                  <h3>Therapeutic Targets and Drug Development:</h3>
                  <p>Identifying and validating therapeutic targets is a crucial step in drug development. Biochemical approaches, such as enzyme inhibition or modulation of signaling pathways, are often employed to develop targeted therapies for various diseases.</p>
                </section>

                <section>
                  <h3>Nutritional Biochemistry:</h3>
                  <p>Nutritional biochemistry explores the relationships between diet, nutrient metabolism, and human health. It encompasses the study of essential nutrients, their metabolic pathways, and their roles in maintaining health and preventing disease.</p>
                </section>

                <section>
                  <h3>Pharmacogenomics and Personalized Medicine:</h3>
                  <p>Pharmacogenomics is the study of how genetic variation affects individual responses to drugs, aiming to optimize drug therapy based on a patient's genetic profile. Personalized medicine leverages pharmacogenomics and other biomarkers to tailor medical treatments to individual patients, improving efficacy and reducing adverse effects.</p>
                </section>

              </div>
            </Col>
          </Row>
        </Container>
        <SidebarResource currentPage="biochemistry" />
      </div>
      <footer id='footer'>
        {/* Footer */}
        <Footer />
      </footer>
    </div>
  );
}

export default biochemistry;
