import React from 'react'
import Nav from 'react-bootstrap/Nav';

const CategoryTitle = () => {
  return (
    <div className='divMarginBreak pop-in'>
            <Nav className="justify-content-center">
              <h1>
                Resource Categories
              </h1>
            </Nav>
          </div>
  )
}

export default CategoryTitle