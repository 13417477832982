import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import Container from 'react-bootstrap/Container';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import '../css/master.css';

export const Contact = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [pop, setPop] = useState(false);
  const [showModal, setShowModal] = useState(false); // State for controlling modal visibility
  const [submissionStatus, setSubmissionStatus] = useState('');

  useEffect(() => {
    setPop(true);
  }, []);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_5z26bel', 'template_hjitjxm', e.target, '42fq6WAyhrYVHHLrB')
      .then((result) => {
        console.log(result.text);
        setSubmissionStatus('success');
        setShowModal(true); // Show modal on successful submission
      }, (error) => {
        console.log(error.text);
        setSubmissionStatus('error');
        setShowModal(true); // Show modal on error
      });
  };

  return (
    <div className={darkMode ? 'dark-mode' : 'light-mode'}>
      <NavBar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      <div className={`d-flex justify-content-center divMarginBreak ${pop ? 'pop' : ''}`}>
        <Card className="align-items-center text-center" style={{ width: '90vh', backgroundColor: darkMode ? '#343a40' : '' }}>
          <Container>
            <div className='divMarginBreak'>
              <Nav className={`mb-3 justify-content-center ${darkMode ? 'text-white' : ''}`}>
                <h1 className="mb-4">Contact Us</h1>
                <h5>Feel free to message us about any questions you may have, bugs that need investigating, or features you will like us to add.</h5>
              </Nav>
            </div>
            <Row>
              <Col>
                <Form onSubmit={sendEmail}>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3 divMargin" controlId="exampleForm.ControlInput1">
                        <Form.Label style={{ color: darkMode ? '#fff' : '' }}><strong>First Name</strong></Form.Label>
                        <Form.Control name="firstName" required placeholder="First Name" style={{ backgroundColor: darkMode ? '#454d55' : '' }} />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3 divMargin" controlId="exampleForm.ControlInput1">
                        <Form.Label style={{ color: darkMode ? '#fff' : '' }}><strong>Last Name</strong></Form.Label>
                        <Form.Control name="lastName" placeholder="Last Name" style={{ backgroundColor: darkMode ? '#454d55' : '' }} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3 divMargin" controlId="exampleForm.ControlInput1">
                    <Form.Label style={{ color: darkMode ? '#fff' : '' }}><strong>Email Address</strong></Form.Label>
                    <Form.Control name="email" type="email" required placeholder="name@example.com"  style={{ backgroundColor: darkMode ? '#454d55' : '' }} />
                  </Form.Group>
                  <Form.Group className="mb-3 divMargin" controlId="exampleForm.ControlTextarea1">
                    <Form.Label style={{ color: darkMode ? '#fff' : '' }}><strong>Message</strong></Form.Label>
                    <Form.Control name="message" as="textarea" required rows={3} style={{ backgroundColor: darkMode ? '#454d55' : '' }} />
                  </Form.Group>
                  <Form.Group className="mb-5 divMargin" controlId="exampleForm.ControlTextarea1">
                    <Form.Label style={{ color: darkMode ? '#fff' : '' }}><strong>Additional Considerations</strong></Form.Label>
                    <Form.Control name="additionalConsiderations" as="textarea" rows={2} style={{ backgroundColor: darkMode ? '#454d55' : '' }} />
                  </Form.Group>
                  <Button className="mb-5" variant="primary" type="submit" size="lg"><strong>Submit Message</strong></Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </Card>
      </div>
      <footer style={{marginTop:'10vh'}}>
        <Footer />
      </footer>
      {/* Modal for confirmation message */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Email Submission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {submissionStatus === 'success' ? (
            <p>Email sent successfully!</p>
          ) : (
            <p>Error sending email. Please try again later.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Contact;
