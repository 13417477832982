import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import { Table, Container, Row, Col, Modal, Button } from 'react-bootstrap';


const QuestionDatabase = () => {
  const faqData = [
   // Anatomy
{ id: 1, category: "Anatomy", question: "What is the largest organ in the human body?", answer: "The skin." },
{ id: 2, category: "Anatomy", question: "Where is the femur located?", answer: "The thigh bone." },
{ id: 3, category: "Anatomy", question: "What are the three layers of the skin?", answer: "The epidermis, dermis, and hypodermis." },
{ id: 4, category: "Anatomy", question: "What are the main functions of the skeletal system?", answer: "Support, protection, movement, mineral storage, and blood cell formation." },
{ id: 5, category: "Anatomy", question: "Where is the liver located in the body?", answer: "In the upper right part of the abdomen, beneath the diaphragm and above the stomach." },
{ id: 6, category: "Anatomy", question: "What is the function of the kidneys?", answer: "To filter waste and excess fluids from the blood and regulate electrolyte balance." },
{ id: 7, category: "Anatomy", question: "What is the function of the pancreas?", answer: "To produce hormones such as insulin and glucagon, and to release digestive enzymes into the small intestine." },
{ id: 8, category: "Anatomy", question: "What is the function of the spleen?", answer: "To filter blood and remove damaged or old red blood cells, and to store and produce white blood cells." },
{ id: 9, category: "Anatomy", question: "What is the function of the thyroid gland?", answer: "To produce hormones that regulate metabolism, growth, and development." },
{ id: 10, category: "Anatomy", question: "What is the function of the adrenal glands?", answer: "To produce hormones such as cortisol, aldosterone, and adrenaline that regulate stress response, blood pressure, and metabolism." },

// Histology
{ id: 11, category: "Histology", question: "What is the study of tissues called?", answer: "Histology." },
{ id: 12, category: "Histology", question: "Name the four basic types of tissues.", answer: "Epithelial, connective, muscle, and nervous tissue." },
{ id: 13, category: "Histology", question: "What is the extracellular matrix?", answer: "A complex network of proteins and carbohydrates that provides structural support to tissues." },
{ id: 14, category: "Histology", question: "What is the function of adipose tissue?", answer: "To store energy in the form of fat and provide insulation and cushioning." },
{ id: 15, category: "Histology", question: "What is the main function of epithelial tissue?", answer: "To provide protection, secretion, absorption, and sensation." },
{ id: 16, category: "Histology", question: "What is the main function of connective tissue?", answer: "To provide support, protection, and connection between other tissues." },
{ id: 17, category: "Histology", question: "What is the main function of muscle tissue?", answer: "To generate force and cause movement." },
{ id: 18, category: "Histology", question: "What is the main function of nervous tissue?", answer: "To transmit electrical signals and coordinate body functions." },
{ id: 19, category: "Histology", question: "What is the difference between simple and stratified epithelium?", answer: "Simple epithelium consists of a single layer of cells, while stratified epithelium consists of multiple layers of cells." },
{ id: 20, category: "Histology", question: "What is the difference between striated and smooth muscle tissue?", answer: "Striated muscle tissue has a striped appearance and is under voluntary control, while smooth muscle tissue has a smooth appearance and is under involuntary control." },

// Biochemistry
{ id: 21, category: "Biochemistry", question: "What is the role of enzymes in biochemical reactions?", answer: "Enzymes catalyze biochemical reactions." },
{ id: 22, category: "Biochemistry", question: "What is ATP and its function?", answer: "ATP is the energy currency of cells." },
{ id: 23, category: "Biochemistry", question: "What is glycolysis?", answer: "The metabolic pathway that converts glucose into pyruvate, producing ATP and NADH." },
{ id: 24, category: "Biochemistry", question: "What is the role of cholesterol in the body?", answer: "Cholesterol is a structural component of cell membranes and a precursor for steroid hormones and bile acids." },
{ id: 25, category: "Biochemistry", question: "What are enzymes and how do they work?", answer: "Enzymes are biological catalysts that speed up chemical reactions by lowering the activation energy required for the reaction to occur." },
{ id: 26, category: "Biochemistry", question: "What is the difference between DNA and RNA?", answer: "DNA contains deoxyribose sugar and thymine, while RNA contains ribose sugar and uracil." },
{ id: 27, category: "Biochemistry", question: "What is the role of proteins in the body?", answer: "Proteins have various functions in the body, including structural support, enzymatic activity, transport, and signaling." },
{ id: 28, category: "Biochemistry", question: "What is the difference between prokaryotic and eukaryotic cells?", answer: "Prokaryotic cells lack a nucleus and other membrane-bound organelles, while eukaryotic cells have a nucleus and other membrane-bound organelles." },
{ id: 29, category: "Biochemistry", question: "What is the difference between aerobic and anaerobic respiration?", answer: "Aerobic respiration requires oxygen and produces more ATP, while anaerobic respiration does not require oxygen and produces less ATP." },
{ id: 30, category: "Biochemistry", question: "What is the difference between a gene and an allele?", answer: "A gene is a segment of DNA that codes for a specific trait, while an allele is a specific version of a gene." },

// Pharmacology
{ id: 31, category: "Pharmacology", question: "What are common side effects of NSAIDs?", answer: "Gastrointestinal irritation and kidney damage." },
{ id: 32, category: "Pharmacology", question: "What is the mechanism of action of beta-blockers?", answer: "Beta-blockers block beta-adrenergic receptors, reducing heart rate and blood pressure." },
{ id: 33, category: "Pharmacology", question: "What are the different classes of antibiotics?", answer: "Classes of antibiotics include penicillins, cephalosporins, macrolides, fluoroquinolones, and tetracyclines." },
{ id: 34, category: "Pharmacology", question: "What are the side effects of opioid analgesics?", answer: "Side effects of opioids include sedation, respiratory depression, constipation, and dependence." },
{ id: 35, category: "Pharmacology", question: "How do beta-blockers work in the treatment of hypertension?", answer: "Beta-blockers decrease heart rate and blood pressure by blocking beta-adrenergic receptors." },
{ id: 36, category: "Pharmacology", question: "What is the difference between agonists and antagonists?", answer: "Agonists bind to a receptor and activate it, while antagonists bind to a receptor and block its activation." },
{ id: 37, category: "Pharmacology", question: "What is the difference between pharmacokinetics and pharmacodynamics?", answer: "Pharmacokinetics is the study of how drugs move through the body, while pharmacodynamics is the study of how drugs affect the body." },
{ id: 38, category: "Pharmacology", question: "What is the difference between a drug's half-life and its duration of action?", answer: "A drug's half-life is the time it takes for the drug's concentration in the body to decrease by half, while its duration of action is the length of time the drug produces a therapeutic effect." },
{ id: 39, category: "Pharmacology", question: "What is the difference between a narrow therapeutic index drug and a wide therapeutic index drug?", answer: "A narrow therapeutic index drug has a small range between its therapeutic and toxic doses, while a wide therapeutic index drug has a large range between its therapeutic and toxic doses." },
{ id: 40, category: "Pharmacology", question: "What is the difference between a prodrug and an active drug?", answer: "A prodrug is an inactive form of a drug that is converted to its active form in the body, while an active drug is already in its active form." },

// Microbiology
{ id: 41, category: "Microbiology", question: "What are Gram-positive bacteria?", answer: "Bacteria that retain the crystal violet stain in Gram staining." },
{ id: 42, category: "Microbiology", question: "What is the mode of transmission of HIV?", answer: "HIV is transmitted through bodily fluids such as blood, semen, and vaginal secretions." },
{ id: 43, category: "Microbiology", question: "What are the main types of microorganisms?", answer: "Bacteria, viruses, fungi, protozoa, and algae." },
{ id: 44, category: "Microbiology", question: "What is antibiotic resistance?", answer: "The ability of bacteria to resist the effects of antibiotics, making infections harder to treat." },
{ id: 45, category: "Microbiology", question: "How do viruses replicate?", answer: "Viruses replicate by hijacking host cells and using their cellular machinery to produce new viral particles." },
{ id: 46, category: "Microbiology", question: "What is the difference between a bacterium and a virus?", answer: "Bacteria are single-celled organisms that can survive on their own, while viruses are not living organisms and require a host cell to replicate." },
{ id: 47, category: "Microbiology", question: "What is the difference between a fungus and a mold?", answer: "Fungi are a diverse group of eukaryotic organisms that include yeasts, molds, and mushrooms, while molds are a type of fungi that grow as multicellular filaments called hyphae." },
{ id: 48, category: "Microbiology", question: "What is the difference between a protozoan and an alga?", answer: "Protozoa are single-celled eukaryotic organisms that can move independently, while algae are photosynthetic eukaryotic organisms that can range from single-celled to multicellular." },
{ id: 49, category: "Microbiology", question: "What is the difference between anaerobic and aerobic bacteria?", answer: "Anaerobic bacteria do not require oxygen to grow, while aerobic bacteria require oxygen to grow." },
{ id: 50, category: "Microbiology", question: "What is the difference between a vaccine and an antibiotic?", answer: "Vaccines are substances that stimulate the immune system to produce an immune response against a specific pathogen, while antibiotics are drugs that kill or inhibit the growth of bacteria." },

// Immunology
{ id: 51, category: "Immunology", question: "What are antibodies?", answer: "Proteins produced by the immune system to neutralize pathogens." },
{ id: 52, category: "Immunology", question: "What is the function of T cells?", answer: "T cells help regulate immune responses and directly kill infected cells." },
{ id: 53, category: "Immunology", question: "What is inflammation?", answer: "The body's response to infection or injury, characterized by redness, swelling, heat, and pain." },
{ id: 54, category: "Immunology", question: "What is innate immunity?", answer: "The body's first line of defense against pathogens, including physical barriers and non-specific immune responses." },
{ id: 55, category: "Immunology", question: "What is adaptive immunity?", answer: "The immune response mediated by T and B lymphocytes, providing specific and long-lasting protection against pathogens." },
{ id: 56, category: "Immunology", question: "What is the difference between active and passive immunity?", answer: "Active immunity is acquired through the body's own immune response to a pathogen, while passive immunity is acquired through the transfer of antibodies from another source." },
{ id: 57, category: "Immunology", question: "What is the difference between cell-mediated and humoral immunity?", answer: "Cell-mediated immunity is mediated by T cells and involves the direct killing of infected cells, while humoral immunity is mediated by B cells and involves the production of antibodies." },
{ id: 58, category: "Immunology", question: "What is the difference between an allergy and an autoimmune disorder?", answer: "An allergy is an overreaction of the immune system to a harmless substance, while an autoimmune disorder is a condition in which the immune system attacks the body's own cells and tissues." },
{ id: 59, category: "Immunology", question: "What is the difference between a cytokine and a chemokine?", answer: "Cytokines are signaling molecules that regulate immune responses, while chemokines are a specific type of cytokine that attract immune cells to the site of infection or inflammation." },
{ id: 60, category: "Immunology", question: "What is the difference between a primary and a secondary immune response?", answer: "A primary immune response occurs when the body first encounters a pathogen, while a secondary immune response occurs when the body encounters the same pathogen again and mounts a faster and stronger response." },

// Genetics
{ id: 61, category: "Genetics", question: "What is a gene?", answer: "A segment of DNA that codes for a specific trait." },
{ id: 62, category: "Genetics", question: "What is a mutation?", answer: "A change in the DNA sequence that can lead to altered gene function." },
{ id: 63, category: "Genetics", question: "What is DNA replication?", answer: "The process by which DNA makes a copy of itself during cell division." },
{ id: 64, category: "Genetics", question: "What is genetic inheritance?", answer: "The passing of traits from parents to offspring through genes." },
{ id: 65, category: "Genetics", question: "What are genetic disorders?", answer: "Disorders caused by mutations or abnormalities in an individual's DNA sequence." },
{ id: 66, category: "Genetics", question: "What is the difference between a dominant and a recessive allele?", answer: "A dominant allele is expressed when present in a single copy, while a recessive allele is only expressed when present in two copies." },
{ id: 67, category: "Genetics", question: "What is the difference between a genotype and a phenotype?", answer: "A genotype is an individual's genetic makeup, while a phenotype is the observable physical or biochemical characteristics of an individual." },
{ id: 68, category: "Genetics", question: "What is the difference between a chromosome and a gene?", answer: "A chromosome is a structure that contains DNA and proteins, while a gene is a segment of DNA that codes for a specific trait." },
{ id: 69, category: "Genetics", question: "What is the difference between a karyotype and a genome?", answer: "A karyotype is a visual representation of an individual's chromosomes, while a genome is the complete set of an individual's genetic material." },
{ id: 70, category: "Genetics", question: "What is the difference between mitosis and meiosis?", answer: "Mitosis is a type of cell division that produces two identical daughter cells, while meiosis is a type of cell division that produces four genetically unique daughter cells." },

// Embryology
{ id: 71, category: "Embryology", question: "What is gastrulation?", answer: "The process by which the three germ layers (ectoderm, mesoderm, and endoderm) form during embryonic development." },
{ id: 72, category: "Embryology", question: "What is the notochord?", answer: "A rod-like structure that forms along the dorsal axis of the embryo, playing a key role in early development." },
{ id: 73, category: "Embryology", question: "What are stem cells?", answer: "Cells with the ability to differentiate into various cell types, important for development and tissue repair." },
{ id: 74, category: "Embryology", question: "What is organogenesis?", answer: "The process of organ formation from the three germ layers during embryonic development." },
{ id: 75, category: "Embryology", question: "What are teratogens?", answer: "Agents that can cause birth defects or developmental abnormalities in embryos." },
{ id: 76, category: "Embryology", question: "What is the difference between a zygote and a blastocyst?", answer: "A zygote is a fertilized egg cell, while a blastocyst is a structure that forms after several rounds of cell division and contains an inner cell mass and a fluid-filled cavity." },
{ id: 77, category: "Embryology", question: "What is the difference between a monozygotic and a dizygotic twin?", answer: "Monozygotic twins develop from a single fertilized egg that splits into two, while dizygotic twins develop from two separate fertilized eggs." },
{ id: 78, category: "Embryology", question: "What is the difference between a placenta and an umbilical cord?", answer: "The placenta is an organ that develops in the uterus during pregnancy and provides oxygen and nutrients to the developing fetus, while the umbilical cord is a structure that connects the fetus to the placenta and contains blood vessels." },
{ id: 79, category: "Embryology", question: "What is the difference between a fetus and a newborn?", answer: "A fetus is a developing human from the end of the eighth week of gestation until birth, while a newborn is a baby that has been born." },
{ id: 80, category: "Embryology", question: "What is the difference between a germ cell and a somatic cell?", answer: "Germ cells are cells that give rise to sperm and eggs, while somatic cells are all other cells in the body." },

// Internal Medicine
{ id: 81, category: "Internal Medicine", question: "What is hypertension?", answer: "High blood pressure, a risk factor for cardiovascular disease." },
{ id: 82, category: "Internal Medicine", question: "What is diabetes mellitus?", answer: "A metabolic disorder characterized by high blood sugar levels." },
{ id: 83, category: "Internal Medicine", question: "What is atherosclerosis?", answer: "A condition in which fatty deposits build up on the walls of arteries, leading to reduced blood flow." },
{ id: 84, category: "Internal Medicine", question: "What is the role of cholesterol in heart disease?", answer: "High levels of cholesterol can lead to the formation of plaques in arteries, increasing the risk of heart attacks and strokes." },
{ id: 85, category: "Internal Medicine", question: "What are common symptoms of gastroesophageal reflux disease (GERD)?", answer: "Heartburn, regurgitation, and difficulty swallowing." },
{ id: 86, category: "Internal Medicine", question: "What is the difference between a heart attack and a stroke?", answer: "A heart attack occurs when blood flow to the heart is blocked, while a stroke occurs when blood flow to the brain is blocked." },
{ id: 87, category: "Internal Medicine", question: "What is the difference between a myocardial infarction and an angina?", answer: "A myocardial infarction is a heart attack caused by blocked blood flow to the heart, while angina is chest pain caused by reduced blood flow to the heart." },
{ id: 88, category: "Internal Medicine", question: "What is the difference between type 1 and type 2 diabetes?", answer: "Type 1 diabetes is an autoimmune disorder in which the body does not produce insulin, while type 2 diabetes is a metabolic disorder in which the body does not properly use insulin." },
{ id: 89, category: "Internal Medicine", question: "What is the difference between a benign and a malignant tumor?", answer: "A benign tumor is a non-cancerous growth that does not invade surrounding tissue, while a malignant tumor is a cancerous growth that can invade surrounding tissue and spread to other parts of the body." },
{ id: 90, category: "Internal Medicine", question: "What is the difference between a bacterial and a viral infection?", answer: "Bacterial infections are caused by bacteria and can be treated with antibiotics, while viral infections are caused by viruses and cannot be treated with antibiotics." },

// Surgery
{ id: 91, category: "Surgery", question: "What is laparoscopic surgery?", answer: "Minimally invasive surgery performed through small incisions using a camera and specialized instruments." },
{ id: 92, category: "Surgery", question: "What is a hernia?", answer: "A protrusion of an organ or tissue through an abnormal opening in the body wall." },
{ id: 93, category: "Surgery", question: "What is a laparotomy?", answer: "A surgical procedure involving a large incision into the abdominal cavity for examination or treatment." },
{ id: 94, category: "Surgery", question: "What is an appendectomy?", answer: "Surgical removal of the appendix, often performed in cases of appendicitis." },
{ id: 95, category: "Surgery", question: "What are the different types of anesthesia?", answer: "General anesthesia, regional anesthesia, and local anesthesia." },
{ id: 96, category: "Surgery", question: "What is the difference between a biopsy and a resection?", answer: "A biopsy is the removal of a small sample of tissue for examination, while a resection is the removal of a larger portion of tissue or an entire organ." },
{ id: 97, category: "Surgery", question: "What is the difference between a laparoscopy and a laparotomy?", answer: "A laparoscopy is a minimally invasive surgical procedure performed through small incisions using a camera and specialized instruments, while a laparotomy is a surgical procedure involving a large incision into the abdominal cavity." },
{ id: 98, category: "Surgery", question: "What is the difference between an open and a closed fracture?", answer: "An open fracture is a break in a bone in which the bone pierces the skin, while a closed fracture is a break in a bone in which the skin remains intact." },
{ id: 99, category: "Surgery", question: "What is the difference between a robotic and a traditional surgery?", answer: "Robotic surgery is a type of minimally invasive surgery in which a surgeon uses a robotic system to perform the procedure, while traditional surgery is performed by a surgeon using handheld instruments." },
{ id: 100, category: "Surgery", question: "What is the difference between a cosmetic and a reconstructive surgery?", answer: "Cosmetic surgery is performed to improve a person's appearance, while reconstructive surgery is performed to restore function or correct a deformity." },

// Pediatrics
{ id: 101, category: "Pediatrics", question: "What is the rationale behind administering the MMR vaccine at specific ages in pediatric care?", answer: "The MMR vaccine is administered at specific ages (typically 12-15 months for the first dose and 4-6 years for the second dose) to optimize the immune response and minimize the risk of infection at a time when children are most vulnerable to these viruses." },
{ id: 102, category: "Pediatrics", question: "How do pediatric growth charts differ between boys and girls, and what implications does this have for assessing health?", answer: "Pediatric growth charts differ for boys and girls to account for natural variations in growth patterns and rates. These differences are crucial for identifying potential health issues early, as deviations from the charts can indicate underlying medical conditions." },
{ id: 103, category: "Pediatrics", question: "Discuss the role of genetic and environmental factors in pediatric asthma.", answer: "Pediatric asthma is influenced by both genetic predisposition and environmental factors such as allergens, tobacco smoke, and air pollution. Understanding these factors is crucial for managing and preventing asthma exacerbations in children." },
{ id: 104, category: "Pediatrics", question: "Identify and describe the management strategies for the three most prevalent infectious diseases in children.", answer: "The three most prevalent infectious diseases in children are otitis media, gastroenteritis, and the common cold. Management strategies include appropriate use of antibiotics for bacterial infections, rehydration therapy for gastroenteritis, and supportive care for the common cold." },
{ id: 105, category: "Pediatrics", question: "Explain the significance of the Denver Developmental Screening Test in pediatric care.", answer: "The Denver Developmental Screening Test is used to assess the developmental progress of children from infancy through preschool years. It helps in identifying children who may have developmental delays, providing a basis for intervention and further assessment." },
{ id: 106, category: "Pediatrics", question: "Differentiate between physiological jaundice and pathological jaundice in newborns.", answer: "Physiological jaundice is a common and usually harmless condition in newborns, peaking at 3-5 days post-birth. Pathological jaundice, however, appears within the first 24 hours or persists, indicating underlying conditions such as infections or liver problems." },
{ id: 107, category: "Pediatrics", question: "Discuss the impact of early nutrition on long-term cognitive and physical development in children.", answer: "Early nutrition, including breastfeeding and appropriate introduction of solid foods, plays a critical role in cognitive and physical development. Adequate nutrition supports neural development, immune function, and overall growth, influencing long-term health outcomes." },
{ id: 108, category: "Pediatrics", question: "Describe the challenges and strategies in managing adolescent mental health.", answer: "Adolescent mental health management involves addressing issues such as depression, anxiety, and substance abuse. Strategies include counseling, medication, and supportive interventions that promote resilience and coping mechanisms." },
{ id: 109, category: "Pediatrics", question: "What is the role of pediatric vaccinations in the eradication of communicable diseases?", answer: "Pediatric vaccinations play a critical role in the eradication of communicable diseases by inducing herd immunity, preventing the spread of infectious agents, and reducing the incidence of vaccine-preventable diseases." },
{ id: 110, category: "Pediatrics", question: "Compare and contrast the clinical presentations and management of croup and bronchiolitis in infants.", answer: "Croup, characterized by a 'barking' cough and stridor, is managed with steroids and nebulized epinephrine. Bronchiolitis, often caused by RSV, presents with wheezing and difficulty breathing, managed with supportive care such as hydration and oxygen therapy." },

// Obstetrics and Gynecology
{ id: 111, category: "Obstetrics and Gynecology", question: "What is menopause?", answer: "The natural cessation of menstruation and fertility in women." },
{ id: 112, category: "Obstetrics and Gynecology", question: "What is cervical cancer?", answer: "Cancer that begins in the cervix, the lower part of the uterus." },
{ id: 113, category: "Obstetrics and Gynecology", question: "What are common prenatal screening tests?", answer: "Ultrasound, blood tests, and genetic screening." },
{ id: 114, category: "Obstetrics and Gynecology", question: "What is endometriosis?", answer: "A painful condition in which tissue similar to the lining of the uterus grows outside the uterus." },
{ id: 115, category: "Obstetrics and Gynecology", question: "What is a Pap smear?", answer: "A screening test for cervical cancer that involves collecting cells from the cervix for examination under a microscope." },
{ id: 116, category: "Obstetrics and Gynecology", question: "What is the difference between a cesarean section and a vaginal birth?", answer: "A cesarean section is a surgical procedure in which the baby is delivered through an incision in the mother's abdomen and uterus, while a vaginal birth is the delivery of the baby through the birth canal." },
{ id: 117, category: "Obstetrics and Gynecology", question: "What is the difference between a normal and a high-risk pregnancy?", answer: "A normal pregnancy is one in which the mother and fetus are healthy and there are no complications, while a high-risk pregnancy is one in which there is an increased risk of complications for the mother or fetus." },
{ id: 118, category: "Obstetrics and Gynecology", question: "What is the difference between a miscarriage and a stillbirth?", answer: "A miscarriage is the loss of a pregnancy before 20 weeks of gestation, while a stillbirth is the loss of a pregnancy after 20 weeks of gestation." },
{ id: 119, category: "Obstetrics and Gynecology", question: "What is the difference between a tubal ligation and a hysterectomy?", answer: "A tubal ligation is a surgical procedure in which the fallopian tubes are blocked or severed to prevent pregnancy, while a hysterectomy is a surgical procedure in which the uterus is removed." },
{ id: 120, category: "Obstetrics and Gynecology", question: "What is the difference between a menstrual period and ovulation?", answer: "A menstrual period is the shedding of the uterine lining that occurs when a fertilized egg is not implanted in the uterus, while ovulation is the release of an egg from the ovary." },

// Psychiatry
{ id: 121, category: "Psychiatry", question: "What is schizophrenia?", answer: "A chronic mental disorder characterized by hallucinations, delusions, and disorganized thinking." },
{ id: 122, category: "Psychiatry", question: "What is anxiety disorder?", answer: "A mental health disorder characterized by excessive worry and fear." },
{ id: 123, category: "Psychiatry", question: "What is bipolar disorder?", answer: "A mood disorder characterized by alternating periods of mania and depression." },
{ id: 124, category: "Psychiatry", question: "What are common symptoms of depression?", answer: "Persistent sadness, loss of interest or pleasure, fatigue, and changes in sleep or appetite." },
{ id: 125, category: "Psychiatry", question: "What is post-traumatic stress disorder (PTSD)?", answer: "A mental health condition triggered by a traumatic event, causing symptoms such as flashbacks, nightmares, and severe anxiety." },
{ id: 126, category: "Psychiatry", question: "What is the difference between a psychologist and a psychiatrist?", answer: "A psychologist is a mental health professional who studies behavior and mental processes and provides therapy, while a psychiatrist is a medical doctor who specializes in the diagnosis and treatment of mental illnesses and can prescribe medication." },
{ id: 127, category: "Psychiatry", question: "What is the difference between a neurosis and a psychosis?", answer: "A neurosis is a mental disorder characterized by anxiety, fear, and obsessive thoughts, while a psychosis is a severe mental disorder characterized by a loss of contact with reality." },
{ id: 128, category: "Psychiatry", question: "What is the difference between a personality disorder and a mood disorder?", answer: "A personality disorder is a type of mental disorder characterized by a rigid and unhealthy pattern of thinking, functioning, and behaving, while a mood disorder is a type of mental disorder characterized by changes in mood that affect a person's ability to function." },
{ id: 129, category: "Psychiatry", question: "What is the difference between a delusion and a hallucination?", answer: "A delusion is a fixed, false belief that persists despite evidence to the contrary, while a hallucination is a sensory perception in the absence of external stimuli." },
{ id: 130, category: "Psychiatry", question: "What is the difference between a phobia and a panic disorder?", answer: "A phobia is an intense fear of a specific object or situation, while a panic disorder is a type of anxiety disorder characterized by recurrent, unexpected panic attacks." },

// Neurology
{ id: 131, category: "Neurology", question: "What is a stroke?", answer: "A sudden interruption of blood flow to the brain, leading to brain damage." },
{ id: 132, category: "Neurology", question: "What is Alzheimer's disease?", answer: "A progressive neurodegenerative disorder that affects memory and cognitive function." },
{ id: 133, category: "Neurology", question: "What is Parkinson's disease?", answer: "A progressive neurodegenerative disorder characterized by tremors, rigidity, and bradykinesia." },
{ id: 134, category: "Neurology", question: "What are the main types of headaches?", answer: "Tension headaches, migraine headaches, and cluster headaches." },
{ id: 135, category: "Neurology", question: "What is multiple sclerosis (MS)?", answer: "A chronic autoimmune disease that affects the central nervous system, causing demyelination and neurological symptoms." },
{ id: 136, category: "Neurology", question: "What is the difference between a seizure and an epilepsy?", answer: "A seizure is a sudden, uncontrolled electrical disturbance in the brain, while epilepsy is a chronic neurological disorder characterized by recurrent, unprovoked seizures." },
{ id: 137, category: "Neurology", question: "What is the difference between a neuron and a glial cell?", answer: "A neuron is a type of cell that transmits electrical signals in the nervous system, while a glial cell is a type of cell that provides support and protection for neurons." },
{ id: 138, category: "Neurology", question: "What is the difference between a motor neuron and a sensory neuron?", answer: "A motor neuron is a type of neuron that carries signals from the brain to the muscles, while a sensory neuron is a type of neuron that carries signals from the senses to the brain." },
{ id: 139, category: "Neurology", question: "What is the difference between a reflex and a voluntary movement?", answer: "A reflex is an automatic response to a stimulus, while a voluntary movement is a consciously controlled movement." },
{ id: 140, category: "Neurology", question: "What is the difference between a neurotransmitter and a hormone?", answer: "A neurotransmitter is a chemical messenger that transmits signals between neurons, while a hormone is a chemical messenger that transmits signals between different parts of the body." },

// Family Medicine
{ id: 141, category: "Family Medicine", question: "What is the role of a family physician?", answer: "To provide primary care for individuals and families, focusing on preventive care and health promotion." },
{ id: 142, category: "Family Medicine", question: "What is hypertension?", answer: "High blood pressure, a common condition managed by family physicians." },
{ id: 143, category: "Family Medicine", question: "What is type 2 diabetes?", answer: "A chronic condition characterized by insulin resistance and high blood sugar levels." },
{ id: 144, category: "Family Medicine", question: "What are common risk factors for heart disease?", answer: "High blood pressure, high cholesterol, smoking, diabetes, and obesity." },
{ id: 145, category: "Family Medicine", question: "What is the recommended age for starting colorectal cancer screening?", answer: "Usually starting at age 50 for average-risk individuals." },
{ id: 146, category: "Family Medicine", question: "What is the difference between a primary care physician and a specialist?", answer: "A primary care physician provides general medical care and coordinates care with specialists, while a specialist is a physician who has advanced training in a specific area of medicine." },
{ id: 147, category: "Family Medicine", question: "What is the difference between a cold and the flu?", answer: "A cold is a mild respiratory illness caused by a virus, while the flu is a more severe respiratory illness caused by the influenza virus." },
{ id: 148, category: "Family Medicine", question: "What is the difference between a sprain and a strain?", answer: "A sprain is an injury to a ligament, while a strain is an injury to a muscle or tendon." },
{ id: 149, category: "Family Medicine", question: "What is the difference between a bacterial and a viral infection?", answer: "Bacterial infections are caused by bacteria and can be treated with antibiotics, while viral infections are caused by viruses and cannot be treated with antibiotics." },
{ id: 150, category: "Family Medicine", question: "What is the difference between an allergy and an intolerance?", answer: "An allergy is an immune response to a substance that is normally harmless, while an intolerance is a difficulty digesting a substance." },

// Radiology
{ id: 151, category: "Radiology", question: "What is a CT scan?", answer: "A diagnostic imaging technique that uses X-rays to create detailed cross-sectional images of the body." },
{ id: 152, category: "Radiology", question: "What is a MRI scan?", answer: "A non-invasive imaging technique that uses strong magnetic fields and radio waves to produce detailed images of the body's internal structures." },
{ id: 153, category: "Radiology", question: "What is contrast-enhanced imaging?", answer: "Imaging techniques that use contrast agents to enhance the visibility of certain structures or abnormalities." },
{ id: 154, category: "Radiology", question: "What are the different types of breast imaging modalities?", answer: "Mammography, ultrasound, and magnetic resonance imaging (MRI)." },
{ id: 155, category: "Radiology", question: "What is the radiation dose associated with a chest X-ray?", answer: "Relatively low, equivalent to a few days' worth of natural background radiation exposure." },
{ id: 156, category: "Radiology", question: "What is the difference between a CT scan and a MRI scan?", answer: "A CT scan uses X-rays to create detailed images of the body, while a MRI scan uses strong magnetic fields and radio waves to produce detailed images of the body's internal structures." },
{ id: 157, category: "Radiology", question: "What is the difference between an X-ray and an ultrasound?", answer: "An X-ray uses radiation to create images of the body, while an ultrasound uses high-frequency sound waves to create images of the body." },
{ id: 158, category: "Radiology", question: "What is the difference between a PET scan and a SPECT scan?", answer: "A PET scan uses a radioactive tracer to create images of the body's metabolic activity, while a SPECT scan uses a radioactive tracer to create images of the body's blood flow and organ function." },
{ id: 159, category: "Radiology", question: "What is the difference between a mammogram and a breast ultrasound?", answer: "A mammogram uses low-dose X-rays to create images of the breast tissue, while a breast ultrasound uses high-frequency sound waves to create images of the breast tissue." },
{ id: 160, category: "Radiology", question: "What is the difference between a bone scan and a bone density scan?", answer: "A bone scan uses a radioactive tracer to create images of the bones to detect abnormalities, while a bone density scan measures the amount of calcium and other minerals in the bones to assess bone strength." },

// Emergency Medicine
{ id: 161, category: "Emergency Medicine", question: "What is cardiac arrest?", answer: "A sudden cessation of heart function, requiring immediate CPR and defibrillation." },
{ id: 162, category: "Emergency Medicine", question: "What is anaphylaxis?", answer: "A severe allergic reaction that can be life-threatening if not treated promptly." },
{ id: 163, category: "Emergency Medicine", question: "What is the initial management of a patient with suspected myocardial infarction?", answer: "Oxygen, aspirin, nitroglycerin, and morphine (if necessary), along with rapid transport to a medical facility." },
{ id: 164, category: "Emergency Medicine", question: "What is the ABCDE approach in emergency medicine?", answer: "Airway, Breathing, Circulation, Disability, Exposure - a systematic approach to assessing and managing critically ill patients." },
{ id: 165, category: "Emergency Medicine", question: "What is the treatment for acute asthma exacerbation?", answer: "Bronchodilators (e.g., albuterol), corticosteroids, and oxygen therapy." },
{ id: 166, category: "Emergency Medicine", question: "What is the difference between a heart attack and cardiac arrest?", answer: "A heart attack occurs when blood flow to the heart is blocked, while cardiac arrest is a sudden cessation of heart function." },
{ id: 167, category: "Emergency Medicine", question: "What is the difference between a stroke and a transient ischemic attack (TIA)?", answer: "A stroke is a sudden interruption of blood flow to the brain, while a TIA is a temporary interruption of blood flow to the brain that resolves within 24 hours." },
{ id: 168, category: "Emergency Medicine", question: "What is the difference between a seizure and a convulsion?", answer: "A seizure is a sudden, uncontrolled electrical disturbance in the brain, while a convulsion is a violent, involuntary contraction of the muscles." },
{ id: 169, category: "Emergency Medicine", question: "What is the difference between a burn and a scald?", answer: "A burn is damage to the skin caused by heat, chemicals, electricity, or radiation, while a scald is a burn caused by hot liquids or steam." },
{ id: 170, category: "Emergency Medicine", question: "What is the difference between a fracture and a dislocation?", answer: "A fracture is a break in a bone, while a dislocation is a separation of the bones at a joint." },

// Anesthesiology
{ id: 171, category: "Anesthesiology", question: "What is general anesthesia?", answer: "A state of reversible unconsciousness and loss of sensation induced by pharmacological agents." },
{ id: 172, category: "Anesthesiology", question: "What is regional anesthesia?", answer: "Anesthesia that blocks nerve transmission to a specific region of the body, providing pain relief during and after surgery." },
{ id: 173, category: "Anesthesiology", question: "What are the common complications of anesthesia?", answer: "Nausea and vomiting, sore throat, headache, and temporary confusion or memory loss." },
{ id: 174, category: "Anesthesiology", question: "What is monitored anesthesia care (MAC)?", answer: "Anesthesia administered by an anesthesiologist or nurse anesthetist while the patient remains conscious, usually for minor procedures." },
{ id: 175, category: "Anesthesiology", question: "What is rapid sequence induction?", answer: "A technique used to quickly induce anesthesia and secure the airway in patients at risk of aspiration, typically in emergency situations." },
{ id: 176, category: "Anesthesiology", question: "What is the difference between a local anesthetic and a general anesthetic?", answer: "A local anesthetic numbs a specific area of the body, while a general anesthetic induces unconsciousness and loss of sensation throughout the body." },
{ id: 177, category: "Anesthesiology", question: "What is the difference between a spinal anesthetic and an epidural anesthetic?", answer: "A spinal anesthetic involves injecting anesthetic directly into the spinal fluid, while an epidural anesthetic involves injecting anesthetic into the space surrounding the spinal cord." },
{ id: 178, category: "Anesthesiology", question: "What is the difference between a nerve block and a regional anesthetic?", answer: "A nerve block involves injecting anesthetic directly into a specific nerve, while a regional anesthetic involves injecting anesthetic into the area surrounding a group of nerves." },
{ id: 179, category: "Anesthesiology", question: "What is the difference between conscious sedation and general anesthesia?", answer: "Conscious sedation involves administering medication to help the patient relax and reduce pain, while general anesthesia involves inducing unconsciousness and loss of sensation throughout the body." },
{ id: 180, category: "Anesthesiology", question: "What is the difference between a preoperative evaluation and a pre-anesthesia evaluation?", answer: "A preoperative evaluation assesses the patient's overall health and readiness for surgery, while a pre-anesthesia evaluation assesses the patient's specific needs and risks related to anesthesia." },
];
  const groupedFAQ = {};
  faqData.forEach(item => {
    if (!groupedFAQ[item.category]) {
      groupedFAQ[item.category] = [];
    }
    groupedFAQ[item.category].push(item);
  });

  // State to manage visibility of answers
  const [visibleAnswers, setVisibleAnswers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentAnswer, setCurrentAnswer] = useState('');

// Function to toggle answer visibility
const toggleAnswerVisibility = (id, answer) => {
  if (window.innerWidth <= 768) {  // Use 768px as the breakpoint for mobile devices
    setCurrentAnswer(answer);
    setShowModal(true);
  } else {
    if (visibleAnswers.includes(id)) {
      setVisibleAnswers(visibleAnswers.filter(item => item !== id));
    } else {
      setVisibleAnswers([...visibleAnswers, id]);
    }
  }
};




return (
  <div>
    <NavBar />
    <Container>
      <div className="p-4 p-md-5 mb-4 rounded text-body-emphasis bg-body-secondary SignInBackgroundQuestionDatabase">
        <Row className="gx-0">
          <Col lg={6} className="px-0">
            <div className="transparent-box">
              <h1 className="display-3">Question Database</h1>
            </div>
          </Col>
        </Row>
      </div>
      {Object.keys(groupedFAQ).map(category => (
        <div key={category} className='divMarginBreak'>
          <h2>{category}</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Question</th>
                <th>Answer</th>
              </tr>
            </thead>
            <tbody>
              {groupedFAQ[category].map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.question}</td>
                  <td onClick={() => toggleAnswerVisibility(item.id, item.answer)}>
                    <span className="text-primary" style={{ cursor: 'pointer' }}>
                      {visibleAnswers.includes(item.id) ? item.answer : 'Click to reveal'}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ))}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Answer</Modal.Title>
        </Modal.Header>
        <Modal.Body>{currentAnswer}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
    <div style={{ textAlign: "center", marginTop: "50px" }}>
    <a href="#" className="top-of-page-link" style={{alignItems: 'center', fontSize: '23px'}}>Back to Top</a> {/* Link to the top of the page */}
    </div>
    <footer style={{marginTop:'5vh'}}>
    <Footer />
    </footer>
  </div>
);
};

export default QuestionDatabase;
