import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';

const RotatingQuotes = () => {
  const [quote, setQuote] = useState('');
  const [loading, setLoading] = useState(true);

  // Function to fetch a motivational quote
  async function fetchMotivationalQuote() {
    try {
      const response = await fetch("https://api.quotable.io/random?tags=motivational");
      if (!response.ok) {
        throw new Error("Failed to fetch quote");
      }
      const data = await response.json();
      return {
        content: data.content,
        author: data.author
      };
    } catch (error) {
      console.error(error);
      return {
        content: "Failed to fetch quote",
        author: ""
      };
    }
  }

  useEffect(() => {
    // Function to fetch a new quote and update state
    const fetchNewQuote = async () => {
      setLoading(true);
      const quoteData = await fetchMotivationalQuote();
      if (quoteData.content !== "Failed to fetch quote") {
        setQuote(`${quoteData.content}\n- ${quoteData.author}`);
      }
      setLoading(false);
    };

    // Fetch a new quote initially
    fetchNewQuote();

    // Fetch a new quote every 5 minutes
    const intervalId = setInterval(fetchNewQuote, 5 * 60 * 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this effect runs only once after initial render


//PASTE THIS ONCE QUESTIONS CAN BE IMPLEMENTED:

/*{/* <Row style={{ flex: '1' }}>
<Col>
  <Card style={{ height: '100%' }}>
    <Card.Header as="h5">Question of the day</Card.Header>
    <Card.Body>
      <Card.Title>Special title treatment</Card.Title>
      <Card.Text>
        With supporting text below as a natural lead-in to additional content.
      </Card.Text>
      <Button variant="primary">Answer Here!</Button>
    </Card.Body>
  </Card>
</Col> }*/




  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', alignItems: 'center', width: '100%', }}>
   <Row style={{ flex: '1' }}>
        <Col>
          <Card style={{ height: '100%', borderWidth:'medium' }}>
            <Card.Body>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <blockquote className="blockquote mb-0 quote-text" style={{ whiteSpace: 'pre-wrap' }}>
                  <p>{quote}</p>
                </blockquote>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default RotatingQuotes;
