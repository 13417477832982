import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NavBar from '../../../components/NavBar';
import NavBarCategories from '../../../components/NavBar-Categories';
import Sidebar from '../../../components/Sidebar';
import Footer from '../../../components/Footer';
import SidebarResource from '../../../components/Sidebar-Resource';

const Genetics = () => {
  return (
    <div>
      <NavBar />
      <div className='sticky-nav-categories'>
        <NavBarCategories />
      </div>
      <div className="pt-3 my-5 d-flex">
        {/* Sidebar */}
        <Sidebar pageTitle="Genetics" />
        {/* Main Content */}
        <Container>
          <Row>
            <Col lg={12}>
              <div>
                <div>
                  <h1 className="display-4 fw-bold text-body-emphasis text-center">Genetics</h1>
                </div>

                <header id="introduction"></header>
                <section>
                  <h2>Introduction</h2>
                  <p>Genetics is the branch of biology that focuses on the study of genes, heredity, and genetic variation in living organisms. It plays a crucial role in understanding the inheritance of traits, genetic disorders, evolutionary processes, and the molecular mechanisms underlying biological functions.</p>
                </section>

                <header id="key-areas-of-study"></header>
                <section>
                  <h2>Key Areas of Study</h2>
                  <ul>
                    <li>Mendelian Genetics</li>
                    <li>Molecular Genetics</li>
                    <li>Population Genetics</li>
                    <li>Genetic Engineering</li>
                    <li>Human Genetics</li>
                    <li>Medical Genetics</li>
                    <li>Evolutionary Genetics</li>
                  </ul>
                </section>

                <header id="mendelian-genetics"></header>
                <section>
                  <h1>Mendelian Genetics</h1>
                  <p>Mendelian genetics, named after Gregor Mendel, explores the principles of inheritance and the transmission of traits from parents to offspring. It provides fundamental insights into the mechanisms of genetic inheritance and the patterns of trait segregation and assortment.</p>
                </section>

                <header id="key-concepts-mendelian-genetics"></header>
                <section>
                  <h2>Key Concepts:</h2>
                  <ul>
                    <li><strong>Mendel's Laws:</strong> Genetics elucidates Mendel's laws of inheritance, including the law of segregation (alleles segregate during gamete formation) and the law of independent assortment (genes assort independently during gamete formation), which govern the inheritance of single-gene traits.</li>
                    <li><strong>Genetic Crosses:</strong> It investigates genetic crosses, including monohybrid crosses (involving one gene) and dihybrid crosses (involving two genes), to predict the phenotypic and genotypic ratios of offspring based on the principles of Mendelian inheritance.</li>
                    <li><strong>Punnett Squares:</strong> Genetics employs Punnett squares, graphical representations of genetic crosses, to visualize allele combinations and predict the outcomes of genetic crosses, including the inheritance of dominant and recessive traits.</li>
                  </ul>
                </section>

                <header id="molecular-genetics"></header>
                <section>
                  <h1>Molecular Genetics</h1>
                  <p>Molecular genetics focuses on the study of DNA, genes, and the molecular mechanisms underlying gene expression, replication, recombination, and regulation. It provides insights into the structure and function of genes at the molecular level.</p>
                </section>

                <header id="key-concepts-molecular-genetics"></header>
                <section>
                  <h2>Key Concepts:</h2>
                  <ul>
                    <li><strong>DNA Structure:</strong> Genetics elucidates the structure of DNA (deoxyribonucleic acid), including its double-helix configuration, nucleotide composition (adenine, thymine, cytosine, guanine), and complementary base pairing (A-T, C-G), which form the basis of genetic information storage and transmission.</li>
                    <li><strong>Gene Expression:</strong> It investigates gene expression processes, including transcription (DNA to RNA) and translation (RNA to protein), as well as the regulation of gene expression by transcription factors, enhancers, repressors, and epigenetic modifications, which control cellular functions and phenotypic traits.</li>
                    <li><strong>Genetic Mutations:</strong> Genetics examines genetic mutations, including point mutations (substitutions), insertions, deletions, and chromosomal rearrangements, which alter DNA sequences, gene products, and cellular functions, leading to genetic disorders, cancer, and evolutionary changes.</li>
                  </ul>
                </section>

                <header id="population-genetics"></header>
                <section>
                  <h1>Population Genetics</h1>
                  <p>Population genetics analyzes the genetic composition and variation within populations, as well as the processes that influence gene frequencies over time, such as natural selection, genetic drift, gene flow, and mutation. It provides insights into evolutionary processes and genetic diversity.</p>
                </section>

                <header id="key-concepts-population-genetics"></header>
                <section>
                  <h2>Key Concepts:</h2>
                  <ul>
                    <li><strong>Genetic Variation:</strong> Genetics explores genetic variation within populations, including allelic frequencies, genotype frequencies, and the distribution of genetic traits, which arise from mutations, genetic recombination, and genetic drift, and contribute to population diversity.</li>
                    <li><strong>Natural Selection:</strong> It investigates natural selection, the process by which advantageous alleles increase in frequency within a population over successive generations, while deleterious alleles decrease, leading to adaptation, speciation, and evolutionary change.</li>
                    <li><strong>Hardy-Weinberg Equilibrium:</strong> Genetics applies the Hardy-Weinberg equilibrium principle, which describes the relationship between allele frequencies and genotype frequencies in idealized populations under conditions of no mutation, no migration, random mating, large population size, and no natural selection, to assess genetic equilibrium and deviations from equilibrium.</li>
                  </ul>
                </section>

                <header id="genetic-engineering"></header>
                <section>
                  <h1>Genetic Engineering</h1>
                  <p>Genetic engineering involves the manipulation of genes, genomes, and genetic material to modify organisms for various purposes, such as biotechnology, agriculture, medicine, and industry. It encompasses techniques for gene cloning, gene editing, gene transfer, and genetic modification.</p>
                </section>

                <header id="key-concepts-genetic-engineering"></header>
                <section>
                  <h2>Key Concepts:</h2>
                  <ul>
                    <li><strong>Recombinant DNA Technology:</strong> Genetics explores recombinant DNA technology, which involves the insertion of DNA fragments from one organism (donor) into the genome of another organism (host) to produce recombinant DNA molecules with new genetic combinations and traits, used in genetic engineering applications.</li>
                    <li><strong>Gene Editing:</strong> It investigates gene editing techniques, such as CRISPR-Cas9, TALENs, and zinc-finger nucleases, which enable precise modification of DNA sequences by introducing targeted mutations, gene insertions, gene deletions, or gene replacements, with applications in gene therapy, biomedicine, and genetic engineering.</li>
                    <li><strong>Genetically Modified Organisms (GMOs):</strong> Genetics examines genetically modified organisms, including plants, animals, and microorganisms, whose genetic material has been altered using genetic engineering techniques to introduce desirable traits, improve agricultural productivity, enhance nutritional value, or confer resistance to pests, diseases, or environmental stresses.</li>
                  </ul>
                </section>

                <header id="human-genetics"></header>
                <section>
                  <h1>Human Genetics</h1>
                  <p>Human genetics focuses on the study of genetic variation, hereditary traits, and genetic disorders in humans. It investigates the genetic basis of human characteristics, diseases, and population diversity, as well as the ethical, legal, and social implications of genetic information.</p>
                </section>

                <header id="key-concepts-human-genetics"></header>
                <section>
                  <h2>Key Concepts:</h2>
                  <ul>
                    <li><strong>Human Genome Project:</strong> Genetics examines the Human Genome Project, an international research effort to sequence and map the entire human genome, which provided a comprehensive reference for human genetics, genomic medicine, and personalized healthcare.</li>
                    <li><strong>Genetic Disorders:</strong> It investigates genetic disorders, including single-gene disorders (e.g., cystic fibrosis, sickle cell disease), chromosomal disorders (e.g., Down syndrome, Turner syndrome), and complex genetic disorders (e.g., diabetes, cancer), as well as the genetic counseling, diagnosis, and treatment of genetic diseases.</li>
                    <li><strong>Genetic Testing:</strong> Genetics explores genetic testing methods, including molecular genetic tests, cytogenetic tests, and biochemical tests, which analyze DNA, chromosomes, or proteins to detect genetic mutations, assess disease risk, diagnose genetic disorders, determine carrier status, or guide personalized treatment decisions.</li>
                  </ul>
                </section>

                <header id="medical-genetics"></header>
                <section>
                  <h1>Medical Genetics</h1>
                  <p>Medical genetics applies principles of genetics to the diagnosis, management, and treatment of genetic disorders and inherited diseases. It integrates knowledge of genetics, genomics, and molecular biology into clinical practice to provide personalized healthcare and genetic counseling.</p>
                </section>

                <header id="key-concepts-medical-genetics"></header>
                <section>
                  <h2>Key Concepts:</h2>
                  <ul>
                    <li><strong>Genetic Counseling:</strong> Genetics involves genetic counseling, a process that provides individuals and families with information about genetic risks, inheritance patterns, genetic testing options, and medical management of genetic conditions, as well as emotional support, informed decision-making, and reproductive planning.</li>
                    <li><strong>Gene Therapy:</strong> It investigates gene therapy approaches, including gene replacement therapy, gene editing therapy, and gene regulation therapy, which aim to treat or cure genetic disorders by correcting or modifying defective genes, restoring normal gene function, or introducing therapeutic genes into patients' cells.</li>
                    <li><strong>Pharmacogenetics:</strong> Genetics explores pharmacogenetics, the study of how genetic variations influence individual responses to drugs, including drug metabolism, drug efficacy, drug safety, and adverse drug reactions, which enables personalized drug selection, dosage optimization, and therapeutic outcomes.</li>
                  </ul>
                </section>

                <header id="evolutionary-genetics"></header>
                <section>
                  <h1>Evolutionary Genetics</h1>
                  <p>Evolutionary genetics examines the genetic basis of evolutionary processes, including natural selection, genetic drift, gene flow, mutation, and speciation. It investigates genetic variation within and between populations, as well as the genetic mechanisms underlying adaptation, divergence, and biodiversity.</p>
                </section>

                <header id="key-concepts-evolutionary-genetics"></header>
                <section>
                  <h2>Key Concepts:</h2>
                  <ul>
                    <li><strong>Genetic Variation:</strong> Genetics explores genetic variation as the raw material for evolutionary change, including mutations, genetic recombination, and gene flow, which generate genetic diversity within populations and contribute to evolutionary adaptation, speciation, and ecological interactions.</li>
                    <li><strong>Adaptive Evolution:</strong> It investigates adaptive evolution, the process by which beneficial genetic variations increase in frequency within populations over time due to natural selection, leading to adaptations to changing environments, improved fitness, and the evolution of new species.</li>
                    <li><strong>Speciation:</strong> Genetics examines speciation, the formation of new species from ancestral populations, which occurs through mechanisms such as allopatric speciation (geographic isolation), sympatric speciation (reproductive isolation), and ecological speciation (adaptation to different niches), resulting in genetic divergence and reproductive isolation.</li>
                  </ul>
                </section>
              </div>
            </Col>
          </Row>
        </Container>
        <SidebarResource currentPage="genetics" />
      </div>
      <footer id='footer'>
        {/* Footer */}
        <Footer />
      </footer>
    </div>
  )
}

export default Genetics;
