import React from 'react'
import Image from 'react-bootstrap/Image';

const ImageOverlay = () => {
  return (
    <div>
        <Image src="/images/Banner.png" fluid alt="MedBudBanner" />
      </div>

  )
}

export default ImageOverlay