import React, { useState, useEffect, useRef } from 'react';
import { Nav, Navbar, Accordion } from 'react-bootstrap';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import '../css/master.css';

export const QuestionBank = () => {
  const [darkMode, setDarkMode] = useState(false); // Dark Mode
  const iframeRef = useRef(null); // Ref for the iframe
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // State to track if the view is mobile

  useEffect(() => {
    // Function to handle resizing and set mobile state
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check on component mount

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const categories = [
    'Medical Sciences',
    'Clinical Sciences',
    'Professional Development and Skills',
    'Clinical Practice and Procedures',
  ];

  const titlesByCategory = {
    'Medical Sciences': [
      'Anatomy',
      'Histology',
      'Biochemistry',
      'Pharmacology',
      'Microbiology',
      'Immunology',
      'Genetics',
      'Embryology',
    ],
    'Clinical Sciences': [
      'Internal Medicine',
      'Surgery',
      'Pediatrics',
      'Obstetrics and Gynecology',
      'Psychiatry',
      'Neurology',
      'Family Medicine',
      'Radiology',
      'Emergency Medicine',
      'Anesthesiology',
    ],
    'Professional Development and Skills': [
      'Medical Ethics',
      'Medical Jurisprudence',
      'Communication Skills',
      'Examination Skills',
      'Clinical Reasoning',
      'Medical Documentation',
      'Professionalism',
      'Cultural Competence',
      'Leadership',
    ],
    'Clinical Practice and Procedures': [
      'Clinical Skills',
      'Patient History',
      'Clinical Procedures',
      'Physical Examination Skills',
      'Physical Examination',
      'Diagnostic Techniques',
      'Surgical Techniques',
      'Electronic Health Records (EHR)',
      'Interprofessional Collaboration',
    ],
  };

  return (
    <div className={`${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <NavBar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      <div style={{ display: 'flex', height: '100vh' }}>
        {!isMobile && ( // Only render this div if not on a mobile device
          <div 
            className={`d-flex flex-column flex-shrink-0 p-3 ${darkMode ? 'text-white bg-dark' : 'text-bg-dark'} question-reference-list`} 
            style={{ width: '250px', overflowY: 'auto' }}>
            <Navbar.Brand className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-decoration-none">
              <span className={`fs-4 ${darkMode ? 'text-white' : ''}`}>Reference Topic List</span>
            </Navbar.Brand>
            <hr />
            <Accordion defaultActiveKey="0">
              {categories.map((category, index) => (
                <Accordion.Item key={index} eventKey={index.toString()}>
                  <Accordion.Header className={`nav-link ${darkMode ? 'text-white' : ''}`}>
                    {category}
                  </Accordion.Header>
                  <Accordion.Body>
                    <Nav className="nav nav-pills flex-column mb-auto">
                      {titlesByCategory[category].map((title, titleIndex) => (
                        <Nav.Link
                          key={titleIndex}
                          className={`nav-link ${darkMode ? 'text-white' : ''}`}
                          style={{ backgroundColor: 'transparent', textDecoration: 'none' }}
                        >
                          {title}
                        </Nav.Link>
                      ))}
                    </Nav>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            <p>Out of credits for this month? Check out the <a href='/Question-Database'>question database!</a></p>
          </div>
        )}
        {/* Iframe */}
        <div style={{ flex: 1, overflow: 'hidden' }}>
          <iframe
            id="KKSSANIMDQ"
            loading="eager"
            src="https://embed.pickaxeproject.com/axe?id=Med_School_Mastery_Maestro_9IKUH&mode=embed_gold&host=beta&theme=custom&opacity=100&font_header=Fira+Sans&size_header=30&font_body=Fira+Sans&size_body=16&font_labels=Fira+Sans&size_labels=14&font_button=Fira+Sans&size_button=16&c_fb=292828&c_ff=292828&c_fbd=FFFFFF&c_bb=FFFFFF&c_bt=000000&c_t=FFFFFF&s_ffo=100&s_bbo=100&s_f=minimalist&s_b=filled&s_t=0.5&s_to=1&s_r=2"
            width="100%"
            height="100%"
            style={{ border: 'none', boxShadow: 'none' }}
            ref={iframeRef}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default QuestionBank;
