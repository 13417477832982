import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import About from './pages/about';
import QuestionBank from './pages/question-bank';
import FAQ from './pages/FAQ';
import Contact from './pages/contact';
import Home from './pages/home'
//import SignIn from './pages/sign-in';
//import SignUp from './pages/sign-up';
import './css/master.css';
import Anatomy from './pages/infoPages/Medical Sciences/anatomy';
import Biochemistry from './pages/infoPages/Medical Sciences/biochemistry';
import Pharmacology from './pages/infoPages/Medical Sciences/pharmacology';
import Microbiology from './pages/infoPages/Medical Sciences/microbiology';
import Immunology from './pages/infoPages/Medical Sciences/immunology';
import Genetics from './pages/infoPages/Medical Sciences/genetics';
import Embryology from './pages/infoPages/Medical Sciences/embryology';
import InternalMedicine from './pages/infoPages/Clinical Sciences/internal-medicine';
import Surgery from './pages/infoPages/Clinical Sciences/surgery';
import Pediatrics from './pages/infoPages/Clinical Sciences/pediatrics';
import Psychiatry from './pages/infoPages/Clinical Sciences/psychiatry';
import ObstetricsandGynecology from './pages/infoPages/Clinical Sciences/obstetrics-and-gynecology';
import Neurology from './pages/infoPages/Clinical Sciences/neurology';
import FamilyMedicine from './pages/infoPages/Clinical Sciences/family-medicine';
import Radiology from './pages/infoPages/Clinical Sciences/radiology';
import EmergencyMedicine from './pages/infoPages/Clinical Sciences/emergency-medicine';
import Anesthsiology from './pages/infoPages/Clinical Sciences/anesthsiology';
import Histology from './pages/infoPages/Medical Sciences/histology';
import ClinicalProcedures from './pages/infoPages/Clinical Practice and Procedures/clinical-procedures';
import ClinicalSkills from './pages/infoPages/Clinical Practice and Procedures/clinical-skills';
import ClinicalReasoning from './pages/infoPages/Professional Development and Skills/clinical-reasoning';
import Leadership from './pages/infoPages/Professional Development and Skills/leadership';
import MedicalDocumentation from './pages/infoPages/Professional Development and Skills/medical-documentation';
import MedicalEthics from './pages/infoPages/Professional Development and Skills/medical-ethics';
import MedicalJurisprudence from './pages/infoPages/Professional Development and Skills/medical-jurisprudence';
import CommunicationSkills from './pages/infoPages/Professional Development and Skills/communication-skills';
import ExaminationSkills from './pages/infoPages/Professional Development and Skills/examination-skills';
import Professionalism from './pages/infoPages/Professional Development and Skills/professionalism';
import CulturalCompetence from './pages/infoPages/Professional Development and Skills/cultural-competence';
import PatientHistory from './pages/infoPages/Clinical Practice and Procedures/patient-history';
import DiagnosticTechniques from './pages/infoPages/Clinical Practice and Procedures/diagnostic-techniques';
import SurgicalTechniques from './pages/infoPages/Clinical Practice and Procedures/surgical-techniques';
import ElectronicHealthRecords from './pages/infoPages/Clinical Practice and Procedures/electronic-health-records';
import InterprofessionalCollaboration from './pages/infoPages/Clinical Practice and Procedures/interprofessional-collaboration';
import StudyTips from './pages/study-tips';
import MedicalTerminologyList from './pages/medical-terminology-list';
import QuestionDatabase from './pages/question-database';
import Flashcards from './pages/flashcards';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";





const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "study-tips",
    element: <StudyTips />,
  },
  {
    path: "medical-terminology-list",
    element: <MedicalTerminologyList />,
  },
  {
    path: "flashcards",
    element: <Flashcards />,
  },
  {
    path: "question-database",
    element: <QuestionDatabase />,
  },
  {
    path: "question-bank",
    element: <QuestionBank />,
  },
  {
    path: "FAQ",
    element: <FAQ />,
  },
  {
    path: "about",
    element: <About />,
  },
  {
    path: "contact",
    element: <Contact />,
  },
  {
    path: "home",
    element: <Home />,
  },
  {
    path: "anatomy",
    element: <Anatomy />,
  },
  {
    path: "histology",
    element: <Histology />,
  },
  {
    path: "biochemistry",
    element: <Biochemistry />,
  },
  {
    path: "pharmacology",
    element: <Pharmacology />,
  },
  {
    path: "microbiology",
    element: <Microbiology />,
  },
  {
    path: "immunology",
    element: <Immunology />,
  },
  {
    path: "genetics",
    element: <Genetics />,
  },
  {
    path: "embryology",
    element: <Embryology />,
  },
  {
    path: "internal-medicine",
    element: <InternalMedicine />,
  },
  {
    path: "surgery",
    element: <Surgery />,
  },
  {
    path: "pediatrics",
    element: <Pediatrics />,
  },
  {
    path: "obstetrics-and-gynecology",
    element: <ObstetricsandGynecology />,
  },
  {
    path: "psychiatry",
    element: <Psychiatry />,
  },
  {
    path: "neurology",
    element: <Neurology />,
  },
  {
    path: "family-medicine",
    element: <FamilyMedicine />,
  },
  {
    path: "radiology",
    element: <Radiology />,
  },
  {
    path: "emergency-medicine",
    element: <EmergencyMedicine />,
  },
  {
    path: "anesthesiology",
    element: <Anesthsiology />,
  },
  {
    path: "medical-ethics",
    element: <MedicalEthics />,
  },
  {
    path: "medical-jurisprudence",
    element: <MedicalJurisprudence />,
  },
  {
    path: "communication-skills",
    element: <CommunicationSkills />,
  },
  {
    path: "examination-skills",
    element: <ExaminationSkills />,
  },
  {
    path: "clinical-reasoning",
    element: <ClinicalReasoning />,
  },
  {
    path: "medical-documentation",
    element: <MedicalDocumentation />,
  },
  {
    path: "professionalism",
    element: <Professionalism />,
  },
  {
    path: "cultural-competence",
    element: <CulturalCompetence />,
  },
  {
    path: "leadership",
    element: <Leadership />,
  },
  {
    path: "clinical-skills",
    element: <ClinicalSkills />,
  },
  {
    path: "patient-history",
    element: <PatientHistory />,
  },
  {
    path: "clinical-procedures",
    element: <ClinicalProcedures />,
  },
  {
    path: "diagnostic-techniques",
    element: <DiagnosticTechniques />,
  },
  {
    path: "surgical-techniques",
    element: <SurgicalTechniques />,
  },
  {
    path: "electronic-health-records",
    element: <ElectronicHealthRecords />,
  },
  {
    path: "interprofessional-collaboration",
    element: <InterprofessionalCollaboration />,
  },
  
  

  //WHEN BACKNED IS ADDED, INCLUDE
  /*
  {
    path: "sign-in",
    element: <SignIn />,
  },
  {
    path: "sign-up",
    element: <SignUp />,
  },
  */
]);


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
