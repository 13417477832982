import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import NavBar from '../../../components/NavBar';
import NavBarCategories from '../../../components/NavBar-Categories';
import Sidebar from '../../../components/Sidebar';
import Footer from '../../../components/Footer';
import SidebarResource from '../../../components/Sidebar-Resource';

const InternalMedicine = () => {
  return (
    <div >
      <NavBar />
      <div className='sticky-nav-categories'>
      <NavBarCategories />
      </div>
      <div className=" pt-3 my-5 d-flex">
        {/* Sidebar */}
        <Sidebar pageTitle="InternalMedicine" />
        {/* Main Content */}
        <Container>
          <Row>
            <Col lg={12}>
            <div>
                <div>
    <h1 className="display-4 fw-bold text-body-emphasis text-center">Internal Medicine</h1>
</div>
<header id="the-nervous-system">
</header>

<section id="introduction">
    <h2>Introduction</h2>
    <p>
    Internal medicine is a medical specialty focused on the prevention, diagnosis, and treatment of adult diseases. Internists, also known as internal medicine physicians, are trained to provide comprehensive care for a wide range of medical conditions, including complex and chronic illnesses.</p>
</section>

<section id="key-areas-of-study">
    <h2>Key Areas of Study</h2>
    <ul>
        <li>Primary Care</li>
        <li>Diagnosis and Management</li>
        <li>Specialized Care</li>
        <li>Inpatient Care</li>
        <li>Geriatric Care</li>
        <li>Preventive Medicine</li>
        <li>Health Promotion</li>
    </ul>
</section>

<section id="primary-care">
    <h1>Primary Care</h1>
    <p>
    Internists provide primary care services, including routine check-ups, health screenings, and preventive services to maintain overall health and well-being in adults.</p>
</section>

<section id="key-concepts-primary-care">
    <h2>Key Concepts:</h2>
    <ul>
        <li><strong>Routine Check-ups:</strong> Internists conduct regular health assessments to monitor vital signs, assess risk factors, and detect early signs of illness.</li>
        <li><strong>Health Screenings:</strong> They perform screenings for conditions such as hypertension, diabetes, hyperlipidemia, and cancer to identify potential health risks and initiate timely interventions.</li>
        <li><strong>Preventive Services:</strong> Internists offer preventive services such as vaccinations, counseling on lifestyle modifications, and risk factor reduction strategies to prevent disease and promote healthy living.</li>
    </ul>
</section>

<section id="diagnosis-and-management">
    <h1>Diagnosis and Management</h1>
    <p>
    Internists are skilled in evaluating symptoms, ordering diagnostic tests, and developing treatment plans for acute and chronic conditions, including diabetes, hypertension, heart disease, and respiratory disorders.</p>
</section>

<section id="key-concepts-diagnosis-and-management">
    <h2>Key Concepts:</h2>
    <ul>
        <li><strong>Symptom Evaluation:</strong> Internists conduct thorough assessments to identify symptoms, obtain medical histories, and perform physical examinations to establish accurate diagnoses.</li>
        <li><strong>Diagnostic Tests:</strong> They order and interpret laboratory tests, imaging studies, and other diagnostic procedures to confirm diagnoses and monitor disease progression.</li>
        <li><strong>Treatment Plans:</strong> Internists develop individualized treatment plans that may include medication management, lifestyle modifications, and referrals to specialists for further evaluation and management.</li>
    </ul>
</section>

<section id="specialized-care">
    <h1>Specialized Care</h1>
    <p>
    Internists refer patients to specialists when necessary and coordinate care for complex medical conditions that require multidisciplinary treatment approaches.</p>
</section>

<section id="key-concepts-specialized-care">
    <h2>Key Concepts:</h2>
    <ul>
        <li><strong>Referrals:</strong> Internists collaborate with specialists such as cardiologists, pulmonologists, gastroenterologists, and oncologists to ensure patients receive specialized care tailored to their unique medical needs.</li>
        <li><strong>Coordination of Care:</strong> They oversee the management of patients with complex medical conditions, ensuring seamless communication between healthcare providers and continuity of care across different healthcare settings.</li>
    </ul>
</section>

<section id="inpatient-care">
    <h1>Inpatient Care</h1>
    <p>
    Internists manage the care of hospitalized patients, including those admitted for acute medical conditions, surgical procedures, or critical care.</p>
</section>

<section id="key-concepts-inpatient-care">
    <h2>Key Concepts:</h2>
    <ul>
        <li><strong>Hospital Rounds:</strong> Internists conduct daily rounds to assess the status of hospitalized patients, review diagnostic findings, adjust treatment plans, and coordinate care with other healthcare team members.</li>
        <li><strong>Inpatient Consultations:</strong> They provide consultations to address medical concerns and optimize management strategies for hospitalized patients, including preoperative evaluations and postoperative care.</li>
    </ul>
</section>

<section id="geriatric-care">
    <h1>Geriatric Care</h1>
    <p>
    Internists address the unique healthcare needs of older adults, including management of chronic diseases, functional decline, and end-of-life care.</p>
</section>

<section id="key-concepts-geriatric-care">
    <h2>Key Concepts:</h2>
    <ul>
        <li><strong>Comprehensive Assessments:</strong> Internists perform comprehensive geriatric assessments to evaluate cognitive function, functional status, medication use, and social support systems in older adults.</li>
        <li><strong>Chronic Disease Management:</strong> They manage chronic conditions such as hypertension, diabetes, osteoarthritis, and dementia, focusing on optimizing quality of life and functional independence in older patients.</li>
        <li><strong>End-of-Life Care:</strong> Internists provide compassionate care and support for patients nearing the end of life, including advance care planning, symptom management, and coordination of palliative and hospice services.</li>
    </ul>
</section>

<section id="preventive-medicine">
    <h1>Preventive Medicine</h1>
    <p>
    Internists promote health and wellness through vaccinations, screenings, lifestyle modifications, and management of risk factors for chronic diseases.</p>
</section>

<section id="key-concepts-preventive-medicine">
    <h2>Key Concepts:</h2>
    <ul>
        <li><strong>Vaccinations:</strong> Internists administer vaccinations to prevent infectious diseases such as influenza, pneumonia, shingles, and human papillomavirus (HPV).</li>
        <li><strong>Screenings:</strong> They conduct screenings for conditions such as breast cancer, colorectal cancer, cervical cancer, osteoporosis, and sexually transmitted infections to detect diseases early and initiate appropriate interventions.</li>
        <li><strong>Lifestyle Modifications:</strong> Internists counsel patients on healthy lifestyle habits, including diet modification, regular exercise, smoking cessation, stress management, and weight management, to reduce the risk of chronic diseases and promote overall well-being.</li>
        <li><strong>Management of Risk Factors:</strong> They manage risk factors such as hypertension, hyperlipidemia, obesity, and diabetes through medication management, lifestyle modifications, and patient education to prevent complications and improve health outcomes.</li>
    </ul>
</section>

<section id="health-promotion">
    <h1>Health Promotion</h1>
    <p>
    Internists educate patients about healthy living practices, disease prevention strategies, and management of chronic conditions to optimize their quality of life.</p>
</section>

<section id="key-concepts-health-promotion">
    <h2>Key Concepts:</h2>
    <ul>
        <li><strong>Patient Education:</strong> Internists provide patients with information about disease prevention, health promotion, and self-care strategies to empower them to make informed decisions and take an active role in managing their health.</li>
        <li><strong>Community Outreach:</strong> They engage in community outreach activities such as health fairs, educational seminars, and preventive screenings to raise awareness about important health issues and promote healthy behaviors in the community.</li>
    </ul>
</section>
</div>
            </Col>
          </Row>
        </Container>
        <SidebarResource currentPage="internalMedicine" />
      </div>
      <footer id='footer'>
          {/* Footer */}
        <Footer />
        </footer>
    </div>
  )
}

export default InternalMedicine
