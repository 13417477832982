import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NavBar from '../../../components/NavBar';
import NavBarCategories from '../../../components/NavBar-Categories';
import Sidebar from '../../../components/Sidebar';
import Footer from '../../../components/Footer';
import SidebarResource from '../../../components/Sidebar-Resource';

const Embryology = () => {
  return (
    <div>
      <NavBar />
      <div className='sticky-nav-categories'>
        <NavBarCategories />
      </div>
      <div className="pt-3 my-5 d-flex">
        {/* Sidebar */}
        <Sidebar pageTitle="Embryology" />
        {/* Main Content */}
        <Container>
          <Row>
            <Col lg={12}>
              <div>
                <div>
                  <h1 className="display-4 fw-bold text-body-emphasis text-center">Embryology</h1>
                </div>
                <header id="introduction"></header>
                <section>
                  <h2>Introduction</h2>
                  <p>Embryology is the branch of biology that studies the development of embryos from fertilization to the formation of complete organisms. It provides insights into the processes underlying morphogenesis, differentiation, and growth, elucidating the mechanisms that shape the structure and function of organisms during prenatal and postnatal development.</p>
                </section>

                <header id="key-areas-of-study"></header>
                <section>
                  <h2>Key Areas of Study</h2>
                  <ul>
                    <li>Embryonic Development</li>
                    <li>Germ Layer Differentiation</li>
                    <li>Organogenesis</li>
                    <li>Developmental Genetics</li>
                    <li>Teratogenesis</li>
                    <li>Regenerative Medicine</li>
                  </ul>
                </section>

                <header id="embryonic-development"></header>
                <section>
                  <h1>Embryonic Development:</h1>
                  <p>Embryology investigates the sequential series of events that occur during embryonic development, from fertilization of the egg to the formation of the basic body plan. This process involves cell division, cell differentiation, and tissue morphogenesis, leading to the formation of organs and organ systems.</p>
                </section>

                <header id="key-concepts-embryonic-development"></header>
                <section>
                  <h2>Key Concepts:</h2>
                  <ul>
                    <li><strong>Fertilization:</strong> The fusion of sperm and egg, resulting in the formation of a diploid zygote.</li>
                    <li><strong>Gastrulation:</strong> The process by which the blastula undergoes inward folding to form the three germ layers: ectoderm, mesoderm, and endoderm.</li>
                    <li><strong>Neurulation:</strong> The formation of the neural tube from the ectoderm, giving rise to the central nervous system.</li>
                    <li><strong>Organogenesis:</strong> The differentiation and development of organs and organ systems from the germ layers, guided by complex molecular signaling pathways.</li>
                  </ul>
                </section>

                <header id="germ-layer-differentiation"></header>
                <section>
                  <h1>Germ Layer Differentiation:</h1>
                  <p>Embryology explores the differentiation of the three primary germ layers—ectoderm, mesoderm, and endoderm—during embryonic development. Each germ layer gives rise to specific tissues and organs, contributing to the structural and functional complexity of the organism.</p>
                </section>

                <header id="key-concepts-germ-layer-differentiation"></header>
                <section>
                  <h2>Key Concepts:</h2>
                  <ul>
                    <li><strong>Ectoderm:</strong> Gives rise to the epidermis, nervous system, sensory organs, and epithelial linings of the mouth and anus.</li>
                    <li><strong>Mesoderm:</strong> Differentiates into muscle, bone, connective tissue, circulatory system, urogenital system, and portions of the digestive and respiratory systems.</li>
                    <li><strong>Endoderm:</strong> Forms the epithelial linings of the respiratory tract, gastrointestinal tract, liver, pancreas, and urinary bladder.</li>
                  </ul>
                </section>

                <header id="organogenesis"></header>
                <section>
                  <h1>Organogenesis:</h1>
                  <p>Embryology studies the process of organogenesis, during which rudimentary structures develop into fully functional organs with distinct anatomical and physiological properties. This intricate process involves cell proliferation, migration, differentiation, and tissue remodeling.</p>
                </section>

                <header id="key-concepts-organogenesis"></header>
                <section>
                  <h2>Key Concepts:</h2>
                  <ul>
                    <li><strong>Heart Development:</strong> Embryology elucidates the formation of the heart from mesodermal precursor cells, including the development of cardiac chambers, valves, and conduction system.</li>
                    <li><strong>Limbs and Appendages:</strong> Investigates the development of limbs and appendages from limb buds, including the formation of bones, muscles, blood vessels, and nerves.</li>
                    <li><strong>Organogenesis:</strong> Studies the development of other vital organs and systems, such as the brain, lungs, liver, kidneys, and reproductive organs, from their embryonic precursors.</li>
                  </ul>
                </section>

                <header id="developmental-genetics"></header>
                <section>
                  <h1>Developmental Genetics:</h1>
                  <p>Embryology examines the role of genetics in embryonic development, including the regulation of gene expression, cell fate determination, and patterning of the developing embryo. It explores the interactions between genetic factors and environmental cues in shaping morphogenesis and differentiation.</p>
                </section>

                <header id="key-concepts-developmental-genetics"></header>
                <section>
                  <h2>Key Concepts:</h2>
                  <ul>
                    <li><strong>Homeobox Genes:</strong> Investigates the role of homeobox genes, such as Hox genes, in specifying regional identities along the anterior-posterior axis of the embryo and directing organ development.</li>
                    <li><strong>Signaling Pathways:</strong> Studies molecular signaling pathways, including BMP, Wnt, Notch, and Hedgehog signaling, that regulate cell proliferation, differentiation, and tissue patterning during embryogenesis.</li>
                    <li><strong>Morphogen Gradients:</strong> Examines the establishment of morphogen gradients, such as those of Sonic hedgehog (Shh) and retinoic acid (RA), which provide positional information to cells and guide tissue patterning and organogenesis.</li>
                  </ul>
                </section>

                <header id="teratogenesis"></header>
                <section>
                  <h1>Teratogenesis:</h1>
                  <p>Embryology investigates teratogenesis, the process by which environmental agents, genetic mutations, or abnormal developmental processes lead to the formation of congenital anomalies or birth defects. It explores the mechanisms underlying teratogenicity and strategies for prevention and management.</p>
                </section>

                <header id="key-concepts-teratogenesis"></header>
                <section>
                  <h2>Key Concepts:</h2>
                  <ul>
                    <li><strong>Teratogens:</strong> Studies various teratogenic agents, including drugs, chemicals, infectious agents, radiation, and maternal factors, and their effects on embryonic development.</li>
                    <li><strong>Critical Periods:</strong> Examines sensitive periods during embryogenesis when developing tissues and organs are most vulnerable to teratogenic insults, highlighting the importance of prenatal care and avoidance of teratogenic exposures.</li>
                    <li><strong>Prevention Strategies:</strong> Investigates strategies for preventing birth defects, such as maternal education, preconception counseling, genetic screening, and environmental risk reduction.</li>
                  </ul>
                </section>

                <header id="regenerative-medicine"></header>
                <section>
                  <h1>Regenerative Medicine:</h1>
                  <p>Embryology contributes to the field of regenerative medicine, which aims to restore or replace damaged tissues and organs by harnessing the regenerative capacity of stem cells and understanding the principles of developmental biology. It explores strategies for tissue engineering, organ transplantation, and regenerative therapies.</p>
                </section>

              </div>
            </Col>
          </Row>
        </Container>
        <SidebarResource currentPage="embryology" />
      </div>
      <footer id='footer'>
        {/* Footer */}
        <Footer />
      </footer>
    </div>
  );
}

export default Embryology;
