import React from 'react';
import { Button, Col, } from 'react-bootstrap';

const SidebarResource = ({ currentPage }) => {
  const linkStyle = {
    display: 'block',
    padding: '10px',
    maxWidth: '100%', 
    borderRadius: '5px',
    marginBottom: '5px',
    transition: 'opacity 0.3s ease',
  };

  // Define different sets of links for each page
  const pageLinks = {
    anatomy: [
      { url: 'https://anatomyzone.com/', text: 'Anatomy Zone' },
      { url: 'https://www.free-anatomy-quiz.com/', text: 'Anatomy Quiz' }
    ],
    histology: [
      { url: 'https://www.purposegames.com/game/ec525e4d59', text: 'Histology quiz' },
      { url: 'https://www.virtualhistologylab.org/', text: 'Histology Lab' }
    ],
    biochemistry: [
      { url: 'https://open.umn.edu/opentextbooks/textbooks/biochemistry-free-for-all-ahern', text: 'TextBook-PDF' },
      { url: 'https://www.picmonic.com/pathways/nursing-np/courses/standard/biochemistry-6926?marketId=203&pathwayId=6926', text: 'Picmonic' }
    ],
    pharmacology: [
      { url: 'https://www.guidetopharmacology.org/', text: 'Guide to Pharmacology' },
      { url: 'https://pharmacologyeducation.org/pharmacology/peptides', text: 'Pharmacology Education Project' }
    ],
    microbiology: [
      { url: 'https://microbenotes.com/top-best-biology-websites-blogs/', text: 'Microbe Notes' },
    ],
    immunology: [
      { url: 'https://www.immunopaedia.org.za/', text: 'Immunopedia' },
      { url: 'https://www.nature.com/ni/', text: 'Nature Immunology' }
    ],
    genetics: [
      { url: 'https://www.genome.gov/', text: 'Genome.gov' },
      { url: 'https://learn.genetics.utah.edu/', text: 'Learn.Genetics' }
    ],
    embryology: [
      { url: 'https://embryo.asu.edu/', text: 'Embyro Project' },
      { url: 'https://embryology.ch/en/embryogenese/', text: 'Embyrology.ch' }
    ],
    
//Clinical Sciences
    internalMedicine: [
      { url: 'https://www.medscape.com/internalmedicine', text: 'MedScape' },
    ],
    surgery: [
      { url: 'https://www.surgicalcore.org/', text: 'Surgicalcore' },
    ],
    pediatrics: [
      { url: '', text: '' },
    ],
    obstetricsandGynecology: [
      { url: '', text: '' },
    ],
    psychiatry: [
      { url: '', text: '' },
    ],
    neurology: [
      { url: 'https://nba.uth.tmc.edu/neuroscience/index.htm', text: 'NeuroScience Online' },
    ],
    familyMedicine: [
      { url: 'https://www.medscape.org/familymedicine', text: 'MedScape' },
    ],
    radiology: [
      { url: 'https://radiologyassistant.nl/', text: 'Radiology Assistant' },
      { url: 'https://www.learningradiology.com/', text: 'Learning Radiology' }
    ],
    emergencyMedicine: [
      { url: '', text: '' },
    ],
    anesthesiology: [
      { url: '', text: '' },
    ],

    //Professional Development and Skills
    medicalEthics: [
      { url: '', text: '' },
    ],
    medicalJurisprudence: [
      { url: '', text: '' },
    ],
    communicationSkills: [
      { url: '', text: '' },
    ],
    examinationSkills: [
      { url: '', text: '' },
    ],
    clinicalReasoning: [
      { url: '', text: '' },
    ],
    medicalDocumentation: [
      { url: '', text: '' },
    ],
    professionalism: [
      { url: '', text: '' },
    ],
    culturalCompetence: [
      { url: '', text: '' },
    ],
    leadership: [
      { url: '', text: '' },
    ],

    //Clinical Practice and Procedures

    clinicalSkills: [
      { url: '', text: '' },
    ],
    patientHistory: [
      { url: '', text: '' },
    ],
    clinicalProcedures: [
      { url: '', text: '' },
    ],
    diagnosticTechniques: [
      { url: '', text: '' },
    ],
    surgicalTechniques: [
      { url: '', text: '' },
    ],
    electronicHealthRecords: [
      { url: '', text: '' },
    ],
    interprofessionalCollaboration: [
      { url: '', text: '' },
    ]
  };

  // Get the links for the current page
  const currentPageLinks = pageLinks[currentPage] || [];
  
  // Check if any URLs exist
  const hasUrls = currentPageLinks.some(link => link.url);

  return (
    <div className="sidebar-container-resources">
    <Col xs={3} sm={2} md={8} lg={8} className="rightResourceBar">
      <h3  style={{ textAlign: 'center', color: hasUrls ? 'black' : 'white' }}>Resources</h3>
      {currentPageLinks.map((link, index) => (
        link.url ? (
          <Button key={index} href={link.url} variant="primary" size="lg" className="px-3 my-2 d-block" style={linkStyle}>
            {link.text}
          </Button>
        ) : (
          <div key={index} style={{ height: '40px' }}>{'\u00A0'}</div>
        )
        
      ))}
    </Col>
    </div>
  );
};


export default SidebarResource;
