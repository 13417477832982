import React from 'react'
import NavBar from '../components/NavBar';


const flashcards = () => {
  return (
    <div><NavBar /></div>
  )
}

export default flashcards