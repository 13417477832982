import React, {useState} from 'react'
import NavBar from '../components/NavBar';



const Home = () => {

  const [darkMode, setDarkMode] = useState(false); //Dark Mode

  const toggleDarkMode = () => {
     setDarkMode(!darkMode);
   };


  return (
    <NavBar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
  )
}

export default Home