import React, { useState, useRef } from 'react';
import { Container, Button, Row, Col, Card, ListGroup, Modal } from 'react-bootstrap';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer'
import '../css/master.css';


const MedicalTerminologyList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [selectedPrefix, setSelectedPrefix] = useState(null);
  const [selectedAcronym, setSelectedAcronym] = useState(null);
  const [selectedMedical, setSelectedMedical] = useState(null);
  const [selectedRootWord, setSelectedRootWord] = useState(null);
  

  const topTermsRef = useRef(null);
  const topPrefixesRef = useRef(null);
  const topAcronymsRef = useRef(null);
  const topRootWordsRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };


  // Preset list of medical terms with their meanings
  const terminologyList = [
    { term: `Acetylcholine`, meaning: `A neurotransmitter responsible for muscle contraction, among other functions.` },
    { term: `Adrenal Gland`, meaning: `An endocrine gland located on top of each kidney that secretes hormones like cortisol and adrenaline.` },
    { term: `Alveoli`, meaning: `Tiny air sacs in the lungs where gas exchange occurs.` },
    { term: `Anaphylaxis`, meaning: `A severe and potentially life-threatening allergic reaction.` },
    { term: `Anatomy`, meaning: `The study of the structure and organization of the body and its parts.` },
    { term: `Aneurysm`, meaning: `A bulge or swelling in a blood vessel, often caused by weakness in the vessel wall.` },
    { term: `Angina`, meaning: `Chest pain or discomfort caused by reduced blood flow to the heart muscle.` },
    { term: `Antibiotic`, meaning: `A medication used to treat bacterial infections.` },
    { term: `Aorta`, meaning: `The largest artery in the body, responsible for carrying oxygen-rich blood away from the heart.` },
    { term: `Arrhythmia`, meaning: `An abnormal heart rhythm, often characterized by palpitations or irregular heartbeat.` },
    { term: `Artery`, meaning: `Blood vessels that carry oxygen-rich blood away from the heart to the rest of the body.` },
    { term: `Arthritis`, meaning: `Inflammation of one or more joints, causing pain and stiffness.` },
    { term: `Aseptic`, meaning: `Free from contamination by harmful microorganisms.` },
    { term: `Aspirin`, meaning: `A medication used to reduce pain, fever, and inflammation, and to prevent blood clots.` },
    { term: `Asthma`, meaning: `A chronic respiratory condition characterized by inflammation and narrowing of the airways, leading to difficulty breathing.` },
    { term: `Atherosclerosis`, meaning: `A condition in which plaque builds up inside the arteries, leading to narrowing and reduced blood flow.` },
    { term: `Autoimmune`, meaning: `A condition in which the body's immune system mistakenly attacks its own tissues, leading to inflammation and damage.` },
    { term: `Biopsy`, meaning: `The removal and examination of a small sample of tissue from the body for diagnostic purposes.` },
    { term: `Blood Pressure`, meaning: `The force exerted by circulating blood against the walls of the arteries.` },
    { term: `Bronchitis`, meaning: `Inflammation of the bronchial tubes, often resulting in coughing and difficulty breathing.` },
    { term: `Cancer`, meaning: `A group of diseases characterized by the uncontrolled growth and spread of abnormal cells.` },
    { term: `Cardiac Arrest`, meaning: `Sudden cessation of heart function, often resulting in loss of consciousness and death if not treated promptly.` },
    { term: `Cardiology`, meaning: `The branch of medicine that deals with the study and treatment of disorders of the heart and blood vessels.` },
    { term: `Cataract`, meaning: `Clouding of the lens of the eye, leading to decreased vision.` },
    { term: `Cellulitis`, meaning: `A bacterial skin infection characterized by redness, swelling, and pain.` },
    { term: `Cholesterol`, meaning: `A fatty substance found in the blood, necessary for building cell membranes and producing hormones, but high levels can increase the risk of heart disease.` },
    { term: `Circulatory System`, meaning: `The system of organs and tissues, including the heart and blood vessels, responsible for circulating blood and lymph throughout the body.` },
    { term: `Colonoscopy`, meaning: `A procedure used to examine the inside of the colon and rectum for abnormalities or signs of disease.` },
    { term: `Congenital`, meaning: `Present at birth, often referring to conditions or abnormalities that develop in utero.` },
    { term: `Conjunctivitis`, meaning: `Inflammation of the conjunctiva, the thin membrane that covers the white part of the eye and lines the inner surface of the eyelids.` },
    { term: `Coronary Artery`, meaning: `Arteries that supply oxygen-rich blood to the heart muscle itself.` },
    { term: `Corticosteroid`, meaning: `A type of medication that reduces inflammation and suppresses the immune system.` },
    { term: `Cystitis`, meaning: `Inflammation of the bladder, often caused by a bacterial infection.` },
    { term: `Diabetes`, meaning: `A group of metabolic diseases characterized by high blood sugar levels over a prolonged period.` },
    { term: `Diagnostic`, meaning: `Relating to the identification of a disease or condition.` },
    { term: `Dietitian`, meaning: `A healthcare professional trained in the science of nutrition and dietetics.` },
    { term: `Digestive System`, meaning: `The system of organs responsible for the digestion and absorption of food, as well as the elimination of waste.` },
    { term: `Disease`, meaning: `A disorder of structure or function in a living organism, often characterized by specific signs and symptoms.` },
    { term: `Dysfunction`, meaning: `Impaired or abnormal functioning, often referring to an organ or system.` },
    { term: `Electrocardiogram`, meaning: `A test that measures the electrical activity of the heart over time, often used to diagnose heart conditions.` },
    { term: `Embolism`, meaning: `A sudden blockage of a blood vessel by a clot or other foreign material, often leading to tissue damage or death.` },
    { term: `Endocrinology`, meaning: `The branch of medicine that deals with the study and treatment of disorders of the endocrine system and hormones.` },
    { term: `Epidemiology`, meaning: `The study of the distribution and determinants of health-related states or events in populations.` },
    { term: `Epilepsy`, meaning: `A neurological disorder characterized by recurrent seizures, often associated with abnormal electrical activity in the brain.` },
    { term: `Exacerbation`, meaning: `A sudden worsening or flare-up of symptoms of a disease or condition.` },
    { term: `Fever`, meaning: `An elevation of body temperature above the normal range, often as a response to infection or inflammation.` },
    { term: `Gastroenteritis`, meaning: `Inflammation of the stomach and intestines, often resulting in symptoms like diarrhea, vomiting, and abdominal pain.` },
    { term: `Genetics`, meaning: `The study of genes, heredity, and genetic variation in living organisms.` },
    { term: `Geriatrics`, meaning: `The branch of medicine that deals with the study and treatment of diseases and conditions of older adults.` },
    { term: `Hematology`, meaning: `The branch of medicine that deals with the study and treatment of disorders of the blood and blood-forming organs.` },
    { term: `Hepatitis`, meaning: `Inflammation of the liver, often caused by viral infection, alcohol, or toxins.` },
    { term: `Hormone Therapy`, meaning: `The use of hormones to treat medical conditions, often used to alleviate symptoms of menopause or hormone-related cancers.` },
    { term: `Hypertension`, meaning: `High blood pressure, often a risk factor for heart disease and stroke.` },
    { term: `Immunization`, meaning: `The process of making an individual immune or resistant to a particular infectious disease, typically by administering a vaccine.` },
    { term: `Immunodeficiency`, meaning: `A condition in which the body's immune system is weakened or impaired, leading to an increased susceptibility to infections.` },
    { term: `Immunoglobulin`, meaning: `A protein produced by plasma cells that functions as an antibody, binding to specific antigens and helping to neutralize or eliminate them.` },
    { term: `Infection`, meaning: `The invasion and multiplication of microorganisms in body tissues, often resulting in illness or disease.` },
    { term: `Inflammation`, meaning: `The body's response to injury, infection, or irritation, characterized by redness, swelling, heat, and pain.` },
    { term: `Insulin`, meaning: `A hormone produced by the pancreas that regulates blood sugar levels by facilitating the uptake of glucose into cells.` },
    { term: `Intensive Care Unit`, meaning: `A specialized department within a hospital that provides around-the-clock care for critically ill patients.` },
    { term: `Jaundice`, meaning: `A yellowing of the skin and eyes, often caused by liver disease or obstruction of the bile ducts.` },
    { term: `Kidney`, meaning: `An organ responsible for filtering waste products from the blood and producing urine.` }
  ];

  const handleTermClick = (term) => {
    setSelectedTerm(term);
    if (window.innerWidth <= 768) {
      setIsModalOpen(true);
    }
  };

  const prefixList = [
    { prefix: 'A-', meaning: 'Without or absence of (e.g., atypical - not typical)' },
    { prefix: 'Ab-', meaning: 'Away from (e.g., abduct - move away from the midline)' },
    { prefix: 'Ad-', meaning: 'Towards or near (e.g., adduction - move towards the midline)' },
    { prefix: 'Anti-', meaning: 'Against or opposed to (e.g., antiseptic - against sepsis)' },
    { prefix: 'Bi-', meaning: 'Two or double (e.g., bilateral - relating to both sides)' },
    { prefix: 'Brady-', meaning: 'Slow (e.g., bradycardia - slow heart rate)' },
    { prefix: 'Circum-', meaning: 'Around or about (e.g., circumcision - removal of the foreskin)' },
    { prefix: 'Dia-', meaning: 'Through or across (e.g., diameter - measurement across)' },
    { prefix: 'Dys-', meaning: 'Abnormal or difficult (e.g., dysfunction - abnormal function)' },
    { prefix: 'Ecto-', meaning: 'Outside or external (e.g., ectoderm - outer layer of embryonic cells)' },
    { prefix: 'Endo-', meaning: 'Within or inner (e.g., endoskeleton - internal skeleton)' },
    { prefix: 'Ex-', meaning: 'Out of or away from (e.g., exhale - breathe out)' },
    { prefix: 'Extra-', meaning: 'Beyond or outside (e.g., extracellular - outside the cell)' },
    { prefix: 'Hemi-', meaning: 'Half (e.g., hemisphere - half of a sphere)' },
    { prefix: 'Hyper-', meaning: 'Excessive or above normal (e.g., hypertension - high blood pressure)' },
    { prefix: 'Hypo-', meaning: 'Deficient or below normal (e.g., hypoglycemia - low blood sugar)' },
    { prefix: 'In-', meaning: 'Not or without (e.g., inactive - not active)' },
    { prefix: 'Infra-', meaning: 'Below or beneath (e.g., infrared - below red in the light spectrum)' },
    { prefix: 'Inter-', meaning: 'Between or among (e.g., intercostal - between the ribs)' },
    { prefix: 'Intra-', meaning: 'Within or inside (e.g., intravenous - within a vein)' },
    { prefix: 'Macro-', meaning: 'Large or long (e.g., macroscopic - visible to the naked eye)' },
    { prefix: 'Mal-', meaning: 'Bad or abnormal (e.g., malformation - abnormal formation)' },
    { prefix: 'Meta-', meaning: 'Change or beyond (e.g., metastasis - spread of cancer beyond original site)' },
    { prefix: 'Micro-', meaning: 'Small or minute (e.g., microscope - device to see small objects)' },
    { prefix: 'Neo-', meaning: 'New or recent (e.g., neonatal - relating to newborns)' },
    { prefix: 'Para-', meaning: 'Beside or beyond (e.g., parathyroid - adjacent to the thyroid)' },
    { prefix: 'Per-', meaning: 'Through or by (e.g., percutaneous - through the skin)' },
    { prefix: 'Peri-', meaning: 'Around or surrounding (e.g., pericardium - membrane around the heart)' },
    { prefix: 'Poly-', meaning: 'Many or much (e.g., polyuria - excessive urination)' },
    { prefix: 'Post-', meaning: 'After or behind (e.g., postoperative - after surgery)' },
    { prefix: 'Pre-', meaning: 'Before or in front of (e.g., prenatal - before birth)' },
    { prefix: 'Pro-', meaning: 'Before or in front of (e.g., prokaryote - before nucleus)' },
    { prefix: 'Re-', meaning: 'Again or back (e.g., reabsorb - absorb again)' },
    { prefix: 'Retro-', meaning: 'Backward or behind (e.g., retrograde - backward movement)' },
    { prefix: 'Sub-', meaning: 'Below or under (e.g., subcutaneous - under the skin)' },
    { prefix: 'Super-', meaning: 'Above or excessive (e.g., superego - above ego in Freudian psychology)' },
    { prefix: 'Supra-', meaning: 'Above or beyond (e.g., suprarenal - above the kidney)' },
    { prefix: 'Sym-', meaning: 'Together or with (e.g., symphysis - fusion of bones)' },
    { prefix: 'Syn-', meaning: 'Together or with (e.g., synthesis - combination or production of a substance)' },
    { prefix: 'Tachy-', meaning: 'Fast or rapid (e.g., tachycardia - rapid heart rate)' },
    { prefix: 'Trans-', meaning: 'Across or through (e.g., transdermal - through the skin)' },
    { prefix: 'Ultra-', meaning: 'Beyond or excessive (e.g., ultraviolet - beyond violet in the light spectrum)' },
    { prefix: 'Uni-', meaning: 'One or single (e.g., unilateral - affecting only one side)' },
    { prefix: 'Xeno-', meaning: 'Foreign or strange (e.g., xenotransplantation - transplantation between species)' }
  ];

  const handlePrefixClick = (prefix) => {
    setSelectedPrefix(prefix);
    if (window.innerWidth <= 768) {
      setIsModalOpen(true);
    }
  };


  const acronymList = [
    { acronym: 'ADHD', meaning: 'Attention Deficit Hyperactivity Disorder' },
    { acronym: 'AIDS', meaning: 'Acquired Immune Deficiency Syndrome' },
    { acronym: 'ALS', meaning: 'Amyotrophic Lateral Sclerosis' },
    { acronym: 'ALT', meaning: 'Alanine Aminotransferase' },
    { acronym: 'AST', meaning: 'Aspartate Aminotransferase' },
    { acronym: 'BMD', meaning: 'Bone Mineral Density' },
    { acronym: 'CAD', meaning: 'Coronary Artery Disease' },
    { acronym: 'CBC', meaning: 'Complete Blood Count' },
    { acronym: 'CNS', meaning: 'Central Nervous System' },
    { acronym: 'CRF', meaning: 'Chronic Renal Failure' },
    { acronym: 'CRP', meaning: 'C-Reactive Protein' },
    { acronym: 'CSF', meaning: 'Cerebrospinal Fluid' },
    { acronym: 'CT', meaning: 'Computed Tomography' },
    { acronym: 'CTC', meaning: 'Computed Tomography Colonography' },
    { acronym: 'DNR', meaning: 'Do Not Resuscitate' },
    { acronym: 'DNA', meaning: 'Deoxyribonucleic Acid' },
    { acronym: 'DVT', meaning: 'Deep Vein Thrombosis' },
    { acronym: 'ECG', meaning: 'Electrocardiogram' },
    { acronym: 'ECMO', meaning: 'Extracorporeal Membrane Oxygenation' },
    { acronym: 'ECHO', meaning: 'Echocardiogram' },
    { acronym: 'EEG', meaning: 'Electroencephalogram' },
    { acronym: 'EKG', meaning: 'Electrocardiogram' },
    { acronym: 'ENT', meaning: 'Ear, Nose, and Throat' },
    { acronym: 'ER', meaning: 'Emergency Room' },
    { acronym: 'ESR', meaning: 'Erythrocyte Sedimentation Rate' },
    { acronym: 'GFR', meaning: 'Glomerular Filtration Rate' },
    { acronym: 'HBsAg', meaning: 'Hepatitis B Surface Antigen' },
    { acronym: 'HDL', meaning: 'High-Density Lipoprotein' },
    { acronym: 'HDL-C', meaning: 'High-Density Lipoprotein Cholesterol' },
    { acronym: 'HIV', meaning: 'Human Immunodeficiency Virus' },
    { acronym: 'HIV/AIDS', meaning: 'Human Immunodeficiency Virus/Acquired Immune Deficiency Syndrome' },
    { acronym: 'HMO', meaning: 'Health Maintenance Organization' },
    { acronym: 'HPV', meaning: 'Human Papillomavirus' },
    { acronym: 'ICU', meaning: 'Intensive Care Unit' },
    { acronym: 'LDH', meaning: 'Lactate Dehydrogenase' },
    { acronym: 'LDL', meaning: 'Low-Density Lipoprotein' },
    { acronym: 'MD', meaning: 'Doctor of Medicine' },
    { acronym: 'MRI', meaning: 'Magnetic Resonance Imaging' },
    { acronym: 'MS', meaning: 'Multiple Sclerosis' },
    { acronym: 'NHS', meaning: 'National Health Service' },
    { acronym: 'NGT', meaning: 'Nasogastric Tube' },
    { acronym: 'OB/GYN', meaning: 'Obstetrics and Gynecology' },
    { acronym: 'OTC', meaning: 'Over-the-Counter' },
    { acronym: 'PE', meaning: 'Pulmonary Embolism' },
    { acronym: 'PET', meaning: 'Positron Emission Tomography' },
    { acronym: 'PID', meaning: 'Pelvic Inflammatory Disease' },
    { acronym: 'PFT', meaning: 'Pulmonary Function Test' },
    { acronym: 'PPD', meaning: 'Purified Protein Derivative' },
    { acronym: 'PTSD', meaning: 'Post-Traumatic Stress Disorder' },
    { acronym: 'PTT', meaning: 'Partial Thromboplastin Time' },
    { acronym: 'RA', meaning: 'Rheumatoid Arthritis' },
    { acronym: 'RBC', meaning: 'Red Blood Cell' },
    { acronym: 'REM', meaning: 'Rapid Eye Movement' },
    { acronym: 'SARS', meaning: 'Severe Acute Respiratory Syndrome' },
    { acronym: 'SIDS', meaning: 'Sudden Infant Death Syndrome' },
    { acronym: 'STD', meaning: 'Sexually Transmitted Disease' },
    { acronym: 'TB', meaning: 'Tuberculosis' },
    { acronym: 'TIA', meaning: 'Transient Ischemic Attack' },
    { acronym: 'TLC', meaning: 'Total Lung Capacity' },
    { acronym: 'TSH', meaning: 'Thyroid-Stimulating Hormone' },
    { acronym: 'URI', meaning: 'Upper Respiratory Infection' },
    { acronym: 'UTI', meaning: 'Urinary Tract Infection' },
    { acronym: 'US', meaning: 'Ultrasound' },
    { acronym: 'VLDL', meaning: 'Very-Low-Density Lipoprotein' },
    { acronym: 'WHO', meaning: 'World Health Organization' }
  ];

  const handleAcronymClick = (acronym) => {
    setSelectedAcronym(acronym);
    if (window.innerWidth <= 768) {
      setIsModalOpen(true);
    }
  };

  const medicalRootWordsList = [
    { rootWord: 'Aero', meaning: 'Air or gas (e.g., aerobic - requiring air)' },
    { rootWord: 'Alb', meaning: 'White (e.g., albino - person with pale skin)' },
    { rootWord: 'Angio', meaning: 'Vessel (e.g., angiogram - x-ray of blood vessels)' },
    { rootWord: 'Arthr', meaning: 'Joint (e.g., arthritis - inflammation of joints)' },
    { rootWord: 'Auto', meaning: 'Self (e.g., autoimmune - immune response against own body)' },
    { rootWord: 'Blast', meaning: 'Embryonic cell or precursor (e.g., blastocyst - embryonic stage)' },
    { rootWord: 'Blephar', meaning: 'Eyelid (e.g., blepharitis - inflammation of eyelids)' },
    { rootWord: 'Bronch', meaning: 'Bronchus (e.g., bronchoscopy - examination of bronchus)' },
    { rootWord: 'Cardio', meaning: 'Heart (e.g., cardiovascular - relating to heart and blood vessels)' },
    { rootWord: 'Cephal', meaning: 'Head (e.g., cephalic - relating to head)' },
    { rootWord: 'Cyst', meaning: 'Bladder or sac (e.g., cystectomy - removal of bladder)' },
    { rootWord: 'Cyt', meaning: 'Cell (e.g., cytology - study of cells)' },
    { rootWord: 'Derm', meaning: 'Skin (e.g., dermatology - study of skin)' },
    { rootWord: 'Encephal', meaning: 'Brain (e.g., encephalitis - inflammation of brain)' },
    { rootWord: 'Gastr', meaning: 'Stomach (e.g., gastritis - inflammation of stomach)' },
    { rootWord: 'Hemat', meaning: 'Blood (e.g., hematoma - collection of blood outside blood vessels)' },
    { rootWord: 'Hepat', meaning: 'Liver (e.g., hepatitis - inflammation of liver)' },
    { rootWord: 'Hyster', meaning: 'Uterus (e.g., hysterectomy - removal of uterus)' },
    { rootWord: 'Leuk', meaning: 'White (e.g., leukemia - cancer of white blood cells)' },
    { rootWord: 'Lip', meaning: 'Fat (e.g., liposuction - removal of fat deposits)' },
    { rootWord: 'Mast', meaning: 'Breast (e.g., mastectomy - removal of breast)' },
    { rootWord: 'My', meaning: 'Muscle (e.g., myocardium - heart muscle)' },
    { rootWord: 'Nephro', meaning: 'Kidney (e.g., nephritis - inflammation of kidneys)' },
    { rootWord: 'Neur', meaning: 'Nerve (e.g., neurology - study of nervous system)' },
    { rootWord: 'Ocul', meaning: 'Eye (e.g., oculist - eye doctor)' },
    { rootWord: 'Onc', meaning: 'Tumor or mass (e.g., oncology - study of tumors)' },
    { rootWord: 'Ophthalm', meaning: 'Eye (e.g., ophthalmology - study of eye diseases)' },
    { rootWord: 'Osteo', meaning: 'Bone (e.g., osteoporosis - porous bones)' },
    { rootWord: 'Oto', meaning: 'Ear (e.g., otitis - inflammation of ear)' },
    { rootWord: 'Path', meaning: 'Disease (e.g., pathology - study of diseases)' },
    { rootWord: 'Ped', meaning: 'Child (e.g., pediatrician - doctor for children)' },
    { rootWord: 'Pneumo', meaning: 'Lung or air (e.g., pneumonia - lung inflammation)' },
    { rootWord: 'Pod', meaning: 'Foot (e.g., podiatrist - foot doctor)' },
    { rootWord: 'Proct', meaning: 'Rectum or anus (e.g., proctology - study of rectum and anus)' },
    { rootWord: 'Pulmo', meaning: 'Lung (e.g., pulmonary - relating to lungs)' },
    { rootWord: 'Rhino', meaning: 'Nose (e.g., rhinoplasty - nose surgery)' },
    { rootWord: 'Somat', meaning: 'Body (e.g., somatic - relating to body)' },
    { rootWord: 'Stomat', meaning: 'Mouth (e.g., stomatitis - inflammation of mouth)' },
    { rootWord: 'Thorac', meaning: 'Chest (e.g., thoracic - relating to chest)' },
    { rootWord: 'Thromb', meaning: 'Clot (e.g., thrombosis - blood clot)' },
    { rootWord: 'Thyr', meaning: 'Thyroid (e.g., thyroiditis - inflammation of thyroid)' },
    { rootWord: 'Trache', meaning: 'Trachea (e.g., tracheostomy - surgical creation of an opening in trachea)' },
    { rootWord: 'Uro', meaning: 'Urine or urinary tract (e.g., urology - study of urinary system)' },
    { rootWord: 'Vas', meaning: 'Vessel (e.g., vasectomy - removal of vessel)' },
    { rootWord: 'Ven', meaning: 'Vein (e.g., venous - relating to veins)' },
    { rootWord: 'Ventricul', meaning: 'Cavity or ventricle (e.g., ventriculitis - inflammation of ventricle)' },
    { rootWord: 'Vesic', meaning: 'Bladder or blister (e.g., vesicle - small blister)' },
    { rootWord: 'Viscer', meaning: 'Internal organs (e.g., visceral - relating to internal organs)' }
  ];

  const handleRootWordClick = (rootWord) => {
    setSelectedMedical(rootWord);
    if (window.innerWidth <= 768) {
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      
        <NavBar />
        <Container>
      <div className="p-4 p-md-5 mb-4 rounded text-body-emphasis bg-body-secondary SignInBackgroundMedicalTerm">
        <Row className="gx-0">
          <Col lg={6} className="px-0">
          <div className="transparent-box">
            <h1 className="display-3">Medical Terminology</h1>
            </div>
          </Col>
        </Row>
      </div>
      
      <Row className="mb-4">
        <Col md={6}>
          <Card className="border rounded h-md-250 position-relative">
            <Card.Body className="d-flex flex-column position-static">
              <Card.Title className='display-6'>Top Terms</Card.Title>
              <Card.Text>
This section provides easy access to key medical terms, arranged alphabetically. Each term is accompanied by a definition to help in understanding its meaning in the medical field.</Card.Text>
<Card.Text style={{fontSize: '12px'}}><u>Click box to navigate</u> </Card.Text>
              <Button variant="link" className="btn btn-link stretched-link" onClick={() => scrollToSection(topTermsRef)}></Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="border rounded h-md-250 position-relative">
            <Card.Body className="d-flex flex-column position-static">
              <Card.Title className='display-6'>Top Prefixes</Card.Title>
              <Card.Text>Each prefix in this section is accompanied by a definition of its meaning. Users can reference this section to better understand medical terms encountered in textbooks, lectures, medical records, or conversations with healthcare professionals.</Card.Text>
              <Card.Text style={{fontSize: '12px'}}><u>Click box to navigate</u> </Card.Text>
              <Button variant="link" className="btn btn-link stretched-link" onClick={() => scrollToSection(topPrefixesRef)}></Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={6}>
          <Card className="border rounded h-md-250 position-relative">
            <Card.Body className="d-flex flex-column position-static">
              <Card.Title className='display-6'>Top Acronyms</Card.Title>
              <Card.Text>This section presents acronyms commonly encountered in various medical contexts, such as diagnoses, treatments, medical organizations, procedures, and healthcare regulations. </Card.Text>
              <Card.Text style={{fontSize: '12px'}}><u>Click box to navigate</u> </Card.Text>
              <Button variant="link" className="btn btn-link stretched-link" onClick={() => scrollToSection(topAcronymsRef)}></Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="border rounded h-md-250 position-relative">
            <Card.Body className="d-flex flex-column position-static">
              <Card.Title className='display-6'>Top Root Words</Card.Title>
              <Card.Text>In this section, each root word is accompanied by a definition of its meaning in medical terminology.</Card.Text>
              <Card.Text style={{fontSize: '12px'}}><u>Click box to navigate</u> </Card.Text>
              <Button variant="link" className="btn btn-link stretched-link" onClick={() => scrollToSection(topRootWordsRef)}></Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
<h1 className="text-center mb-5 divMarginBreak underline" style={{ textDecoration: "underline" }} ref={topTermsRef}>Terms</h1>
   {/* Modal for mobile versions */}
        {window.innerWidth <= 768 && (
          <Modal show={isModalOpen} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>
                {selectedTerm?.term || selectedAcronym?.acronym || selectedPrefix?.prefix || selectedRootWord?.rootWord}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedTerm?.meaning || selectedAcronym?.meaning || selectedPrefix?.meaning || selectedRootWord?.meaning}
            </Modal.Body>
          </Modal>
        )}
      <Row className='divMargin'>
        <Col md={6}>
          <ListGroup>
            {terminologyList.map((item, index) => (
              <ListGroup.Item
                key={index}
                action
                onClick={() => handleTermClick(item)}
                active={selectedTerm && selectedTerm.term === item.term}
              >
                {item.term}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col md={6}>
          <div className="meaning-container medical-answers">
            {selectedTerm && (
              <div>
                <h2>{selectedTerm.term}</h2>
                <p>{selectedTerm.meaning}</p>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <h1 className="text-center mb-5 divMarginBreak underline" style={{ textDecoration: "underline" }} ref={topPrefixesRef}>Prefixes</h1>

    
    <Row className='divMargin' >
        <Col md={6}>
          <ListGroup>
            {prefixList.map((item, index) => (
              <ListGroup.Item
                key={index}
                action
                onClick={() => handlePrefixClick(item)}
                active={selectedPrefix && selectedPrefix.prefix === item.prefix}
              >
                {item.prefix}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col md={6}>
          <div className="meaning-container medical-answers">
            {selectedPrefix && (
              <div>
                <h2>{selectedPrefix.prefix}</h2>
                <p>{selectedPrefix.meaning}</p>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <h1 className="text-center mb-5 divMarginBreak underline" style={{ textDecoration: "underline" }} ref={topAcronymsRef}>Acronyms</h1>

      <Row className='divMargin'>
        <Col md={6}>
          <ListGroup>
            {acronymList.map((item, index) => (
              <ListGroup.Item
                key={index}
                action
                onClick={() => handleAcronymClick(item)}
                active={selectedAcronym && selectedAcronym.acronym === item.acronym}
              >
                {item.acronym}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col md={6}>
          <div className="meaning-container medical-answers">
            {selectedAcronym && (
              <div>
                <h2>{selectedAcronym.acronym}</h2>
                <p>{selectedAcronym.meaning}</p>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <h1 className="text-center mb-5 divMarginBreak underline" style={{ textDecoration: "underline" }} ref={topRootWordsRef}>Root Words</h1>

<Row className='divMargin'>
  <Col md={6}>
    <ListGroup>
      {medicalRootWordsList.map((item, index) => (
       <ListGroup.Item
       key={index}
       action
       onClick={() => handleRootWordClick(item)}
       active={selectedMedical && selectedMedical.rootWord === item.rootWord}
     >
       {item.rootWord}
     </ListGroup.Item>
      ))}
    </ListGroup>
  </Col>
  <Col md={6}>
    <div className="meaning-container medical-answers">
      {selectedMedical && (
        <div>
          <h2>{selectedMedical.rootWord}</h2>
          <p>{selectedMedical.meaning}</p>
        </div>
      )}
      
    </div>
  </Col>
</Row>

    </Container>
    <div style={{ textAlign: "center", marginTop: "50px" }}>
    <a href="#" className="top-of-page-link" style={{alignItems: 'center', fontSize: '23px'}}>Back to Top</a> {/* Link to the top of the page */}
    </div>
        {/* Footer */}
      <footer style={{marginTop:'5vh'}}>
    <Footer />
    </footer>
</div>
    
    
  )
}

export default MedicalTerminologyList