import React, { useState, useRef } from 'react';
import { Nav, Navbar, Container, Dropdown } from 'react-bootstrap';
import { motion } from 'framer-motion';
import '../css/master.css';

const NavBarCategories = () => {
  const [showMedical, setShowMedical] = useState(false);
  const [showClinical, setShowClinical] = useState(false);
  const [showProfessional, setShowProfessional] = useState(false);
  const [showPractice, setShowPractice] = useState(false);

  const timeoutRefs = {
    medical: useRef(null),
    clinical: useRef(null),
    professional: useRef(null),
    practice: useRef(null),
  };

  const handleMouseEnter = (setter, ref) => {
    if (timeoutRefs[ref].current) {
      clearTimeout(timeoutRefs[ref].current);
      timeoutRefs[ref].current = null;
    }
    setter(true);
  };

  const handleMouseLeave = (setter, ref) => {
    timeoutRefs[ref].current = setTimeout(() => {
      setter(false);
    }, 200); // Adjust as needed
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -10 },
    visible: { opacity: 1, x: 0 },
  };

  const menuVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.1, // Adjust this for the desired stagger effect between items
      },
    },
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Nav className="mx-auto">
          <Dropdown
            onMouseEnter={() => handleMouseEnter(setShowMedical, 'medical')}
            onMouseLeave={() => handleMouseLeave(setShowMedical, 'medical')}
            show={showMedical}
          >
            <Dropdown.Toggle variant="dark" id="dropdown-medical">
              Medical Sciences
            </Dropdown.Toggle>

            <motion.div
              initial="hidden"
              animate={showMedical ? "visible" : "hidden"}
              variants={menuVariants}
            >
              <Dropdown.Menu className="animated-dropdown">
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/anatomy">Anatomy</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/histology">Histology</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/biochemistry">Biochemistry</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/pharmacology">Pharmacology</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/microbiology">Microbiology</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/immunology">Immunology</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/genetics">Genetics</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/embryology">Embryology</Dropdown.Item>
                </motion.div>
              </Dropdown.Menu>
            </motion.div>
          </Dropdown>

          <Dropdown
            onMouseEnter={() => handleMouseEnter(setShowClinical, 'clinical')}
            onMouseLeave={() => handleMouseLeave(setShowClinical, 'clinical')}
            show={showClinical}
          >
            <Dropdown.Toggle variant="dark" id="dropdown-clinical">
              Clinical Sciences
            </Dropdown.Toggle>

            <motion.div
              initial="hidden"
              animate={showClinical ? "visible" : "hidden"}
              variants={menuVariants}
            >
              <Dropdown.Menu className="animated-dropdown">
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/internal-medicine">Internal Medicine</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/surgery">Surgery</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/pediatrics">Pediatrics</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/obstetrics-and-gynecology">Obstetrics and Gynecology</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/psychiatry">Psychiatry</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/neurology">Neurology</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/family-medicine">Family Medicine</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/radiology">Radiology</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/emergency-medicine">Emergency Medicine</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/anesthesiology">Anesthesiology</Dropdown.Item>
                </motion.div>
              </Dropdown.Menu>
            </motion.div>
          </Dropdown>

          <Dropdown
            onMouseEnter={() => handleMouseEnter(setShowProfessional, 'professional')}
            onMouseLeave={() => handleMouseLeave(setShowProfessional, 'professional')}
            show={showProfessional}
          >
            <Dropdown.Toggle variant="dark" id="dropdown-professional">
              Professional Development
            </Dropdown.Toggle>

            <motion.div
              initial="hidden"
              animate={showProfessional ? "visible" : "hidden"}
              variants={menuVariants}
            >
              <Dropdown.Menu className="animated-dropdown">
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/medical-ethics">Medical Ethics</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/medical-jurisprudence">Medical Jurisprudence</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/communication-skills">Communication Skills</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/examination-skills">Examination Skills</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/clinical-reasoning">Clinical Reasoning</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/medical-documentation">Medical Documentation</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/professionalism">Professionalism</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/cultural-competence">Cultural Competence</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/leadership">Leadership</Dropdown.Item>
                </motion.div>
              </Dropdown.Menu>
            </motion.div>
          </Dropdown>

          <Dropdown
  onMouseEnter={() => handleMouseEnter(setShowPractice, 'practice')}
  onMouseLeave={() => handleMouseLeave(setShowPractice, 'practice')}
  show={showPractice}
>
            <Dropdown.Toggle variant="dark" id="dropdown-practice">
              Clinical Practice and Procedures
            </Dropdown.Toggle>

            <motion.div
              initial="hidden"
              animate={showPractice ? "visible" : "hidden"}
              variants={menuVariants}
            >
              <Dropdown.Menu className="animated-dropdown">
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/clinical-skills">Clinical Skills</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/patient-history">Patient History</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/clinical-procedures">Clinical Procedures</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/diagnostic-techniques">Diagnostic Techniques</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/surgical-techniques">Surgical Techniques</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/electronic-health-records">Electronic Health Records</Dropdown.Item>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Dropdown.Item href="/interprofessional-collaboration">Interprofessional Collaboration</Dropdown.Item>
                </motion.div>
              </Dropdown.Menu>
            </motion.div>
          </Dropdown>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default NavBarCategories;
