import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import NavBar from '../../../components/NavBar';
import NavBarCategories from '../../../components/NavBar-Categories';
import Sidebar from '../../../components/Sidebar';
import Footer from '../../../components/Footer';
import SidebarResource from '../../../components/Sidebar-Resource';

const Pediatrics = () => {
  return (
    <div >
    <NavBar />
    <div className='sticky-nav-categories'>
      <NavBarCategories />
      </div>
    <div className=" pt-3 my-5 d-flex">
      {/* Sidebar */}
      <Sidebar pageTitle="Pediatrics" />
      {/* Main Content */}
      <Container>
        <Row>
          <Col lg={12}>
          <div>
              <div>
  <h1 className="display-4 fw-bold text-body-emphasis text-center">Pediatrics</h1>
</div>


<section id="introduction">
    <h2>Introduction</h2>
    <p>
    Pediatrics, the branch of medicine focused on the health and medical care of infants, children, and adolescents, embodies the essence of compassionate and comprehensive healthcare for the younger population. From addressing developmental milestones to managing acute and chronic illnesses unique to childhood, pediatrics plays a crucial role in safeguarding the well-being and future of our youngest patients.</p>
</section>
<section id="key-areas-of-study">
    <h2>Key Areas of Study</h2>
    <ul>
        <li>Growth and Development</li>
        <li>Childhood Diseases and Conditions</li>
        <li>Immunizations and Preventive Care</li>
        <li>Child Psychology and Behavior</li>
        <li>Neonatology</li>
        <li>Pediatric Emergency Medicine</li>
        <li>Adolescent Medicine</li>
    </ul>
</section>
<section id="growth-and-development">
    <h1>Growth and Development:</h1>

    <p>Growth and development are fundamental aspects of pediatric medicine, encompassing the physical, cognitive, emotional, and social changes that occur from infancy through adolescence. Understanding the trajectory of normal growth and development enables pediatricians to monitor milestones, detect deviations, and provide interventions to support optimal health and well-being.</p>
</section>
<section id="key-concepts-growth-and-development">
    <h2>Key Concepts:</h2>

<ul>
    <li><strong>Developmental Milestones:</strong> Pediatricians track developmental milestones such as motor skills, language acquisition, and social interactions to assess a child's progress and identify potential delays or disorders.</li>
    <li><strong>Growth Charts:</strong> Growth charts provide standardized references for assessing a child's physical growth, including weight, length/height, and head circumference. These charts allow pediatricians to monitor growth trends and identify abnormalities.</li>
    <li><strong>Early Intervention:</strong> Early identification of developmental delays or disorders allows for timely interventions and support services to optimize a child's development and minimize long-term impacts.</li>
</ul>
</section>
<section id="developmental-milestones">
<h3>Developmental Milestones:</h3>
<ul>
<li><strong>Motor Skills:</strong> Infants gradually develop motor skills such as lifting their head, rolling over, crawling, walking, and fine motor skills like grasping objects and drawing. Delays in achieving motor milestones may indicate neurological or musculoskeletal issues.</li>
<li><strong>Language Acquisition:</strong> Children progress from babbling and cooing to forming words, phrases, and sentences. Language milestones include understanding words, using gestures, and developing expressive and receptive language skills.</li>
<li><strong>Social and Emotional Development:</strong> Social milestones involve bonding with caregivers, engaging in play, demonstrating empathy, and forming friendships. Emotional milestones include regulating emotions, expressing feelings, and developing a sense of identity and self-esteem.</li>
</ul>
</section>
<section id="growth-charts">
<h3>Growth Charts:</h3>
<ul>
<li><strong>Weight:</strong> Weight gain is an important indicator of nutritional status and overall health. Growth charts display weight-for-age percentiles, allowing pediatricians to assess growth patterns and detect abnormalities such as failure to thrive or obesity.</li>
<li><strong>Length/Height:</strong> Length (for infants) and height (for older children) are markers of linear growth and skeletal development. Growth charts provide height-for-age percentiles, aiding in the evaluation of growth velocity and potential growth disorders.</li>
<li><strong>Head Circumference:</strong> Head circumference reflects brain growth and development. Abnormalities in head circumference may indicate neurological conditions such as hydrocephalus or microcephaly.</li>
</ul>
</section>
<section id="early-intervention">
<h3>Early Intervention:</h3>
<ul>
<li><strong>Screening and Assessment:</strong> Pediatricians conduct developmental screenings and assessments at routine well-child visits to identify children at risk for developmental delays or disorders. Screening tools such as the Ages and Stages Questionnaires (ASQ) and the Denver Developmental Screening Test (DDST) help identify areas of concern.</li>
<li><strong>Referral to Specialists:</strong> Children identified with developmental delays may be referred to specialists such as developmental pediatricians, pediatric neurologists, or early intervention programs for further evaluation and intervention services.</li>
<li><strong>Family Support and Education:</strong> Early intervention programs provide support and resources to families, empowering them to facilitate their child's development through therapeutic interventions, educational strategies, and community resources.</li>
</ul>
</section>
<section id="childhood-diseases-and-conditions">
    <h1>Childhood Diseases and Conditions:</h1>
    <p>Childhood diseases and conditions encompass a wide range of acute and chronic illnesses that affect infants, children, and adolescents. Pediatricians play a central role in diagnosing, treating, and managing these conditions, ensuring the health and well-being of their young patients.</p>
</section>
<section id="key-concepts-childhood-diseases-and-conditions">
    <h2>Key Concepts:</h2>
    <ul>
        <li>Common Pediatric Illnesses</li>
        <li>Vaccination Schedules</li>
        <li>Infectious Diseases</li>
        <li>Chronic Conditions</li>
        <li>Allergies and Immunodeficiencies</li>
        <li>Injury Prevention</li>
        <li>Mental Health Disorders</li>
    </ul>
</section>
<section id="common-pediatric-illnesses">
    <h3>Common Pediatric Illnesses:</h3>
    <p>Children are susceptible to a variety of common illnesses, ranging from minor infections to more serious conditions. Pediatricians must be adept at recognizing and managing these illnesses to provide appropriate care and support for their patients.</p>
    <ul>
        <li><strong>Respiratory Infections:</strong> Common respiratory infections in children include the common cold, influenza (flu), bronchitis, pneumonia, and croup.</li>
        <li><strong>Gastrointestinal Disorders:</strong> Gastrointestinal conditions such as gastroenteritis (stomach flu), diarrhea, constipation, and gastroesophageal reflux disease (GERD) are common in children.</li>
        <li><strong>Skin Conditions:</strong> Skin conditions such as eczema, diaper rash, impetigo, and fungal infections are prevalent in pediatric populations.</li>
        <li><strong>Ear Infections:</strong> Otitis media (middle ear infection) and otitis externa (swimmer's ear) are common ear infections seen in children.</li>
        <li><strong>Childhood Vaccines:</strong> Pediatricians follow vaccination schedules recommended by organizations like the Centers for Disease Control and Prevention (CDC) and the American Academy of Pediatrics (AAP) to protect children from vaccine-preventable diseases such as measles, mumps, rubella, pertussis, polio, and influenza.</li>
    </ul>
</section>
<section id="infectious-diseases">
    <h3>Infectious Diseases:</h3>
    <p>Infectious diseases pose significant health risks to children, necessitating vigilant monitoring, prevention, and treatment strategies. Pediatricians play a critical role in diagnosing and managing infectious diseases to minimize their spread and impact on pediatric populations.</p>
    <ul>
        <li><strong>Bacterial Infections:</strong> Bacterial infections such as strep throat, urinary tract infections (UTIs), pneumonia, and skin infections require appropriate antibiotic treatment.</li>
        <li><strong>Viral Infections:</strong> Viral infections such as influenza (flu), respiratory syncytial virus (RSV), rotavirus, and herpes simplex virus (HSV) present unique challenges in management and prevention.</li>
        <li><strong>Parasitic Infections:</strong> Parasitic infections such as giardiasis, pinworms, and lice infestations are common in pediatric populations, requiring specific treatment and preventive measures.</li>
        <li><strong>Fungal Infections:</strong> Fungal infections such as ringworm, candidiasis (yeast infection), and athlete's foot are prevalent in children, particularly in warm and humid environments.</li>
        <li><strong>Preventive Measures:</strong> Pediatricians emphasize the importance of preventive measures such as hand hygiene, vaccination, proper nutrition, and environmental sanitation to reduce the risk of infectious diseases in children.</li>
    </ul>
</section>
<section id="chronic-conditions">
    <h3>Chronic Conditions:</h3>
    <p>Chronic conditions in children pose ongoing challenges that require comprehensive management and support from pediatric healthcare providers. These conditions may impact various aspects of a child's life, including physical health, emotional well-being, and social functioning.</p>
    <ul>
        <li><strong>Asthma:</strong> Asthma is a common chronic respiratory condition characterized by airway inflammation, bronchoconstriction, and respiratory symptoms such as wheezing, coughing, and shortness of breath.</li>
        <li><strong>Diabetes:</strong> Type 1 diabetes and type 2 diabetes are chronic metabolic disorders that require ongoing monitoring, insulin therapy (for type 1 diabetes), dietary management, and lifestyle modifications.</li>
        <li><strong>Allergic Disorders:</strong> Allergic disorders such as allergic rhinitis (hay fever), food allergies, asthma, eczema, and anaphylaxis are prevalent in pediatric populations and require allergen avoidance, medication management, and emergency preparedness.</li>
        <li><strong>Neurodevelopmental Disorders:</strong> Neurodevelopmental disorders such as autism spectrum disorder (ASD), attention-deficit/hyperactivity disorder (ADHD), and intellectual disabilities require comprehensive evaluation, early intervention, and tailored support services.</li>
        <li><strong>Genetic Disorders:</strong> Genetic disorders such as Down syndrome, cystic fibrosis, and muscular dystrophy present unique challenges that require multidisciplinary care, genetic counseling, and family support.</li>
    </ul>
</section>
<section id="injury-prevention">
    <h3>Injury Prevention:</h3>
    <p>Preventing injuries in children is a crucial aspect of pediatric healthcare, encompassing strategies to mitigate risks associated with accidents, falls, sports-related injuries, and environmental hazards. Pediatricians advocate for injury prevention through education, counseling, and community-based interventions.</p>
    <ul>
        <li><strong>Childproofing:</strong> Pediatricians educate parents and caregivers about childproofing measures to create safe home environments, including securing furniture, installing safety gates, and keeping hazardous substances out of reach.</li>
        <li><strong>Sports Safety:</strong> Pediatricians promote sports safety practices such as proper equipment use, warm-up exercises, hydration, and injury prevention techniques to reduce the risk of sports-related injuries in children and adolescents.</li>
        <li><strong>Seatbelt and Car Seat Safety:</strong> Pediatricians emphasize the importance of proper seatbelt and car seat use to protect children from injuries in motor vehicle accidents. They provide guidance on selecting and installing appropriate car seats based on a child's age, weight, and height.</li>
        <li><strong>Water Safety:</strong> Pediatricians educate parents and caregivers about water safety practices, including supervision, swimming lessons, lifeguard presence, and barriers such as fences around pools to prevent drowning incidents.</li>
        <li><strong>Poison Prevention:</strong> Pediatricians provide guidance on poison prevention measures, including safe storage of household chemicals, medications, and other toxic substances, as well as education on identifying and responding to poison exposures.</li>
    </ul>
</section>
<section id="mental-health-disorders">
    <h3>Mental Health Disorders:</h3>
    <p>Mental health disorders in children require prompt recognition, evaluation, and intervention to optimize outcomes and promote overall well-being. Pediatricians play a vital role in screening, diagnosing, and managing mental health conditions, collaborating with mental health professionals to provide comprehensive care.</p>
    <ul>
        <li><strong>Depression and Anxiety:</strong> Depression and anxiety disorders are common mental health conditions in children and adolescents, characterized by persistent feelings of sadness, worry, and fear that interfere with daily functioning.</li>
        <li><strong>Attention-Deficit/Hyperactivity Disorder (ADHD):</strong> ADHD is a neurodevelopmental disorder characterized by inattention, hyperactivity, and impulsivity, often leading to academic, social, and behavioral challenges.</li>
        <li><strong>Autism Spectrum Disorder (ASD):</strong> ASD is a complex neurodevelopmental disorder characterized by difficulties in social communication and interaction, restricted interests, and repetitive behaviors. Early diagnosis and intervention are critical for improving outcomes.</li>
        <li><strong>Eating Disorders:</strong> Eating disorders such as anorexia nervosa, bulimia nervosa, and binge-eating disorder may occur in childhood and adolescence, requiring multidisciplinary treatment approaches involving medical, nutritional, and psychological interventions.</li>
        <li><strong>Behavioral Disorders:</strong> Behavioral disorders such as oppositional defiant disorder (ODD) and conduct disorder (CD) are characterized by disruptive and antisocial behaviors that may impair social relationships, academic performance, and family functioning.</li>
    </ul>
</section>
</div>
          </Col>
        </Row>
      </Container>
      <SidebarResource currentPage="pediatrics" />
    </div>
    <footer id='footer'>
        {/* Footer */}
      <Footer />
      </footer>
  </div>
  )
}

export default Pediatrics
