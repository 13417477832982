import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import NavBar from '../../../components/NavBar';
import NavBarCategories from '../../../components/NavBar-Categories';
import Sidebar from '../../../components/Sidebar';
import Footer from '../../../components/Footer';
import SidebarResource from '../../../components/Sidebar-Resource';

const FamilyMedicine = () => {
  return (
    <div>
      <NavBar />
      <div className='sticky-nav-categories'>
        <NavBarCategories />
      </div>
      <div className="pt-3 my-5 d-flex">
        {/* Sidebar */}
        <Sidebar pageTitle="FamilyMedicine" />
        {/* Main Content */}
        <Container>
          <Row>
            <Col lg={12}>
              <div>
                <div>
                  <h1 className="display-4 fw-bold text-body-emphasis text-center">Family Medicine</h1>
                </div>
                <header id="the-nervous-system">
                </header>

                <section id="introduction">
                  <h2>Introduction</h2>
                  <p>
                    Family Medicine, a comprehensive medical specialty focused on providing primary healthcare to individuals and families across the lifespan, plays a pivotal role in promoting health, preventing disease, and managing chronic conditions within the context of the family unit. From newborn care to geriatric medicine, family physicians serve as trusted partners in addressing the diverse healthcare needs of patients within their communities, emphasizing continuity of care, patient-centeredness, and holistic wellness.
                  </p>
                </section>

                <section id="key-areas-of-study">
                  <h2>Key Areas of Study</h2>
                  <ul>
                    <li>Primary Care Principles</li>
                    <li>Comprehensive Patient Evaluation</li>
                    <li>Disease Prevention and Health Promotion</li>
                    <li>Chronic Disease Management</li>
                    <li>Family-Centered Care</li>
                    <li>Community Health and Advocacy</li>
                    <li>Interdisciplinary Collaboration</li>
                  </ul>
                </section>

                <section id="primary-care-principles">
                  <h1>Primary Care Principles:</h1>
                  <p>Family Medicine encompasses the fundamental principles of primary care, emphasizing the provision of accessible, comprehensive, and coordinated healthcare services to individuals and families across the lifespan.</p>
                </section>

                <section id="key-concepts">
                  <h2>Key Concepts:</h2>
                  <ul>
                    <li><strong>Patient-Centered Care:</strong> Family physicians adopt a patient-centered approach, prioritizing the needs, preferences, and values of patients in their healthcare decision-making process. This involves fostering open communication, building therapeutic relationships, and involving patients in shared decision-making.</li>
                    <li><strong>Continuity of Care:</strong> Family physicians provide longitudinal care, establishing enduring relationships with patients and families over time. This continuity of care facilitates comprehensive health assessment, ongoing management of chronic conditions, and preventive healthcare interventions.</li>
                    <li><strong>Comprehensive Healthcare Services:</strong> Family physicians deliver a broad spectrum of healthcare services, including preventive care, acute illness management, chronic disease management, behavioral health services, and minor surgical procedures. This comprehensive approach addresses the diverse healthcare needs of patients within the context of their families and communities.</li>
                    <li><strong>Population Health and Public Health:</strong> Family physicians engage in population health and public health initiatives aimed at promoting health equity, preventing disease, and addressing social determinants of health within their communities. This involves collaborating with public health agencies, community organizations, and interdisciplinary teams to implement evidence-based interventions and advocate for health policy changes.</li>
                  </ul>
                </section>

                <section id="patient-centered-care">
                  <h3>Patient-Centered Care:</h3>
                  <ul>
                    <li><strong>Open Communication:</strong> Family physicians establish trustful relationships with patients by actively listening to their concerns, providing empathetic support, and addressing their questions and preferences.</li>
                    <li><strong>Shared Decision-Making:</strong> Involving patients in healthcare decisions empowers them to participate actively in their own care, leading to greater satisfaction, adherence to treatment plans, and improved health outcomes.</li>
                    <li><strong>Cultural Competence:</strong> Recognizing and respecting the cultural diversity of patients is essential for delivering culturally sensitive care and addressing the unique healthcare needs of individuals and families from different backgrounds.</li>
                  </ul>
                </section>

                <section id="continuity-of-care">
                  <h3>Continuity of Care:</h3>
                  <ul>
                    <li><strong>Longitudinal Relationships:</strong> Building enduring relationships with patients and families allows family physicians to gain comprehensive understanding of their medical history, preferences, values, and social context.</li>
                    <li><strong>Comprehensive Health Assessment:</strong> Continuity of care enables family physicians to conduct thorough health assessments, identify risk factors, and implement preventive interventions tailored to individual and family needs.</li>
                    <li><strong>Chronic Disease Management:</strong> Family physicians play a central role in managing chronic conditions, providing ongoing support, education, and monitoring to optimize patient outcomes and quality of life.</li>
                  </ul>
                </section>

                <section id="comprehensive-healthcare-services">
                  <h3>Comprehensive Healthcare Services:</h3>
                  <ul>
                    <li><strong>Preventive Care:</strong> Family physicians deliver preventive services such as immunizations, health screenings, counseling on healthy lifestyle behaviors, and preventive health assessments tailored to age, gender, and risk factors.</li>
                    <li><strong>Acute Illness Management:</strong> Family physicians diagnose and manage acute medical conditions, including infections, injuries, and minor emergencies, providing timely interventions and referrals when necessary.</li>
                    <li><strong>Chronic Disease Management:</strong> Family physicians oversee the management of chronic conditions such as diabetes, hypertension, asthma, and depression, focusing on patient education, lifestyle modifications, medication management, and monitoring of disease progression.</li>
                    <li><strong>Behavioral Health Services:</strong> Family physicians integrate behavioral health services into primary care settings, addressing mental health issues such as depression, anxiety, substance abuse, and stress-related disorders through counseling, medication management, and referral to mental health specialists.</li>
                    <li><strong>Minor Surgical Procedures:</strong> Family physicians perform minor surgical procedures such as laceration repair, skin biopsies, joint injections, and contraceptive implant insertion, enhancing access to essential healthcare services in primary care settings.</li>
                  </ul>
                </section>

                <section id="population-health-and-public-health">
                  <h3>Population Health and Public Health:</h3>
                  <ul>
                    <li><strong>Health Promotion:</strong> Family physicians engage in health promotion activities such as community education campaigns, lifestyle modification counseling, and advocacy for healthy public policies to prevent disease and promote wellness.</li>
                    <li><strong>Disease Prevention:</strong> Family physicians implement evidence-based strategies for disease prevention, including vaccination programs, screening tests for early detection of diseases, and counseling on risk factor reduction.</li>
                    <li><strong>Social Determinants of Health:</strong> Addressing social determinants of health such as socioeconomic status, housing stability, food security, and access to healthcare services is integral to reducing health disparities and improving health outcomes in underserved populations.</li>
                    <li><strong>Interdisciplinary Collaboration:</strong> Family physicians collaborate with interdisciplinary teams comprising nurses, nurse practitioners, physician assistants, social workers, pharmacists, and community health workers to deliver comprehensive care, coordinate services, and address the multifaceted needs of patients and families.</li>
                  </ul>
                </section>
              </div>
            </Col>
          </Row>
        </Container>
        <SidebarResource currentPage="familyMedicine" />
      </div>
      <footer id='footer'>
        {/* Footer */}
        <Footer />
      </footer>
    </div>
  )
}

export default FamilyMedicine;
