import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { motion } from 'framer-motion';
import '/MedBud/medbud/src/css/master.css';

const CardWithVideo = ({ title, videoRef, handleMouseEnter, handleMouseLeave, videoSrc, darkMode, link }) => {
  const [isHovered, setIsHovered] = useState(false);

  // Animation variants for ease-in effect
  const cardVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5, ease: 'easeIn' } },
  };

  return (
    <Col md={4}>
      <motion.div variants={cardVariants} initial="hidden" animate="visible" whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}>
        <div className="card-link">
          <a href={link} rel="noopener noreferrer" className="card-link-anchor">
            <Card
              className={`w-200 h-50 ${darkMode ? 'card-dark' : 'card-light'}`}
              style={{ marginBottom: '30px', borderRadius: '30px' }}
              onMouseEnter={() => {
                setIsHovered(true);
                handleMouseEnter(title);
              }}
              onMouseLeave={() => {
                setIsHovered(false);
                handleMouseLeave(title);
              }}
            >
              <Card.Body>
                <Card.Text>{title}</Card.Text>
              </Card.Body>
              <video ref={videoRef} muted width="100%" height="100%" style={{ cursor: 'pointer' }}>
                <source src={`/videos/${videoSrc}.mp4`} type="video/mp4" />
              </video>
            </Card>
          </a>
        </div>
      </motion.div>
    </Col>
  );
};

const CardList = ({ selectedCategory, videoRefs, handleMouseEnter, handleMouseLeave, darkMode }) => {
  const [cardsData, setCardsData] = useState([]);

  useEffect(() => {
    let newCardsData = [];
    if (selectedCategory === 'Medical Sciences') {
      newCardsData = [
        { title: 'Anatomy', category: 'Medical Sciences', videoSrc: 'Anatomy', link: '/anatomy' },
        { title: 'Histology', category: 'Medical Sciences', videoSrc: 'Histology', link: '/histology' },
        { title: 'Biochemistry', category: 'Medical Sciences', videoSrc: 'Biochemistry', link: '/biochemistry' },
        { title: 'Pharmacology', category: 'Medical Sciences', videoSrc: 'Pharmacology', link: '/pharmacology' },
        { title: 'Microbiology', category: 'Medical Sciences', videoSrc: 'Microbiology', link: '/microbiology' },
        { title: 'Immunology', category: 'Medical Sciences', videoSrc: 'Immunology', link: '/immunology' },
        { title: 'Genetics', category: 'Medical Sciences', videoSrc: 'Genetics', link: '/genetics' },
        { title: 'Embryology', category: 'Medical Sciences', videoSrc: 'Embryology', link: '/embryology' },
      ];
    } else if (selectedCategory === 'Clinical Sciences') {
      newCardsData = [
        { title: 'Internal Medicine', category: 'Clinical Sciences', videoSrc: 'InternalMedicine', link: '/internal-medicine' },
        { title: 'Surgery', category: 'Clinical Sciences', videoSrc: 'Surgery', link: '/surgery' },
        { title: 'Pediatrics', category: 'Clinical Sciences', videoSrc: 'Pediatrics', link: '/pediatrics' },
        { title: 'OBGYN', category: 'Clinical Sciences', videoSrc: 'ObstetricsandGynecology', link: '/obstetrics-and-gynecology' },
        { title: 'Psychiatry', category: 'Clinical Sciences', videoSrc: 'Psychiatry', link: '/psychiatry' },
        { title: 'Neurology', category: 'Clinical Sciences', videoSrc: 'Neurology', link: '/neurology' },
        { title: 'Family Medicine', category: 'Clinical Sciences', videoSrc: 'FamilyMedicine', link: '/family-medicine' },
        { title: 'Radiology', category: 'Clinical Sciences', videoSrc: 'Radiology', link: '/radiology' },
        { title: 'Emergency Medicine', category: 'Clinical Sciences', videoSrc: 'EmergencyMedicine', link: '/emergency-medicine' },
        { title: 'Anesthesiology', category: 'Clinical Sciences', videoSrc: 'Anesthesiology', link: '/anesthesiology' },
      ];
    } else if (selectedCategory === 'Professional Development and Skills') {
      newCardsData = [
        { title: 'Medical Ethics', category: 'Professional Development and Skills', videoSrc: 'MedicalEthics', link: '/medical-ethics' },
        { title: 'Medical Jurisprudence', category: 'Professional Development and Skills', videoSrc: 'MedicalJurisprudence', link: '/medical-jurisprudence' },
        { title: 'Communication Skills', category: 'Professional Development and Skills', videoSrc: 'CommunicationSkills', link: '/communication-skills' },
        { title: 'Examination Skills', category: 'Professional Development and Skills', videoSrc: 'ExaminationSkills', link: '/examination-skills' },
        { title: 'Clinical Reasoning', category: 'Professional Development and Skills', videoSrc: 'ClinicalReasoning', link: '/clinical-reasoning' },
        { title: 'Medical Documentation', category: 'Professional Development and Skills', videoSrc: 'MedicalDocumentation', link: '/medical-documentation' },
        { title: 'Professionalism', category: 'Professional Development and Skills', videoSrc: 'Professionalism', link: '/professionalism' },
        { title: 'Cultural Competence', category: 'Professional Development and Skills', videoSrc: 'CulturalCompetence', link: '/cultural-competence' },
        { title: 'Leadership', category: 'Professional Development and Skills', videoSrc: 'Leadership', link: '/leadership' },
      ];
    } else if (selectedCategory === 'Clinical Practice and Procedures') {
      newCardsData = [
        { title: 'Clinical Skills', category: 'Clinical Practice and Procedures', videoSrc: 'ClinicalSkills', link: '/clinical-skills' },
        { title: 'Patient History', category: 'Clinical Practice and Procedures', videoSrc: 'PatientHistory', link: '/patient-history' },
        { title: 'Clinical Procedures', category: 'Clinical Practice and Procedures', videoSrc: 'ClinicalProcedures', link: '/clinical-procedures' },
        { title: 'Diagnostic Techniques', category: 'Clinical Practice and Procedures', videoSrc: 'DiagnosticTechniques', link: '/diagnostic-techniques' },
        { title: 'Surgical Techniques', category: 'Clinical Practice and Procedures', videoSrc: 'SurgicalTechniques', link: '/surgical-techniques' },
        { title: 'Electronic Health Records', category: 'Clinical Practice and Procedures', videoSrc: 'ElectronicHealthRecords', link: '/electronic-health-records' },
        { title: 'Interprofessional Collaboration', category: 'Clinical Practice and Procedures', videoSrc: 'InterprofessionalCollaboration', link: '/interprofessional-collaboration' },
      ];
    }

    setCardsData(newCardsData);
  }, [selectedCategory]);

  useEffect(() => {
    // Update videoRefs based on selected category
    cardsData.forEach(({ title }) => {
      if (videoRefs.current[title]) {
        videoRefs.current[title].current.load();
      }
    });
  }, [selectedCategory]);

  return (
    <Row className='divMargin' as='h2'>
      {cardsData.map(({ title, videoSrc, link }, index) => (
        <CardWithVideo
          key={index}
          title={title}
          videoRef={videoRefs.current[title]}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          videoSrc={videoSrc}
          darkMode={darkMode}
          link={link}
        />
      ))}
    </Row>
  );
};

export default CardList;
