import React, { useState, useEffect } from 'react';
import { Container, Nav } from 'react-bootstrap';
import { motion } from 'framer-motion';
import '/MedBud/medbud/src/css/master.css';

const Categories = ({ darkMode, selectedCategory, handleCategorySelect }) => {
  // Animation variants for ease-out effect
  const linkVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: 'easeOut' } }
  };

  return (
    <div className='divMargin'>
      <Container>
        <Nav variant="underline" className="justify-content-center" defaultActiveKey="/home" onSelect={handleCategorySelect}>
          <Nav.Item>
            <motion.div variants={linkVariants} initial="hidden" animate="visible">
              <Nav.Link
                eventKey="Medical Sciences"
                className={`${selectedCategory === 'Medical Sciences' ? (darkMode ? 'selected-category-dark' : 'selected-category-light') : ''}`}
              >
                <h4>Medical Sciences</h4>
              </Nav.Link>
            </motion.div>
          </Nav.Item>

          <Nav.Item>
            <motion.div variants={linkVariants} initial="hidden" animate="visible">
              <Nav.Link
                eventKey="Clinical Sciences"
                className={`${selectedCategory === 'Clinical Sciences' ? (darkMode ? 'selected-category-dark' : 'selected-category-light') : ''}`}
              >
                <h4>Clinical Sciences</h4>
              </Nav.Link>
            </motion.div>
          </Nav.Item>

          <Nav.Item>
            <motion.div variants={linkVariants} initial="hidden" animate="visible">
              <Nav.Link
                eventKey="Professional Development and Skills"
                className={`${selectedCategory === 'Professional Development and Skills' ? (darkMode ? 'selected-category-dark' : 'selected-category-light') : ''}`}
              >
                <h4>Professional Development and Skills</h4>
              </Nav.Link>
            </motion.div>
          </Nav.Item>

          <Nav.Item>
            <motion.div variants={linkVariants} initial="hidden" animate="visible">
              <Nav.Link
                eventKey="Clinical Practice and Procedures"
                className={`${selectedCategory === 'Clinical Practice and Procedures' ? (darkMode ? 'selected-category-dark' : 'selected-category-light') : ''}`}
              >
                <h4>Clinical Practice and Procedures</h4>
              </Nav.Link>
            </motion.div>
          </Nav.Item>
        </Nav>
      </Container>
    </div>
  );
}

export default Categories;
