import React, { useState, useEffect } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import { Container, Nav } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';

export const FAQ = () => {
  const [darkMode, setDarkMode] = useState(false); // Dark Mode
  const [pop, setPop] = useState(false);

  useEffect(() => {
    setPop(true);
  }, []);


  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className={darkMode ? 'dark-mode' : 'light-mode'} style={{ minHeight: '110vh' }} id='app'>
      <NavBar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      <div className={`divMarginBreak ${pop ? 'pop' : ''}`} >
        <Nav className="justify-content-center">
          <h1>Frequently Asked Questions</h1>
        </Nav>
      </div>
      <div className={`divMarginBreak ${pop ? 'pop' : ''}`}>
        <Container>
          <Accordion className={darkMode ? 'accordion-dark' : ''}>
            <Accordion.Item eventKey="0">
              <Accordion.Header><strong>Is this website completely free?</strong></Accordion.Header>
              <Accordion.Body>
                Of course! This was made to provide the most useful information in a simple yet effective way.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header><strong>I encountered an issue, what can I do?</strong></Accordion.Header>
              <Accordion.Body>
                You can navigate to the <a href="/contact">Contact Us</a> webpage and fill in the information along with a message, or message us directly at support@med-bud.net or through our <a href='https://discord.com/invite/8YwYPVYrXd'>Discord</a> Channel!
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header><strong>How do I get started?</strong></Accordion.Header>
              <Accordion.Body>
               To begin, you can explore a variety of options: choose from different topics, access the Question Bank or Question Database, consult the Medical Dictionary, or delve into the Study Tips section. You can also join our <a href='https://discord.com/invite/8YwYPVYrXd'>Discord</a> to gain access to our community of like-minded individuals and stay up to date on the latest news/updates.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header><strong>Can I contribute content to Med-Bud?</strong></Accordion.Header>
              <Accordion.Body>
                <p>
                  Absolutely! We welcome contributions from users who are passionate about sharing their knowledge and experiences in the medical field. Whether you have informative articles, insightful blog posts, or valuable resources to share, we encourage you to contribute to the MedBud community.
                </p>
                <p>
                  To submit your content for consideration, please reach out to us at support@med-bud.net or present your ideas/critiques via our <a href='https://discord.com/invite/8YwYPVYrXd'>Discord</a> channel. Make sure to include a brief overview of your proposed content you wish to add to our community. Our team will review your submission and get back to you as soon as possible.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            {/* <Accordion.Item eventKey="4">
            <Accordion.Header><strong>How do I create an account?</strong></Accordion.Header>
            <Accordion.Body>
              <p>
            Creating an account is a breeze! Follow these simple steps:
            </p>
<p>
1. Find the Sign-Up button: Look for the prominent "Sign-Up" or "Create Account" button, usually located at the top of the page.
</p>
<p>
2. Complete the registration form: Click on the Sign-Up button, and you'll be directed to a registration form. Fill in your email address, choose a strong password, and provide any other necessary information, such as your name or username.
</p>
<p>
3. Verify your email: Once you've submitted the registration form, check your email inbox for a verification message. Click on the verification link to confirm your email address and activate your account. If you don't see the email, remember to check your spam or junk folder.
</p>
<p>
4. Optional: Complete your profile: After verifying your email, you may have the option to enhance your profile by adding a profile picture, bio, or other personal details. This step is entirely up to you but can help personalize your experience on our platform.
</p>
<p>
5. Start exploring: Congratulations! Your account is now ready to use. Dive into our platform, explore its features, and start engaging with our community.
</p>
<p>
6. If you encounter any difficulties during the account creation process or have questions, feel free to reach out to our customer support team. We're here to assist you every step of the way!
</p>
            </Accordion.Body>
          </Accordion.Item>*/}
            <Accordion.Item eventKey="4">
              <Accordion.Header><strong>What features does Med-Bud have?</strong></Accordion.Header>
              <Accordion.Body>
                In addition to a diverse array of subjects that could be covered in Medical School, we offer: 
                <ol>
  <li><strong>AI-Powered Question Bank:</strong> Access our <a href='/question-bank'>Question Bank</a>, which utilizes AI to tailor inquiries to your chosen topic, aiding effective learning. When AI tokens are used up, you can still access a diverse array of questions ranging from easy to hard.Should you exhaust your AI tokens for the month, you can continue to explore a broad spectrum of questions, varying from easy to hard, through our extensive <a href='/question-database'>Question Database.</a></li>
  
  <li><strong>Varied Difficulty Levels:</strong> Our Question Bank includes questions ranging from easy to hard, ensuring a comprehensive study experience. You can customize the number of questions and their difficulty level to match your study preferences and needs.</li>

  <li><strong>Medical Terminology Page:</strong> Explore our comprehensive glossary of medical terms, complete with definitions, pronunciation guides, and usage examples. This resource is invaluable for expanding your medical vocabulary and enhancing your understanding of complex concepts.</li>

  <li><strong>Study Tips Page:</strong> Access expert advice and strategies curated by professionals to optimize your study routine. Whether you're preparing for exams or deepening your understanding of medical concepts, our study tips offer practical guidance for effective learning.</li>
  </ol>
  <strong>Please note:</strong> If you reach your monthly limit for the AI-powered Question Bank, you can still access a wealth of study material through our Question Database, ensuring uninterrupted learning and progress.

              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </div>
      <footer style={{marginTop:'19vh'}}>
        {/* Footer */}
        <Footer />
      </footer>
    </div>
  );
};

export default FAQ;
