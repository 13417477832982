import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NavBar from '../../../components/NavBar';
import NavBarCategories from '../../../components/NavBar-Categories';
import Sidebar from '../../../components/Sidebar';
import Footer from '../../../components/Footer';
import SidebarResource from '../../../components/Sidebar-Resource';

const Psychiatry = () => {
  return (
    <div>
      <NavBar />
      <div className='sticky-nav-categories'>
        <NavBarCategories />
      </div>
      <div className="pt-3 my-5 d-flex">
        {/* Sidebar */}
        <Sidebar pageTitle="Psychiatry" />
        {/* Main Content */}
        <Container>
          <Row>
            <Col lg={12}>
              <div>
                <div>
                  <h1 className="display-4 fw-bold text-body-emphasis text-center">Psychiatry</h1>
                </div>
                <section id='introduction'>
                  <h2>Introduction</h2>
                  <p>
                    Psychiatry, a branch of medicine dedicated to the study, diagnosis, treatment, and prevention of mental disorders, holds a unique place in medical education and practice. It delves into the intricate workings of the human mind, exploring the complexities of thought, emotion, behavior, and neurobiology. Through the lens of psychiatry, medical students gain insights into the interplay between biological, psychological, social, and environmental factors shaping mental health and illness.</p>
                </section>
                <section>
                  <h2 id="key-areas">Key Areas of Study</h2>
                  <ul>
                    <li>Neurobiology of Mental Disorders</li>
                    <li>Psychological Theories and Therapies</li>
                    <li>Diagnostic Classification and Assessment</li>
                    <li>Pharmacotherapy and Psychopharmacology</li>
                    <li>Psychotherapy and Counseling Techniques</li>
                    <li>Community Psychiatry and Public Mental Health</li>
                    <li>Ethical and Legal Considerations</li>
                  </ul>
                </section>
                <section>
                  <h1 id="neurobiology">Neurobiology of Mental Disorders:</h1>
                  <p>The study of the neurobiology of mental disorders involves understanding the underlying brain mechanisms and neural pathways associated with various psychiatric conditions. This includes investigating alterations in neurotransmitter systems, neuroendocrine function, neural circuits, and brain structures implicated in psychiatric disorders such as depression, schizophrenia, bipolar disorder, and anxiety disorders.</p>
                </section>
                <section>
                  <h2 id="key-concepts">Key Concepts:</h2>
                  <ul>
                    <li><strong>Neurotransmitter Systems:</strong> Psychiatry explores the role of neurotransmitters such as serotonin, dopamine, norepinephrine, and gamma-aminobutyric acid (GABA) in the pathophysiology of mental disorders. Dysregulation of these neurotransmitter systems is linked to mood disorders, psychotic disorders, and substance use disorders.</li>
                    <li><strong>Neuroendocrine Function:</strong> The interaction between the central nervous system and the endocrine system is of particular interest in psychiatry. Dysfunctions in the hypothalamic-pituitary-adrenal (HPA) axis and the hypothalamic-pituitary-thyroid (HPT) axis have been implicated in mood disorders, stress-related disorders, and eating disorders.</li>
                    <li><strong>Neural Circuits:</strong> Psychiatric research focuses on elucidating aberrant neural circuits associated with specific symptoms and behaviors observed in mental disorders. For example, alterations in reward pathways, fear circuits, and executive function networks are relevant to addiction, anxiety disorders, and impulse control disorders.</li>
                    <li><strong>Brain Structures:</strong> Structural and functional imaging techniques, such as magnetic resonance imaging (MRI) and positron emission tomography (PET), are utilized to investigate differences in brain structure and function in individuals with psychiatric disorders. Changes in the prefrontal cortex, amygdala, hippocampus, and other brain regions are implicated in psychiatric conditions.</li>
                  </ul>
                </section>
                <section>
                  <h3 id="neurotransmitter">Neurotransmitter Systems:</h3>
                  <ul>
                    <li><strong>Serotonin (5-HT):</strong> Dysregulation of serotonin signaling is associated with mood disorders such as major depressive disorder and anxiety disorders. Selective serotonin reuptake inhibitors (SSRIs) and serotonin-norepinephrine reuptake inhibitors (SNRIs) are commonly used antidepressants targeting serotonin pathways.</li>
                    <li><strong>Dopamine:</strong> Alterations in dopamine neurotransmission are implicated in schizophrenia, bipolar disorder, and substance use disorders. Antipsychotic medications act by modulating dopamine receptors, while drugs of abuse like cocaine and amphetamines exert their effects through dopamine release.</li>
                    <li><strong>Norepinephrine:</strong> Norepinephrine dysregulation is associated with mood disorders and anxiety disorders. Medications such as tricyclic antidepressants (TCAs) and serotonin-norepinephrine reuptake inhibitors (SNRIs) target norepinephrine reuptake to alleviate symptoms.</li>
                    <li><strong>Gamma-Aminobutyric Acid (GABA):</strong> GABAergic dysfunction is implicated in anxiety disorders, epilepsy, and sleep disorders. Medications that enhance GABAergic transmission, such as benzodiazepines and barbiturates, are used as anxiolytics, anticonvulsants, and sedative-hypnotics.</li>
                  </ul>
                </section>
                <section>
                  <h3 id="neural-circuits">Neural Circuits:</h3>
                  <ul>
                    <li><strong>Reward Pathways:</strong> Dysfunction in the mesolimbic dopamine pathway, involving projections from the ventral tegmental area (VTA) to the nucleus accumbens, is implicated in addiction and reward-related behaviors. Drugs of abuse hijack this circuitry, leading to compulsive drug-seeking behavior.</li>
                    <li><strong>Fear Circuits:</strong> The amygdala plays a central role in fear processing and the expression of anxiety. Hyperactivity of the amygdala and impaired regulation by prefrontal cortical regions are observed in anxiety disorders such as post-traumatic stress disorder (PTSD) and phobias.</li>
                    <li><strong>Executive Function Networks:</strong> Prefrontal cortical regions, including the dorsolateral prefrontal cortex (DLPFC) and anterior cingulate cortex (ACC), are involved in cognitive control, decision-making, and emotional regulation. Dysfunction in these regions is implicated in mood disorders, attention-deficit/hyperactivity disorder (ADHD), and impulse control disorders.</li>
                  </ul>
                </section>
                <section>
                  <h3 id="brain-structures">Brain Structures:</h3>
                  <ul>
                    <li><strong>Prefrontal Cortex:</strong> The prefrontal cortex is involved in higher-order cognitive functions, including decision-making, planning, and social behavior. Structural and functional abnormalities in the prefrontal cortex are associated with schizophrenia, depression, and substance use disorders.</li>
                    <li><strong>Amygdala:</strong> The amygdala is critical for processing emotional stimuli and generating fear responses. Hyperactivity of the amygdala is observed in anxiety disorders, while dysfunction in amygdala regulation is implicated in mood disorders and PTSD.</li>
                    <li><strong>Hippocampus:</strong> The hippocampus is involved in memory formation and spatial navigation. Reduced hippocampal volume and impaired neurogenesis are associated with depression, PTSD, and neurodegenerative disorders such as Alzheimer's disease.</li>
                  </ul>
                </section>
                <section id='psychological'>
                  <h1>Psychological Theories and Therapies:</h1>
                  <p>Psychiatry encompasses a diverse array of psychological theories and therapeutic modalities aimed at understanding and treating mental disorders. These theories provide frameworks for conceptualizing psychopathology, while therapeutic approaches offer interventions to alleviate symptoms, enhance coping mechanisms, and promote psychological well-being.</p>
                </section>
                <section>
                  <h2 id="psychological-key-concepts">Key Concepts:</h2>
                  <ul>
                    <li><strong>Psychodynamic Theory:</strong> Developed by Sigmund Freud, psychodynamic theory posits that unconscious conflicts and childhood experiences shape personality development and influence behavior. Psychodynamic therapy aims to explore and resolve unconscious conflicts through techniques such as free association, dream analysis, and transference interpretation.</li>
                    <li><strong>Cognitive-Behavioral Theory:</strong> Cognitive-behavioral theory emphasizes the role of maladaptive thoughts and behaviors in maintaining mental disorders. Cognitive-behavioral therapy (CBT) aims to identify and modify dysfunctional beliefs and behaviors through techniques such as cognitive restructuring, exposure therapy, and behavioral activation.</li>
                    <li><strong>Humanistic-Existential Theory:</strong> Humanistic and existential theories emphasize individual autonomy, personal growth, and the search for meaning in life. Humanistic therapies, such as person-centered therapy and Gestalt therapy, focus on facilitating self-awareness, self-acceptance, and self-actualization.</li>
                    <li><strong>Biopsychosocial Model:</strong> The biopsychosocial model integrates biological, psychological, and social factors in understanding mental health and illness. It acknowledges the complex interplay between genetic predisposition, psychological vulnerabilities, and environmental stressors in shaping an individual's mental well-being.</li>
                  </ul>
                </section>
                <section>
                  <h3 id="psychodynamic-theory">Psychodynamic Theory:</h3>
                  <ul>
                    <li><strong>Unconscious Conflicts:</strong> According to psychodynamic theory, unconscious conflicts stemming from early childhood experiences contribute to the development of mental disorders. These conflicts involve unresolved issues related to instincts, desires, and interpersonal relationships.</li>
                    <li><strong>Defense Mechanisms:</strong> Individuals employ defense mechanisms such as repression, projection, and displacement to cope with anxiety and protect the ego from distressing thoughts and feelings. However, maladaptive use of defense mechanisms can lead to psychological symptoms and interpersonal difficulties.</li>
                    <li><strong>Therapeutic Techniques:</strong> Psychodynamic therapy aims to explore unconscious conflicts and facilitate insight and resolution. Therapists utilize techniques such as free association, dream analysis, and interpretation of transference and resistance to uncover underlying psychological processes.</li>
                  </ul>
                </section>
                <section>
                  <h3 id="cognitive-behavioral-theory">Cognitive-Behavioral Theory:</h3>
                  <ul>
                    <li><strong>Maintaining Factors:</strong> Cognitive-behavioral theory posits that maladaptive thoughts, beliefs, and behaviors perpetuate and exacerbate mental disorders. Common cognitive distortions include black-and-white thinking, catastrophizing, and personalization.</li>
                    <li><strong>Behavioral Experiments:</strong> Cognitive-behavioral therapy involves conducting behavioral experiments to test the validity of negative beliefs and assumptions. Through exposure therapy and behavioral activation, individuals confront feared situations and engage in rewarding activities to challenge and modify dysfunctional patterns.</li>
                    <li><strong>Skill Building:</strong> CBT focuses on teaching individuals coping skills and problem-solving strategies to manage distressing symptoms and improve functioning. Techniques such as relaxation training, assertiveness training, and social skills training are utilized to enhance adaptive coping mechanisms.</li>
                  </ul>
                </section>
                <section>
                  <h3 id="humanistic-existential-theory">Humanistic-Existential Theory:</h3>
                  <ul>
                    <li><strong>Self-Actualization:</strong> Humanistic and existential theories emphasize the innate drive for self-actualization and personal growth. Therapy aims to foster conditions conducive to self-discovery, authenticity, and fulfillment of one's potential.</li>
                    <li><strong>Client-Centered Approach:</strong> Person-centered therapy, developed by Carl Rogers, emphasizes empathy, unconditional positive regard, and genuineness in the therapeutic relationship. The therapist provides a supportive environment for clients to explore their feelings, values, and goals.</li>
                    <li><strong>Existential Themes:</strong> Existential therapy explores existential themes such as freedom, responsibility, and meaninglessness. By confronting existential dilemmas and existential anxiety, individuals can develop a greater sense of purpose and authenticity in life.</li>
                  </ul>
                </section>
                <section>
                  <h1 id="diagnostic">Diagnostic Classification and Assessment:</h1>
                  <p>Diagnostic classification and assessment play pivotal roles in psychiatry, providing systematic frameworks for identifying and categorizing mental disorders. Accurate diagnosis enables clinicians to formulate appropriate treatment plans, monitor treatment progress, and facilitate communication among healthcare providers.</p>
                </section>
                <section>
                  <h2 id="diagnostic-key-concepts">Key Concepts:</h2>
                  <ul>
                    <li><strong>Diagnostic and Statistical Manual of Mental Disorders (DSM):</strong> The DSM is a widely used classification system published by the American Psychiatric Association (APA). It provides criteria for diagnosing mental disorders based on symptom presentation, duration, and functional impairment.</li>
                    <li><strong>International Classification of Diseases (ICD):</strong> The ICD, published by the World Health Organization (WHO), includes a section dedicated to mental and behavioral disorders. It complements the DSM and is utilized for international health statistics and billing purposes.</li>
                    <li><strong>Multiaxial Assessment:</strong> Multiaxial assessment involves evaluating individuals across multiple domains, including clinical syndromes, personality traits, medical conditions, psychosocial stressors, and level of functioning. This comprehensive approach provides a holistic understanding of the individual's mental health status.</li>
                    <li><strong>Structured Clinical Interviews:</strong> Structured clinical interviews, such as the Structured Clinical Interview for DSM Disorders (SCID), are standardized assessment tools used to obtain diagnostic information from patients. These interviews follow a structured format and ensure reliability and validity in diagnostic evaluation.</li>
                  </ul>
                </section>
                <section>
                  <h3 id="dsm">Diagnostic and Statistical Manual of Mental Disorders (DSM):</h3>
                  <ul>
                    <li><strong>Diagnostic Criteria:</strong> The DSM provides standardized diagnostic criteria for mental disorders, including symptom descriptions, duration criteria, and exclusion criteria. Clinicians use these criteria to make reliable and valid diagnoses based on observed symptoms and patient reports.</li>
                    <li><strong>Multiaxial System:</strong> Previous editions of the DSM utilized a multiaxial system for comprehensive assessment, incorporating clinical diagnoses, personality disorders, medical conditions, psychosocial stressors, and level of functioning. The latest edition, DSM-5, integrates these factors into a single-axis diagnostic framework.</li>
                    <li><strong>Revisions and Updates:</strong> The DSM undergoes periodic revisions and updates to reflect advances in psychiatric research, changes in diagnostic criteria, and updates based on field trials and empirical evidence. These revisions aim to enhance diagnostic validity, reliability, and clinical utility.</li>
                  </ul>
                </section>
                <section>
                  <h3 id="icd">International Classification of Diseases (ICD):</h3>
                  <ul>
                    <li><strong>Global Standard:</strong> The ICD is a global standard for diagnostic classification maintained by the World Health Organization (WHO). It includes a section dedicated to mental and behavioral disorders, providing a complementary framework to the DSM.</li>
                    <li><strong>Comprehensive Coverage:</strong> The ICD encompasses a wide range of health conditions, including physical and mental disorders, and is utilized for international health statistics, epidemiological research, and billing purposes.</li>
                    <li><strong>Alignment with DSM:</strong> While the DSM and ICD systems share many similarities in diagnostic criteria, there may be differences in terminology, classification, and coding. Efforts are made to ensure alignment between the two systems to facilitate cross-cultural research and clinical practice.</li>
                  </ul>
                </section>
                <section>
                  <h3 id="multiaxial">Multiaxial Assessment:</h3>
                  <ul>
                    <li><strong>Comprehensive Evaluation:</strong> Multiaxial assessment involves evaluating individuals across multiple domains to obtain a comprehensive understanding of their mental health status and functioning. This approach considers clinical syndromes, personality traits, medical conditions, psychosocial stressors, and level of adaptive functioning.</li>
                    <li><strong>Diagnostic Clarity:</strong> By assessing individuals across multiple axes, clinicians can differentiate between primary mental disorders, comorbid conditions, and contextual factors influencing symptom presentation. This aids in treatment planning and prognosis estimation.</li>
                    <li><strong>Holistic Perspective:</strong> Multiaxial assessment promotes a holistic perspective of mental health, recognizing the interplay between biological, psychological, social, and environmental factors in shaping individuals' well-being and functioning.</li>
                  </ul>
                </section>
                <section>
                  <h3 id="clinical-interviews">Structured Clinical Interviews:</h3>
                  <ul>
                    <li><strong>Standardized Assessment:</strong> Structured clinical interviews provide a standardized approach to diagnostic evaluation, ensuring consistency and reliability in gathering diagnostic information from patients. These interviews follow a predetermined format and include specific probes for assessing symptom severity and diagnostic criteria.</li>
                    <li><strong>Diagnostic Accuracy:</strong> By employing structured interviews, clinicians can systematically assess for the presence of mental disorders and obtain detailed information regarding symptomatology, onset, duration, and functional impairment. This enhances diagnostic accuracy and treatment planning.</li>
                    <li><strong>Training and Certification:</strong> Clinicians conducting structured interviews typically undergo training and certification to ensure proficiency in administering the assessment tool. Training programs emphasize adherence to standardized protocols, interview techniques, and diagnostic criteria.</li>
                  </ul>
                </section>
              </div>
            </Col>
          </Row>
        </Container>
        <SidebarResource currentPage="psychiatry" />
      </div>
      <footer id='footer'>
        {/* Footer */}
        <Footer />
      </footer>
    </div>
  );
}

export default Psychiatry;
