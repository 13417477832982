import { Container, Row, Col } from 'react-bootstrap';
import { SiDiscord } from "react-icons/si";
import { BsGithub } from "react-icons/bs";
import { SiCoffeescript } from "react-icons/si";

const Footer = () => {
  return (
    <div>
      {/* Footer */}
      <footer className="text-center text-white mt-auto bg-body-tertiary" data-bs-theme= 'dark' expand="md">
        {/* Grid container */}
        <Container >
          {/* Section: Links */}
          <section>
            {/* Grid row*/}
            <Row className="text-center d-flex justify-content-center pt-5"  >
              {/* Grid column */}
              <Col md={2}>
                <h6 className="text-uppercase font-weight-bold" >
                  <a href="/" className="text-white" style={{ textDecoration: 'none' }} >Home</a>
                </h6>
              </Col>
              {/* Grid column */}
  
              {/* Grid column */}
              <Col md={2}>
                <h6 className="text-uppercase font-weight-bold">
                  <a href="/Question-Bank" className="text-white" style={{ textDecoration: 'none' }}>Question Bank</a>
                </h6>
              </Col>
              {/* Grid column */}
  
              {/* Grid column */}
              <Col md={2}>
                <h6 className="text-uppercase font-weight-bold">
                  <a href="/FAQ" className="text-white" style={{ textDecoration: 'none' }}>FAQ</a>
                </h6>
              </Col>
              {/* Grid column */}
  
              {/* Grid column */}
              <Col md={2}>
                <h6 className="text-uppercase font-weight-bold">
                  <a href="/About" className="text-white" style={{ textDecoration: 'none' }}>About</a>
                </h6>
              </Col>
              {/* Grid column */}
  
              {/* Grid column */}
              <Col md={2}>
                <h6 className="text-uppercase font-weight-bold">
                  <a href="/Contact" className="text-white" style={{ textDecoration: 'none' }}>Contact Us</a>
                </h6>
              </Col>
              {/* Grid column */}
              
            </Row>
            {/* Grid row*/}
          </section>
          {/* Section: Links */}
  
          <hr className="my-4" />
  
  
          {/* Section: Social */}
          <section className="justify-content-center mb-5" >
            <a href="https://github.com/ElementalVista" className="text-white me-4">
            <BsGithub />
            </a>
            <a href="https://www.buymeacoffee.com/elementalvista" className="text-white me-4">
            <SiCoffeescript /> 
            </a>
            <a href="https://discord.gg/8YwYPVYrXd" className="text-white me-4">
            <SiDiscord />
            </a>
          </section>
          {/* Section: Social */}
        </Container>
        {/* Grid container */}
  
        {/* Copyright */}
        <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
          © 2024 Med-Bud
        </div>
        {/* Copyright */}
      </footer>
      {/* Footer */}
    </div>
  );
}

export default Footer;
